import React, { useContext, useEffect, useState } from 'react';
import Slide from '@material-ui/core/Slide';

import Logo from '../Assets/Brain.png'
import Mustaqeem from '../Assets/mustaqeem.png'

import { DataContext } from './Context/Data.Context';
import Sample from '../Assets/sample.jpg';
import Row from "./Row";
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import { Fab, Grid } from '@material-ui/core';
import { StepsContext } from './Context/Steps.Context';
import Img1 from '../Assets/1.png';
import Img2 from '../Assets/2.jpg';
import Img3 from '../Assets/3.png';
import Img4 from '../Assets/4.png';
import DocB from '../Assets/dummy.pdf'

import MainC from '../Assets/MainC.jpg';
import MainM from '../Assets/MainM.jpg';
import ThankC from '../Assets/ThankC.jpg';
import ThankM from '../Assets/ThankM.jpg';
import FreelanceM from '../Assets/FreelanceM.jpg';
import FreelanceC from '../Assets/FreelanceC.jpg';
import AssanM from '../Assets/AssanM.jpg';
import AssanC from '../Assets/AssanC.jpg';
import RemitterM from '../Assets/RemitterM.jpg';
import RemitterC from '../Assets/RemitterC.jpg';
import RepeatC from '../Assets/repeatC.jpg';
import Repeat from '../Assets/repeat.jpg';
import Blue from '../Assets/Logo-White.png'
import Mlogo from '../Assets/Logo-White.png'

import CardDoc from '../Assets/Documents/Debit Card Product Description (1).pdf'
import FAQs from '../Assets/Documents/Digital Onboarding FAQs- Final.pdf'
import BriefDoc from '../Assets/Documents/Products Brief Description - Digital Onboarding Final.pdf'

import { Call, AccessTime, Mail } from '@material-ui/icons';
import { Step, Stepper } from 'react-form-stepper';
import { useHistory } from 'react-router';
import { getEnums } from './service';
import { ApiDataContext } from './Context/Api.context';
import { AlertDataContext } from './Context/Alert.context';
import { FunctionsContext } from './Context/Functions.context';
import { ArrowRight } from '@material-ui/icons';
import { screen } from './Questions/screen';
import { Config } from './congig'

const Animatedpage = ({ children, submit, pop, sideImage, useauth }) => {
    const { data } = useContext(DataContext);
    const { steps } = useContext(StepsContext);
    const { AddSteps, setComplete } = useContext(StepsContext);
    const { ApiData, setApiData } = useContext(ApiDataContext);
    const { setLoading } = useContext(AlertDataContext)
    const { GetCurrentScreen } = useContext(FunctionsContext)
    const [Tabsteps, setTabsteps] = useState(steps);
    const [imgindex, setImgindex] = useState(0)
    const imglist = [Img1, Img2, Img3, Img4];
    const history = useHistory();
    const enable = screen[history.location.pathname.replace("/", "")] < screen[sessionStorage.getItem("CurrentScreen")] ? true : false;
    const [CurrenImg, setCurrenImg] = useState(MainC)
    const sp1 = ["BankIslami", "Emaar Pakistan", "Discount On", "One Page Application Form",];
    const sp = ["Salana", "Home Financing", "18k+", "Mera Pakistan",];
    const h = [<>Munafa</>, <><br />With BankIslami</>, <>Merchants</>, <><br />Mera Ghar</>];
    const [span1txt, setspan1txt] = useState("BankIslami");
    const [spantxt, setspantxt] = useState("Salana");
    const [h2txt, seth2txt] = useState("Munafa");
    const [index, setIndex] = useState(0)
    useEffect(() => {
        
        setTabsteps(steps)
    }, [steps])

    useEffect(() => {

        // ValidateSideImage()
    }, [data])

    function ValidateSideImage() {
        debugger
        var path = history.location.pathname.replace("/", "");
        var BankingType = data.selectedAT?.id;
        var AccountType = data.option?.Id;
        if (path === "thankyou" && BankingType === 1) {
            setCurrenImg(ThankC);
        }

        else if (path === "thankyou" && BankingType === 2) {
            setCurrenImg(ThankM);
        }

        else if (AccountType === 1 && BankingType === 2) {
            setCurrenImg(AssanM);
        }

        else if (AccountType === 2 && BankingType === 2) {
            setCurrenImg(RemitterM);
        }

        else if (AccountType === 3 && BankingType === 2) {
            setCurrenImg(FreelanceM);
        }
        else if (AccountType === 1 && BankingType === 1) {
            setCurrenImg(AssanC);
        }

        else if (AccountType === 2 && BankingType === 1) {
            setCurrenImg(RemitterC);
        }
        else if (AccountType === 3 && BankingType === 1) {
            setCurrenImg(FreelanceC);
        }


        else if (BankingType === 1) {
            setCurrenImg(MainC);
        }

        else if (BankingType === 2) {
            setCurrenImg(MainM);
        }

        else {
            setCurrenImg(Repeat);

        }



    }


    useEffect(async () => {

        //checklocalversion();
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
        var t = setInterval(() => {
            var i = index + 1;
            setspantxt(prevIndex => sp[i]);
            seth2txt(prevIndex => h[i]);
            setspan1txt(prevIndex => sp1[i]);

            setIndex(prevIndex => prevIndex === 3 ? 0 : prevIndex + 1)
        }, 5000);
        sessionStorage.setItem("CurrentScreen", "");
        // var s = await placecurrentscreen();
        var p = await Enums();
        var t = document.getElementsByTagName("input");

        for (let index = 0; index < t.length; index++) {
            const element = t[index];
            if (element.type === "file") {
                element.accept = "application/pdf, image/png,image/jpg,image/jpeg";
            }

        }
        
    }, [])

    function checklocalversion() {
        if (localStorage.getItem("localstorageVersion") !== Config.localstorageVersion) {
            localStorage.setItem("datacollection", "{}");
            localStorage.setItem("localstorageVersion", Config.localstorageVersion);
        }

    }
    var _validFileExtensions = [".jpg", ".jpeg", ".bmp", ".gif", ".png", ".pdf"];
    function Validate(oForm) {
        var arrInputs = oForm.getElementsByTagName("input");
        for (var i = 0; i < arrInputs.length; i++) {
            var oInput = arrInputs[i];
            if (oInput.type == "file") {
                var sFileName = oInput.value;
                if (sFileName.length > 0) {
                    var blnValid = false;
                    for (var j = 0; j < _validFileExtensions.length; j++) {
                        var sCurExtension = _validFileExtensions[j];
                        if (sFileName.substr(sFileName.length - sCurExtension.length, sCurExtension.length).toLowerCase() == sCurExtension.toLowerCase()) {
                            blnValid = true;
                            break;
                        }
                    }

                    if (!blnValid) {
                        alert("Please upload correct file format (i.e. PNG, PDF, BMP, GIF or JPEG)");
                        return false;
                    }
                }
            }
        }

        return true;
    }


    async function placecurrentscreen() {
        debugger
        var resp = await GetCurrentScreen();
        if (resp.StatusCode === 0) {
            if (resp.ResponseObject.Exist === true) {
                sessionStorage.setItem("CurrentScreen", resp.ResponseObject.ScreenName);
            }
        } else {
            sessionStorage.setItem("CurrentScreen", "none");
        }
    }
    async function Enums() {
        setLoading(true)
        var arr = await getEnums();
        debugger
        if (arr.StatusCode === 0) {
            setApiData({ ...ApiData, ...arr.ResponseObject });

            if (pop !== undefined) {
                pop();
            }
        }
        setLoading(false)

    }

    

    const checkselect = (event) => {
        event.preventDefault();
        debugger
        var t = document.getElementById("appform");
        if (Validate(t)) {
            submit(event);
        } else {
            alert("Invalid file/s selected.");
            return;
        }
    }

    return (
        <>
            <div class="row">
                <div class="column1" style={{ backgroundColor: "var(--primary)", borderBottomRightRadius: 200 }}>
                    <div style={{ display: "inline-block", textAlign: "left", marginTop: 6 }} id="logoheader">
                        <div style={{ margin: 10 }}>

                            <img src={Blue} style={{ width: "90%" }} />
                            <h4 id="wellcome" style={{ fontWeight: "bold", color: "white", textTransform: "uppercase", marginTop: (history.location.pathname !== "/" ? "0px !important" : "0px") }}>Welcome To</h4>
                            <h3 style={{ color: "white" }}>BankIslami Digital Onboarding</h3>
                            <div style={{ marginTop: 75, marginLeft: 25 }}>
                                <div>
                                    <h5 style={{ color: "white" }}>
                                        <span style={{ textDecoration: "line-through", color: "var(--light)", width: 20, borderBottomWidth: 1, borderColor: "var(--light)" }}>{ }</span>
                                        {sp1[index]}</h5>
                                    <h2 style={{ color: "White" }}>
                                        <span style={{ color: "var(--light)" }}>{sp[index]} </span>
                                        {h[index]}
                                    </h2>
                                </div>

                                <a className="visitenow" target={"_blank"} href="https://bankislami.com.pk/">Visit Now</a>


                            </div>


                        </div>
                        {history.location.pathname === "/" ?
                            <>
                                <div id="headermain" style={{ position: "absolute", height: 70, margin: "auto", alignSelf: "center", borderRadius: 20, top: "85vh", padding: "20px 0px", width: "fit-content", background: "var(--secondary)", left: 40 }}>
                                    <h5 className="Q" style={{ fontSize: 17, padding: 10, paddingTop: 5, display: "inline-block", marginLeft: 10, marginRight: 10 }}><a style={{ textDecoration: "none", color: "white" }}>Important Documents</a></h5>
                                    <h5 className="Q" style={{ fontSize: 14, padding: 10, display: "inline-block", marginLeft: 10, marginRight: 10 }}><a style={{ textDecoration: "none", color: "white" }} href={CardDoc} download>Debit Card Product Description</a></h5>
                                    <h5 className="Q" style={{ fontSize: 14, padding: 10, display: "inline-block", marginLeft: 10, marginRight: 10 }}><a style={{ textDecoration: "none", color: "white" }} href={FAQs} download>Digital Onboarding FAQs</a></h5>
                                    <h5 className="Q" style={{ fontSize: 14, padding: 10, display: "inline-block", marginLeft: 10, marginRight: 10 }}><a style={{ textDecoration: "none", color: "white" }} href={BriefDoc} download>Products Brief Description</a></h5>
                                </div>
                                <div id="headerright" style={{ position: "absolute", height: 110, margin: "auto", alignSelf: "center", top: 0, padding: "20px 30px", width: "60vw", right: 1 }}>
                                    <div style={{ display: "inline-block", marginLeft: 20, marginRight: 20 }}>
                                        <div className='headerIcon'>
                                            <Call style={{ fontSize: 35, display: "inline-block", marginTop: -20, marginRight: 10, fill: "var(--primary)" }} />
                                        </div>
                                        <div className="Content">
                                            <span className="Title">021-111 475 264</span>
                                            <span className="Text">Call </span>
                                        </div>
                                    </div>
                                    <div style={{ display: "inline-block", marginLeft: 20, marginRight: 20 }}>
                                        <div className='headerIcon'>
                                            <AccessTime style={{ fontSize: 35, display: "inline-block", marginTop: -20, marginRight: 10, fill: "var(--primary)" }} />
                                        </div>
                                        <div className="Content">
                                            <span className="Title">Internet Banking</span>
                                            <span className="Text">24/7</span>
                                        </div>
                                    </div>
                                    <div style={{ display: "inline-block", marginLeft: 20, marginRight: 20 }}>
                                        <div className='headerIcon'>
                                            <Mail style={{ fontSize: 35, display: "inline-block", marginTop: -20, marginRight: 10, fill: "var(--primary)" }} />
                                        </div>
                                        <div className="Content">
                                            <span className="Title">contact.center@bankislami.com.pk</span>
                                            <span className="Text">Email</span>
                                        </div>
                                    </div>
                                </div>
                            </>
                            : <></>}
                    </div>

                </div>
                <div class="column2">
                    <div style={{ textAlign: "center", height: 50 }} className="HeaderDiv">
                        {/* {data.selectedAT === "" && history.location.pathname !== "/" ?
                            <img src={Mustaqeem} style={{ marginTop: 10, width: 155, margin: "auto" }} />
                            :
                            <img src={Logo} style={{ marginTop: 10, margin: "auto", width: 115, }} />
                        } */}
                        <div className='mobilelogo' style={{ display: "none" }}>
                            <img src={Mlogo} style={{ marginTop: 10, margin: "auto", width: 200, display: "none" }} />
                            <h4 id="wellcome" style={{ fontWeight: "bold", color: "black", textTransform: "uppercase", display: "none", marginTop: (history.location.pathname !== "/" ? "0px !important" : "0px") }}>Welcome To</h4>
                            <h3 style={{ color: "black", display: "none" }}>BankIslami Digital Onboarding</h3>
                        </div>
                    </div>
                    {Tabsteps.findIndex(p => p.active === true) > -1 ?
                        <>
                            {/* <h4 style={{ marginTop: 15, fontSize: "1.2em", color: "#646777", display: "inline-block" }}>{data.option}</h4>
                            <h4 style={{ marginTop: 15, fontSize: "15px", color: "#646777", display: "inline-block", float: "right" }}>{data.customerName} {(data?.cnic ? "(" + data.cnic + ")" : <></>)}</h4> */}
                            <Stepper activeStep={Tabsteps.findIndex(p => p.active === true)} connectorStateColors={true} style={{ width: "100%" }} connectorStyleConfig={{ activeColor: "#18407c", completedColor: "#579209" }}>
                                {Tabsteps.map((p, i) => (
                                    <Step label={p.Name} styleConfig={{ activeBgColor: "#18407c", completedBgColor: "#8cc53f" }} />
                                ))}
                            </Stepper>
                        </>
                        : null}
                    <Slide direction="right" in={true} mountOnEnter unmountOnExit >
                        <form onSubmit={checkselect} id="appform">
                            <div className="formdiv">
                                {children}
                            </div>
                        </form>
                    </Slide>
                    {/* {enable ?
                        <Fab style={{ position: "absolute", width: 100, right: 20, top: "85vh", backgroundColor: "var(--primary)", border: "none", borderRadius: 10, marginTop: 20 }}
                            onClick={() => history.push("/" + sessionStorage.getItem("CurrentScreen"))}
                        >
                            Continue
                            <ArrowRight style={{ marginRight: -10 }} />

                        </Fab>

                        : <></>} */}
                </div>
            </div>
        </>
    );
}

export default Animatedpage;
