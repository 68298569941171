import React, { useContext, useState } from 'react';
import DownIcon from 'mdi-react/ChevronDownIcon';

import { Collapse } from 'reactstrap';
import TopbarMenuLink from './TopbarMenuLink';
import { Auth } from '../../../Context/local.context';

const Ava = `${process.env.PUBLIC_URL}/favicon.png`;

const TopbarProfile = () => {
  const [isCollapsed, setIsCollapsed] = useState(false);
  const handleToggleCollapse = () => {
    setIsCollapsed(!isCollapsed);
  };
  const {AuthData} = useContext(Auth)

  return (
    <div className="topbar__profile">      
      <button type="button" className="topbar__avatar" onClick={setIsCollapsed}>
        <img className="topbar__avatar-img" src={Ava} alt="avatar" />
        <p className="topbar__avatar-name">{AuthData.UserName}</p>
        <DownIcon className="topbar__icon" />
      </button>
      {isCollapsed && (
        <button
          type="button"
          aria-label="button collapse"
          className="topbar__back"
          onClick={handleToggleCollapse}
        />
      )}
      <Collapse isOpen={isCollapsed} className="topbar__menu-wrap">
        <div className="topbar__menu">
          
          <TopbarMenuLink title="Log Out" icon="exit" path="/admin/dashboard/logout"/>
        </div>
      </Collapse>
    </div>
  );
};

export default TopbarProfile;
