import React, { useContext, useEffect, useState } from 'react';
import { Card, CardBody, CardFooter, CardHeader, Col, Container, Row, Table } from 'reactstrap';
import ForntCNIC from '../../assets/cnicf.jpg'
import BackCNIC from '../../assets/cnicb.jpg'
import { useHistory } from 'react-router-dom';
import SeheduleMeeting from './ScheduleMeeting';
import axios from 'axios';
import { Config } from '../../../components/congig';
import { postRequest } from '../service';
import { screen } from '../../../components/Questions/screen'
import { ApplicationStatuses, ApplicationStatusesEnum, ApplicationStatusList, ApplicationStatusList2 } from '../Enums';
import AddNotes from './Addnotes';
import { CheckBox } from '@material-ui/icons';
import Details from './Descrepantdtl';
import AddDoc from './AddDoc';
import { Auth } from '../../Context/local.context';
import Meeting from './Uploadmetting';
const UserList = () => {
    const history = useHistory();
    var parmsdata = history.location.state;
    const [Loading, setLoading] = useState(true);
    const [data, setData] = useState({})
    const [currentscreen, setcurrentscreen] = useState("");
    const [statuid, setstatuid] = useState();
    const [notesmodel, setNotesmodel] = useState(false);
    const [remarks, setRemarks] = useState("");
    const [notes, setnotes] = useState("");
    const [notesmodeldtl, setnotesmodeldtl] = useState(false);

    const authconst = localStorage.getItem("authnav");

    useEffect(() => {
        console.log(authconst);
        console.log(parmsdata);
        if (parmsdata.Id === null) {
            alert("")
            return;
        } else {

            axios.post("api/dashboard/DigitalAcountDashboard/CustomerDetail",
                {
                    "Id": parmsdata.Id,
                    "Index": parmsdata.Index
                }
            ).then(response => {
                console.log(response.data);
                if (response.data !== null && response.data !== "") {
                    setData({ ...response.data })
                    setcurrentscreen(response.data.AccountVerificationStep);
                    setLoading(false);
                }

                // initMap(response.data.latitude, response.data.longitude);
            }).catch(error => {

            })
        }
    }, [parmsdata])

    const [meetingmodel, setMeetingmodel] = useState(false);
    const [Docmodel, setDocmodel] = useState(false);
    const [Meetingvar, setMeetingvar] = useState(false);

    const [selid, setSelid] = useState(1);


    const RejectHandler = async (id) => {
        // setLoading(true);
        // var t = await postRequest("api/dashboard/DigitalAcountDashboard/DeclineApplication", { "Id": parmsdata.Id, "Index": parmsdata.Index, "Status": id });
        // if (t.StatusCode === 0) {
        //     history.goBack()
        // } else {
        //     alert("Some Error Occurs please try again.");
        //     console.error(t);
        // }
        // setLoading(false);
        if (id !== "") {
            setstatuid(id);
            setNotesmodel(!notesmodel);
        }
    }
    function initMap(lat, long) {
        const uluru = { lat: lat, lng: long };
        // const map = new google.maps.Map(document.getElementById("map"), {
        //     zoom: 4,
        //     center: uluru,
        // });
        // const marker = new google.maps.Marker({
        //     position: uluru,
        //     map: map,
        // });
    }
    return (
        <div style={{}}>
            {!Loading ?
                <div>
                    <Row>
                        <Col md={12}>
                            <h3 className="page-title">User Details</h3>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Card>
                                <CardBody>
                                    {data.ApplicationStatus === "Application Draft" && screen[currentscreen] <= screen.basicdetails ? <></> :
                                        <>
                                            <h3 style={{ marginBottom: 20 }}>Account Details</h3>
                                            <Row>
                                                {/* Config.LOCALADDRESS + "/" +  */}
                                                {data.LivenessImagePath !== null ? <> <img style={{ height: 160, width: 200 }} src={Config.LOCALADDRESS + "/" + data.LivenessImagePath} /> <br /></> : ""}
                                            </Row>
                                            <Row style={{ marginTop: 20 }}>
                                                <Col>
                                                    <div className="form__form-group" style={{ marginTop: 0 }}>
                                                        <span className="form__form-group-label" style={{ fontSize: 15, fontWeight: 400 }}>Reference Id</span>
                                                        <div className="form__form-group-field">
                                                            <p
                                                                disabled
                                                                style={{ backgroundColor: "white", paddingLeft: 5, borderColor: "var(--faysal)", borderBottomWidth: 2, borderStyle: "solid", borderTopWidth: 0, borderLeftWidth: 0, borderRightWidth: 0, height: "fit-content", fontSize: 14, width: "100%", outlineWidth: 0, marginTop: 5 }}
                                                                name="name"
                                                                type="text"
                                                                autoComplete="off"
                                                                placeholder=""
                                                                required
                                                                value={"Conventional"}
                                                            //onChange={(txt) => { setFullName(txt.target.value); }}
                                                            >{data.ReferenceId}</p>
                                                        </div>
                                                    </div>
                                                </Col>
                                                <Col>
                                                    <div className="form__form-group" style={{ marginTop: 0 }}>
                                                        <span className="form__form-group-label" style={{ fontSize: 15, fontWeight: 400 }}>Created On</span>
                                                        <div className="form__form-group-field">
                                                            <p
                                                                disabled
                                                                style={{ backgroundColor: "white", paddingLeft: 5, borderColor: "var(--faysal)", borderBottomWidth: 2, borderStyle: "solid", borderTopWidth: 0, borderLeftWidth: 0, borderRightWidth: 0, height: "fit-content", fontSize: 14, width: "100%", outlineWidth: 0, marginTop: 5 }}
                                                                name="name"
                                                                type="text"
                                                                autoComplete="off"
                                                                placeholder=""
                                                                required
                                                                value={"Conventional"}
                                                            //onChange={(txt) => { setFullName(txt.target.value); }}
                                                            >{new Date(data.CreatedOn).toDateString()}</p>
                                                        </div>
                                                    </div>

                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col>
                                                    <div className="form__form-group" style={{ marginTop: 0 }}>
                                                        <span className="form__form-group-label" style={{ fontSize: 15, fontWeight: 400 }}>Submitted On</span>
                                                        <div className="form__form-group-field">
                                                            <p
                                                                disabled
                                                                style={{ backgroundColor: "white", paddingLeft: 5, borderColor: "var(--faysal)", borderBottomWidth: 2, borderStyle: "solid", borderTopWidth: 0, borderLeftWidth: 0, borderRightWidth: 0, height: "fit-content", fontSize: 14, width: "100%", outlineWidth: 0, marginTop: 5 }}
                                                                name="name"
                                                                type="text"
                                                                autoComplete="off"
                                                                placeholder=""
                                                                required
                                                                value={"Conventional"}
                                                            //onChange={(txt) => { setFullName(txt.target.value); }}
                                                            >{data.ApplicationStatusTimeStamps !== "" ? (new Date(JSON.parse(data.ApplicationStatusTimeStamps).filter(p => p.TransactionStatus === 2)[0]?.DateTime).toDateString()).replace("Invalid Date", "") + " " + (new Date(JSON.parse(data.ApplicationStatusTimeStamps).filter(p => p.TransactionStatus === 2)[0]?.DateTime).toLocaleTimeString()).replace("Invalid Date", "") : ""}</p>
                                                        </div>
                                                    </div>

                                                </Col>
                                                <Col>
                                                    <div className="form__form-group" style={{ marginTop: 10 }}>
                                                        <span className="form__form-group-label" style={{ fontSize: 15, fontWeight: 400 }}>Product type</span>
                                                        <div className="form__form-group-field">
                                                            <p
                                                                disabled
                                                                style={{ backgroundColor: "white", paddingLeft: 5, borderColor: "var(--faysal)", borderBottomWidth: 2, borderStyle: "solid", borderTopWidth: 0, borderLeftWidth: 0, borderRightWidth: 0, height: "fit-content", fontSize: 14, width: "100%", outlineWidth: 0, marginTop: 5 }}
                                                                name="name"
                                                                type="text"
                                                                autoComplete="off"
                                                                placeholder=""
                                                                required
                                                                value={"Asaan Digital Account"}
                                                            //onChange={(txt) => { setFullName(txt.target.value); }}
                                                            >{data.ProductType}</p>
                                                        </div>
                                                    </div>
                                                </Col>

                                            </Row>
                                            <Row>
                                                <Col>
                                                    <div className="form__form-group" style={{ marginTop: 0 }}>
                                                        <span className="form__form-group-label" style={{ fontSize: 15, fontWeight: 400 }}>Currency</span>
                                                        <div className="form__form-group-field">
                                                            <p
                                                                disabled
                                                                style={{ backgroundColor: "white", paddingLeft: 5, borderColor: "var(--faysal)", borderBottomWidth: 2, borderStyle: "solid", borderTopWidth: 0, borderLeftWidth: 0, borderRightWidth: 0, height: "fit-content", fontSize: 14, width: "100%", outlineWidth: 0, marginTop: 5 }}
                                                                name="name"
                                                                type="text"
                                                                autoComplete="off"
                                                                placeholder=""
                                                                required
                                                                value={"PKR"}
                                                            //onChange={(txt) => { setFullName(txt.target.value); }}
                                                            >{data.Currency}</p>
                                                        </div>
                                                    </div>
                                                </Col>
                                                <Col>
                                                    <div className="form__form-group" style={{ marginTop: 0 }}>
                                                        <span className="form__form-group-label" style={{ fontSize: 15, fontWeight: 400 }}>Account type</span>
                                                        <div className="form__form-group-field">
                                                            <p
                                                                disabled
                                                                style={{ backgroundColor: "white", paddingLeft: 5, borderColor: "var(--faysal)", borderBottomWidth: 2, borderStyle: "solid", borderTopWidth: 0, borderLeftWidth: 0, borderRightWidth: 0, height: "fit-content", fontSize: 14, width: "100%", outlineWidth: 0, marginTop: 5 }}
                                                                name="name"
                                                                type="text"
                                                                autoComplete="off"
                                                                placeholder=""
                                                                required
                                                                value={"Current"}
                                                            //onChange={(txt) => { setFullName(txt.target.value); }}
                                                            >{data.AccountType}</p>
                                                        </div>
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col>
                                                    <div className="form__form-group" style={{ marginTop: 0 }}>
                                                        <span className="form__form-group-label" style={{ fontSize: 15, fontWeight: 400 }}>Account Category</span>
                                                        <div className="form__form-group-field">
                                                            <p
                                                                disabled
                                                                style={{ backgroundColor: "white", paddingLeft: 5, borderColor: "var(--faysal)", borderBottomWidth: 2, borderStyle: "solid", borderTopWidth: 0, borderLeftWidth: 0, borderRightWidth: 0, height: "fit-content", fontSize: 14, width: "100%", outlineWidth: 0, marginTop: 5 }}
                                                                name="name"
                                                                type="text"
                                                                autoComplete="off"
                                                                placeholder=""
                                                                required
                                                                value={"Single"}
                                                            //onChange={(txt) => { setFullName(txt.target.value); }}
                                                            >{data.AccountCategory}</p>
                                                        </div>
                                                    </div>
                                                </Col>
                                                <Col>
                                                    <div className="form__form-group" style={{ marginTop: 0 }}>
                                                        <span className="form__form-group-label" style={{ fontSize: 15, fontWeight: 400 }}>Purpose of account</span>
                                                        <div className="form__form-group-field">
                                                            <p
                                                                disabled
                                                                style={{ backgroundColor: "white", paddingLeft: 5, borderColor: "var(--faysal)", borderBottomWidth: 2, borderStyle: "solid", borderTopWidth: 0, borderLeftWidth: 0, borderRightWidth: 0, height: "fit-content", fontSize: 14, width: "100%", outlineWidth: 0, marginTop: 5 }}
                                                                name="name"
                                                                type="text"
                                                                autoComplete="off"
                                                                placeholder=""
                                                                required
                                                                value={"Business"}
                                                            //onChange={(txt) => { setFullName(txt.target.value); }}
                                                            >{data.PurposeOfAccount}</p>
                                                        </div>
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col>
                                                    <div className="form__form-group" style={{ marginTop: 0 }}>
                                                        <span className="form__form-group-label" style={{ fontSize: 15, fontWeight: 400 }}>Biometric Taken</span>
                                                        <div className="form__form-group-field">
                                                            <p
                                                                disabled
                                                                style={{ backgroundColor: "white", paddingLeft: 5, borderColor: "var(--faysal)", borderBottomWidth: 2, borderStyle: "solid", borderTopWidth: 0, borderLeftWidth: 0, borderRightWidth: 0, height: "fit-content", fontSize: 14, width: "100%", outlineWidth: 0, marginTop: 5 }}
                                                                name="name"
                                                                type="text"
                                                                autoComplete="off"
                                                                placeholder=""
                                                                required
                                                                value={"Single"}
                                                            //onChange={(txt) => { setFullName(txt.target.value); }}
                                                            >{data.BiometricVerification ? "Yes" : "No"}</p>
                                                        </div>
                                                    </div>
                                                </Col>
                                                <Col>
                                                <div className="form__form-group" style={{ marginTop: 0 }}>
                                                        <span className="form__form-group-label" style={{ fontSize: 15, fontWeight: 400 }}>Sim ownership verification</span>
                                                        <div className="form__form-group-field">
                                                            <p
                                                                disabled
                                                                style={{ backgroundColor: "white", paddingLeft: 5, borderColor: "var(--faysal)", borderBottomWidth: 2, borderStyle: "solid", borderTopWidth: 0, borderLeftWidth: 0, borderRightWidth: 0, height: "fit-content", fontSize: 14, width: "100%", outlineWidth: 0, marginTop: 5 }}
                                                                name="name"
                                                                type="text"
                                                                autoComplete="off"
                                                                placeholder=""
                                                                required
                                                                value={"Single"}
                                                            //onChange={(txt) => { setFullName(txt.target.value); }}
                                                            >{data?.PMDVerified ? data?.PMDVerified : "--"}</p>
                                                        </div>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </>
                                    }
                                    {data.ApplicationStatus === "Application Draft" && screen[currentscreen] <= screen.basicdetails ? <></> :
                                        <>
                                            <h3 style={{ marginBottom: 20, marginTop: 20 }}>Basic Details</h3>

                                            <Row>
                                                <Col>
                                                    <div className="form__form-group" style={{ marginTop: 0 }}>
                                                        <span className="form__form-group-label" style={{ fontSize: 15, fontWeight: 400 }}>Customer Name</span>
                                                        <div className="form__form-group-field">
                                                            <p
                                                                disabled
                                                                style={{ backgroundColor: "white", paddingLeft: 5, borderColor: "var(--faysal)", borderBottomWidth: 2, borderStyle: "solid", borderTopWidth: 0, borderLeftWidth: 0, borderRightWidth: 0, height: "fit-content", fontSize: 14, width: "100%", outlineWidth: 0, marginTop: 5 }}
                                                                name="name"
                                                                type="text"
                                                                autoComplete="off"
                                                                placeholder=""
                                                                required

                                                            >{data.CustomerName}</p>
                                                        </div>
                                                    </div>
                                                </Col>
                                                <Col>
                                                    <div className="form__form-group" style={{ marginTop: 0 }}>
                                                        <span className="form__form-group-label" style={{ fontSize: 15, fontWeight: 400 }}>National Identity Card</span>
                                                        <div className="form__form-group-field">
                                                            <p
                                                                disabled
                                                                style={{ backgroundColor: "white", paddingLeft: 5, borderColor: "var(--faysal)", borderBottomWidth: 2, borderStyle: "solid", borderTopWidth: 0, borderLeftWidth: 0, borderRightWidth: 0, height: "fit-content", fontSize: 14, width: "100%", outlineWidth: 0, marginTop: 5 }}
                                                                name="name"
                                                                type="text"
                                                                autoComplete="off"
                                                                placeholder=""
                                                                required
                                                                value={"42101-6547589-9"}
                                                            //onChange={(txt) => { setFullName(txt.target.value); }}
                                                            >{data.CNIC}</p>
                                                        </div>
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col>
                                                    <div className="form__form-group" style={{ marginTop: 0 }}>
                                                        <span className="form__form-group-label" style={{ fontSize: 15, fontWeight: 400 }}>
                                                            Issuance Date</span>
                                                        <div className="form__form-group-field">
                                                            <p
                                                                disabled
                                                                style={{ backgroundColor: "white", paddingLeft: 5, borderColor: "var(--faysal)", borderBottomWidth: 2, borderStyle: "solid", borderTopWidth: 0, borderLeftWidth: 0, borderRightWidth: 0, height: "fit-content", fontSize: 14, width: "100%", outlineWidth: 0, marginTop: 5 }}
                                                                name="name"
                                                                type="text"
                                                                autoComplete="off"
                                                                placeholder=""
                                                                required
                                                                value={"12-12-2021"}
                                                            //onChange={(txt) => { setFullName(txt.target.value); }}
                                                            >{new Date(data.NadraIssuanceDate).toDateString()}</p>
                                                        </div>
                                                    </div>
                                                </Col>
                                                <Col>
                                                    <div className="form__form-group" style={{ marginTop: 0 }}>
                                                        <span className="form__form-group-label" style={{ fontSize: 15, fontWeight: 400 }}>
                                                            Expiry Date</span>
                                                        <div className="form__form-group-field">
                                                            <p
                                                                disabled
                                                                style={{ backgroundColor: "white", paddingLeft: 5, borderColor: "var(--faysal)", borderBottomWidth: 2, borderStyle: "solid", borderTopWidth: 0, borderLeftWidth: 0, borderRightWidth: 0, height: "fit-content", fontSize: 14, width: "100%", outlineWidth: 0, marginTop: 5 }}
                                                                name="name"
                                                                type="text"
                                                                autoComplete="off"
                                                                placeholder=""
                                                                required
                                                                value={"12-12-2023"}
                                                            //onChange={(txt) => { setFullName(txt.target.value); }}
                                                            >{new Date(data.ExpiryDate).toDateString()}</p>
                                                        </div>
                                                    </div>
                                                </Col>
                                            </Row>

                                            <Row>
                                                <Col>
                                                    <div className="form__form-group" style={{ marginTop: 0 }}>
                                                        <span className="form__form-group-label" style={{ fontSize: 15, fontWeight: 400 }}>
                                                            Computerized/Smart National Identity Card Front</span>
                                                        <div className="form__form-group-field">
                                                            <img src={Config.LOCALADDRESS + "/" + data.CNICFrontPath} style={{ width: 450, height: 200 }} />
                                                        </div>
                                                    </div>
                                                </Col>
                                                <Col>
                                                    <div className="form__form-group" style={{ marginTop: 0 }}>
                                                        <span className="form__form-group-label" style={{ fontSize: 15, fontWeight: 400 }}>
                                                            Computerized/Smart National Identity Card Back</span>
                                                        <div className="form__form-group-field">
                                                            <img src={Config.LOCALADDRESS + "/" + data.CNICBackPath} style={{ width: 450, height: 200 }} />
                                                        </div>
                                                    </div>
                                                </Col>
                                            </Row>

                                            <Row style={{ marginBottom: 10 }} id="hidcls">
                                                <Col>
                                                    <div className="form__form-group" style={{ marginTop: 0 }}>
                                                        <span className="form__form-group-label" style={{ fontSize: 15, fontWeight: 400 }}>
                                                            Nadra Mother/Maiden Name</span>
                                                        <div className="form__form-group-field">
                                                            <p
                                                                disabled
                                                                style={{ backgroundColor: "white", paddingLeft: 5, borderColor: "var(--faysal)", borderBottomWidth: 2, borderStyle: "solid", borderTopWidth: 0, borderLeftWidth: 0, borderRightWidth: 0, height: "fit-content", fontSize: 14, width: "100%", outlineWidth: 0, marginTop: 5 }}
                                                            //onChange={(txt) => { setFullName(txt.target.value); }}
                                                            >{data.NadraMotherMaidenName}</p>
                                                        </div>
                                                    </div>
                                                </Col>
                                                <Col>
                                                    <div className="form__form-group" style={{ marginTop: 0 }}>
                                                        <span className="form__form-group-label" style={{ fontSize: 15, fontWeight: 400 }}>
                                                            Nadra Place Of Birth</span>
                                                        <div className="form__form-group-field">
                                                            <p
                                                                disabled
                                                                style={{ backgroundColor: "white", paddingLeft: 5, borderColor: "var(--faysal)", borderBottomWidth: 2, borderStyle: "solid", borderTopWidth: 0, borderLeftWidth: 0, borderRightWidth: 0, height: "fit-content", fontSize: 14, width: "100%", outlineWidth: 0, marginTop: 5 }}
                                                            //onChange={(txt) => { setFullName(txt.target.value); }}
                                                            >{data.NadraPlaceOfBirth}</p>
                                                        </div>
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col>
                                                    <div className="form__form-group" style={{ marginTop: 0 }}>
                                                        <span className="form__form-group-label" style={{ fontSize: 15, fontWeight: 400 }}>
                                                            Date of Birth</span>
                                                        <div className="form__form-group-field">
                                                            <p
                                                                disabled
                                                                style={{ backgroundColor: "white", paddingLeft: 5, borderColor: "var(--faysal)", borderBottomWidth: 2, borderStyle: "solid", borderTopWidth: 0, borderLeftWidth: 0, borderRightWidth: 0, height: "fit-content", fontSize: 14, width: "100%", outlineWidth: 0, marginTop: 5 }}
                                                                name="name"
                                                                type="text"
                                                                autoComplete="off"
                                                                placeholder=""
                                                                required
                                                                value={"20--12-1995"}
                                                            //onChange={(txt) => { setFullName(txt.target.value); }}
                                                            >{new Date(data.DateOfBirth).toDateString()}</p>
                                                        </div>
                                                    </div>
                                                </Col>
                                                <Col>
                                                    {(parseInt(new Date().getFullYear()) - parseInt(new Date(data.DateOfBirth).getFullYear())) < 18 ?
                                                        <>
                                                            <div className="form__form-group" style={{ marginTop: 0 }}>
                                                                <span className="form__form-group-label" style={{ fontSize: 15, fontWeight: 400 }}>
                                                                    Relationship with Guardian</span>
                                                                <div className="form__form-group-field">
                                                                    <p
                                                                        disabled
                                                                        style={{ backgroundColor: "white", paddingLeft: 5, borderColor: "var(--faysal)", borderBottomWidth: 2, borderStyle: "solid", borderTopWidth: 0, borderLeftWidth: 0, borderRightWidth: 0, height: "fit-content", fontSize: 14, width: "100%", outlineWidth: 0, marginTop: 5 }}
                                                                        name="name"
                                                                        type="text"
                                                                        autoComplete="off"
                                                                        placeholder=""
                                                                        required
                                                                        value={"20--12-1995"}
                                                                    //onChange={(txt) => { setFullName(txt.target.value); }}
                                                                    >{data.RWG}</p>
                                                                </div>
                                                            </div>
                                                        </> : <></>}
                                                </Col>
                                            </Row>

                                            <Row>
                                                <Col>
                                                    <div className="form__form-group" style={{ marginTop: 0 }}>
                                                        <span className="form__form-group-label" style={{ fontSize: 15, fontWeight: 400 }}>
                                                            Mobile number</span>
                                                        <div className="form__form-group-field">
                                                            <p
                                                                disabled
                                                                style={{ backgroundColor: "white", paddingLeft: 5, borderColor: "var(--faysal)", borderBottomWidth: 2, borderStyle: "solid", borderTopWidth: 0, borderLeftWidth: 0, borderRightWidth: 0, height: "fit-content", fontSize: 14, width: "100%", outlineWidth: 0, marginTop: 5 }}
                                                                name="name"
                                                                type="text"
                                                                autoComplete="off"
                                                                placeholder=""
                                                                required
                                                                value={"+923169898787"}
                                                            //onChange={(txt) => { setFullName(txt.target.value); }}
                                                            >+{data.PhoneNumber}</p>
                                                        </div>
                                                    </div>
                                                </Col>
                                                <Col>
                                                    <div className="form__form-group" style={{ marginTop: 0 }}>
                                                        <span className="form__form-group-label" style={{ fontSize: 15, fontWeight: 400 }}>
                                                            E-mail</span>
                                                        <div className="form__form-group-field">
                                                            <p
                                                                disabled
                                                                style={{ backgroundColor: "white", paddingLeft: 5, borderColor: "var(--faysal)", borderBottomWidth: 2, borderStyle: "solid", borderTopWidth: 0, borderLeftWidth: 0, borderRightWidth: 0, height: "fit-content", fontSize: 14, width: "100%", outlineWidth: 0, marginTop: 5 }}
                                                                name="name"
                                                                type="text"
                                                                autoComplete="off"
                                                                placeholder=""
                                                                required
                                                                value={"example@abc.com"}
                                                            //onChange={(txt) => { setFullName(txt.target.value); }}
                                                            >{data.Email}</p>
                                                        </div>
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row>
                                                {/* <div id="map"></div> */}
                                                {/* <div class="msg send-msg-location">
                                            <iframe class="location"
                                                src={`https://maps.google.com/maps?q=${data.latitude},${data.longitude}&amp;output=embed`} frameborder="0" scrolling="no" marginheight="0" marginwidth="0">
                                            </iframe>
                                        </div> */}
                                                <Col>
                                                    <div className="form__form-group" style={{ marginTop: 0 }}>
                                                        <span className="form__form-group-label" style={{ fontSize: 15, fontWeight: 400 }}>
                                                            Latitude</span>
                                                        <div className="form__form-group-field">
                                                            <p
                                                                disabled
                                                                style={{ backgroundColor: "white", paddingLeft: 5, borderColor: "var(--faysal)", borderBottomWidth: 2, borderStyle: "solid", borderTopWidth: 0, borderLeftWidth: 0, borderRightWidth: 0, height: "fit-content", fontSize: 14, width: "100%", outlineWidth: 0, marginTop: 5 }}
                                                                name="name"
                                                                type="text"
                                                                autoComplete="off"
                                                                placeholder=""
                                                                required
                                                            //onChange={(txt) => { setFullName(txt.target.value); }}
                                                            >{data.latitude}</p>
                                                        </div>
                                                    </div>
                                                </Col>
                                                <Col>
                                                    <div className="form__form-group" style={{ marginTop: 0 }}>
                                                        <span className="form__form-group-label" style={{ fontSize: 15, fontWeight: 400 }}>
                                                            Longitude</span>
                                                        <div className="form__form-group-field">
                                                            <p
                                                                disabled
                                                                style={{ backgroundColor: "white", paddingLeft: 5, borderColor: "var(--faysal)", borderBottomWidth: 2, borderStyle: "solid", borderTopWidth: 0, borderLeftWidth: 0, borderRightWidth: 0, height: "fit-content", fontSize: 14, width: "100%", outlineWidth: 0, marginTop: 5 }}
                                                                name="name"
                                                                type="text"
                                                                autoComplete="off"
                                                                placeholder=""
                                                                required
                                                            //onChange={(txt) => { setFullName(txt.target.value); }}
                                                            >{data.longitude}</p>
                                                        </div>
                                                    </div>
                                                </Col>
                                                <a href={`https://maps.google.com/maps?q=${data.latitude},${data.longitude}&amp;output=embed`} target={"_blank"}>Open Location</a>
                                            </Row>
                                        </>}
                                    {data.ApplicationStatus === "Application Draft" && screen[currentscreen] <= screen.individual ? <></> :
                                        <>
                                            <h3 style={{ marginBottom: 20, marginTop: 20 }}>Individual Information</h3>

                                            <Row>
                                                <Col>
                                                    <div className="form__form-group" style={{ marginTop: 0 }}>
                                                        <span className="form__form-group-label" style={{ fontSize: 15, fontWeight: 400 }}>
                                                            Mother Maiden Name</span>
                                                        <div className="form__form-group-field">
                                                            <p
                                                                disabled
                                                                style={{ backgroundColor: "white", paddingLeft: 5, borderColor: "var(--faysal)", borderBottomWidth: 2, borderStyle: "solid", borderTopWidth: 0, borderLeftWidth: 0, borderRightWidth: 0, height: "fit-content", fontSize: 14, width: "100%", outlineWidth: 0, marginTop: 5 }}
                                                                name="name"
                                                                type="text"
                                                                autoComplete="off"
                                                                placeholder=""
                                                                required
                                                                value={"Amna"}
                                                            //onChange={(txt) => { setFullName(txt.target.value); }}
                                                            >{data.MotherMaidenName}</p>
                                                        </div>
                                                    </div>
                                                </Col>
                                                <Col>
                                                    <div className="form__form-group" style={{ marginTop: 0 }}>
                                                        <span className="form__form-group-label" style={{ fontSize: 15, fontWeight: 400 }}>
                                                            Father/Husband Name</span>
                                                        <div className="form__form-group-field">
                                                            <p
                                                                disabled
                                                                style={{ backgroundColor: "white", paddingLeft: 5, borderColor: "var(--faysal)", borderBottomWidth: 2, borderStyle: "solid", borderTopWidth: 0, borderLeftWidth: 0, borderRightWidth: 0, height: "fit-content", fontSize: 14, width: "100%", outlineWidth: 0, marginTop: 5 }}
                                                                name="name"
                                                                type="text"
                                                                autoComplete="off"
                                                                placeholder=""
                                                                required
                                                                value={"Syed"}
                                                            //onChange={(txt) => { setFullName(txt.target.value); }}
                                                            >{data.FatherHusbandName}</p>
                                                        </div>
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col>
                                                    <div className="form__form-group" style={{ marginTop: 0 }}>
                                                        <span className="form__form-group-label" style={{ fontSize: 15, fontWeight: 400 }}>
                                                            Gender</span>
                                                        <div className="form__form-group-field">
                                                            <p
                                                                disabled
                                                                style={{ backgroundColor: "white", paddingLeft: 5, borderColor: "var(--faysal)", borderBottomWidth: 2, borderStyle: "solid", borderTopWidth: 0, borderLeftWidth: 0, borderRightWidth: 0, height: "fit-content", fontSize: 14, width: "100%", outlineWidth: 0, marginTop: 5 }}
                                                                name="name"
                                                                type="text"
                                                                autoComplete="off"
                                                                placeholder=""
                                                                required
                                                                value={"Male"}
                                                            //onChange={(txt) => { setFullName(txt.target.value); }}
                                                            >{data.Gender}</p>
                                                        </div>
                                                    </div>
                                                </Col>
                                                <Col>

                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col>
                                                    <div className="form__form-group" style={{ marginTop: 0 }}>
                                                        <span className="form__form-group-label" style={{ fontSize: 15, fontWeight: 400 }}>
                                                            Place of Birth City</span>
                                                        <div className="form__form-group-field">
                                                            <p
                                                                disabled
                                                                style={{ backgroundColor: "white", paddingLeft: 5, borderColor: "var(--faysal)", borderBottomWidth: 2, borderStyle: "solid", borderTopWidth: 0, borderLeftWidth: 0, borderRightWidth: 0, height: "fit-content", fontSize: 14, width: "100%", outlineWidth: 0, marginTop: 5 }}
                                                                name="name"
                                                                type="text"
                                                                autoComplete="off"
                                                                placeholder=""
                                                                required
                                                                value={"Male"}
                                                            //onChange={(txt) => { setFullName(txt.target.value); }}
                                                            >{data.PlaceOfCity}</p>
                                                        </div>
                                                    </div>
                                                </Col>
                                                <Col>
                                                    <div className="form__form-group" style={{ marginTop: 0 }}>
                                                        <span className="form__form-group-label" style={{ fontSize: 15, fontWeight: 400 }}>
                                                            Place of Birth Province</span>
                                                        <div className="form__form-group-field">
                                                            <p
                                                                disabled
                                                                style={{ backgroundColor: "white", paddingLeft: 5, borderColor: "var(--faysal)", borderBottomWidth: 2, borderStyle: "solid", borderTopWidth: 0, borderLeftWidth: 0, borderRightWidth: 0, height: "fit-content", fontSize: 14, width: "100%", outlineWidth: 0, marginTop: 5 }}
                                                                name="name"
                                                                type="text"
                                                                autoComplete="off"
                                                                placeholder=""
                                                                required
                                                                value={"Karachi"}
                                                            //onChange={(txt) => { setFullName(txt.target.value); }}
                                                            >{data.PlaceOfProvice}</p>
                                                        </div>
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col>
                                                    <div className="form__form-group" style={{ marginTop: 0 }}>
                                                        <span className="form__form-group-label" style={{ fontSize: 15, fontWeight: 400 }}>
                                                            Nationality</span>
                                                        <div className="form__form-group-field">
                                                            <p
                                                                disabled
                                                                style={{ backgroundColor: "white", paddingLeft: 5, borderColor: "var(--faysal)", borderBottomWidth: 2, borderStyle: "solid", borderTopWidth: 0, borderLeftWidth: 0, borderRightWidth: 0, height: "fit-content", fontSize: 14, width: "100%", outlineWidth: 0, marginTop: 5 }}
                                                                name="name"
                                                                type="text"
                                                                autoComplete="off"
                                                                placeholder=""
                                                                required
                                                                value={"Pakistan"}
                                                            //onChange={(txt) => { setFullName(txt.target.value); }}
                                                            >{data.Nationality}</p>
                                                        </div>
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row>
                                                {data?.ProductTypeId !== 1 && data?.ProductTypeId !== 2 && data?.ProductTypeId !== 3 ?
                                                    <>

                                                        <Col>
                                                            <div className="form__form-group" style={{ marginTop: 0 }}>
                                                                <span className="form__form-group-label" style={{ fontSize: 15, fontWeight: 400 }}>
                                                                    Other Nationality</span>
                                                                <div className="form__form-group-field">
                                                                    <p
                                                                        disabled
                                                                        style={{ backgroundColor: "white", paddingLeft: 5, borderColor: "var(--faysal)", borderBottomWidth: 2, borderStyle: "solid", borderTopWidth: 0, borderLeftWidth: 0, borderRightWidth: 0, height: "fit-content", fontSize: 14, width: "100%", outlineWidth: 0, marginTop: 5 }}
                                                                        name="name"
                                                                        type="text"
                                                                        autoComplete="off"
                                                                        placeholder=""
                                                                        required
                                                                        value={"Pakistan"}
                                                                    //onChange={(txt) => { setFullName(txt.target.value); }}
                                                                    >{data.OtherNationality}</p>
                                                                </div>
                                                            </div>
                                                        </Col>
                                                        <Col>
                                                            <div className="form__form-group" style={{ marginTop: 0 }}>
                                                                <span className="form__form-group-label" style={{ fontSize: 15, fontWeight: 400 }}>
                                                                    Marital Status</span>
                                                                <div className="form__form-group-field">
                                                                    <p
                                                                        disabled
                                                                        style={{ backgroundColor: "white", paddingLeft: 5, borderColor: "var(--faysal)", borderBottomWidth: 2, borderStyle: "solid", borderTopWidth: 0, borderLeftWidth: 0, borderRightWidth: 0, height: "fit-content", fontSize: 14, width: "100%", outlineWidth: 0, marginTop: 5 }}
                                                                        name="name"
                                                                        type="text"
                                                                        autoComplete="off"
                                                                        placeholder=""
                                                                        required
                                                                        value={"Single"}
                                                                    //onChange={(txt) => { setFullName(txt.target.value); }}
                                                                    >{data.MaritalStatus}</p>
                                                                </div>
                                                            </div>
                                                        </Col>

                                                    </> : <></>}
                                            </Row>
                                            <Row>

                                                <Col>
                                                    <div className="form__form-group" style={{ marginTop: 0 }}>
                                                        <span className="form__form-group-label" style={{ fontSize: 15, fontWeight: 400 }}>
                                                            Present Residential Address</span>
                                                        <div className="form__form-group-field">
                                                            <p
                                                                disabled
                                                                style={{ backgroundColor: "white", paddingLeft: 5, borderColor: "var(--faysal)", borderBottomWidth: 2, borderStyle: "solid", borderTopWidth: 0, borderLeftWidth: 0, borderRightWidth: 0, height: "fit-content", fontSize: 14, width: "100%", outlineWidth: 0, marginTop: 5 }}
                                                                name="name"
                                                                type="text"
                                                                autoComplete="off"
                                                                placeholder=""
                                                                required
                                                                value={"Shafiq"}
                                                            //onChange={(txt) => { setFullName(txt.target.value); }}
                                                            >{data.PresentResidentialAddress}</p>
                                                        </div>
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col>
                                                    <div className="form__form-group" style={{ marginTop: 0 }}>
                                                        <span className="form__form-group-label" style={{ fontSize: 15, fontWeight: 400 }}>
                                                            Permanent Residential Address</span>
                                                        <div className="form__form-group-field">
                                                            <p
                                                                disabled
                                                                style={{ backgroundColor: "white", paddingLeft: 5, borderColor: "var(--faysal)", borderBottomWidth: 2, borderStyle: "solid", borderTopWidth: 0, borderLeftWidth: 0, borderRightWidth: 0, height: "fit-content", fontSize: 14, width: "100%", outlineWidth: 0, marginTop: 5 }}
                                                                name="name"
                                                                type="text"
                                                                autoComplete="off"
                                                                placeholder=""
                                                                required
                                                                value={"Pakistan"}
                                                            //onChange={(txt) => { setFullName(txt.target.value); }}
                                                            >{data.PermanentResidentialAddress}</p>
                                                        </div>
                                                    </div>
                                                </Col>
                                                <Col>
                                                    <div className="form__form-group" style={{ marginTop: 0 }}>
                                                        <span className="form__form-group-label" style={{ fontSize: 15, fontWeight: 400 }}>
                                                            Residential /landline contact number</span>
                                                        <div className="form__form-group-field">
                                                            <p
                                                                disabled
                                                                style={{ backgroundColor: "white", paddingLeft: 5, borderColor: "var(--faysal)", borderBottomWidth: 2, borderStyle: "solid", borderTopWidth: 0, borderLeftWidth: 0, borderRightWidth: 0, height: "fit-content", fontSize: 14, width: "100%", outlineWidth: 0, marginTop: 5 }}
                                                                name="name"
                                                                type="text"
                                                                autoComplete="off"
                                                                placeholder=""
                                                                required
                                                                value={"Secondary School"}
                                                            //onChange={(txt) => { setFullName(txt.target.value); }}
                                                            >{data.Landline}</p>
                                                        </div>
                                                    </div>
                                                </Col>
                                            </Row>
                                            {data?.ProductTypeId !== 1 && data?.ProductTypeId !== 2 && data?.ProductTypeId !== 3 ?
                                                <>
                                                    <Row>
                                                        <Col>
                                                            <div className="form__form-group" style={{ marginTop: 0 }}>
                                                                <span className="form__form-group-label" style={{ fontSize: 15, fontWeight: 400 }}>
                                                                    Next of Kin Name</span>
                                                                <div className="form__form-group-field">
                                                                    <p
                                                                        disabled
                                                                        style={{ backgroundColor: "white", paddingLeft: 5, borderColor: "var(--faysal)", borderBottomWidth: 2, borderStyle: "solid", borderTopWidth: 0, borderLeftWidth: 0, borderRightWidth: 0, height: "fit-content", fontSize: 14, width: "100%", outlineWidth: 0, marginTop: 5 }}
                                                                        name="name"
                                                                        type="text"
                                                                        autoComplete="off"
                                                                        placeholder=""
                                                                        required
                                                                        value={"Shafiq"}
                                                                    //onChange={(txt) => { setFullName(txt.target.value); }}
                                                                    >{data.NextofKinName}</p>
                                                                </div>
                                                            </div>
                                                        </Col>
                                                        <Col>
                                                            <div className="form__form-group" style={{ marginTop: 0 }}>
                                                                <span className="form__form-group-label" style={{ fontSize: 15, fontWeight: 400 }}>
                                                                    Next of Kin Address</span>
                                                                <div className="form__form-group-field">
                                                                    <p
                                                                        disabled
                                                                        style={{ backgroundColor: "white", paddingLeft: 5, borderColor: "var(--faysal)", borderBottomWidth: 2, borderStyle: "solid", borderTopWidth: 0, borderLeftWidth: 0, borderRightWidth: 0, height: "fit-content", fontSize: 14, width: "100%", outlineWidth: 0, marginTop: 5 }}
                                                                        name="name"
                                                                        type="text"
                                                                        autoComplete="off"
                                                                        placeholder=""
                                                                        required
                                                                        value={"3rd Floor, Shahrah-e-liaquat, sindh"}
                                                                    //onChange={(txt) => { setFullName(txt.target.value); }}
                                                                    >{data.NextOfKinAddress}</p>
                                                                </div>
                                                            </div>
                                                        </Col>

                                                    </Row>
                                                    <Row>
                                                        <Col>
                                                            <div className="form__form-group" style={{ marginTop: 0 }}>
                                                                <span className="form__form-group-label" style={{ fontSize: 15, fontWeight: 400 }}>
                                                                    Relation with Next of Kin</span>
                                                                <div className="form__form-group-field">
                                                                    <p
                                                                        disabled
                                                                        style={{ backgroundColor: "white", paddingLeft: 5, borderColor: "var(--faysal)", borderBottomWidth: 2, borderStyle: "solid", borderTopWidth: 0, borderLeftWidth: 0, borderRightWidth: 0, height: "fit-content", fontSize: 14, width: "100%", outlineWidth: 0, marginTop: 5 }}
                                                                        name="name"
                                                                        type="text"
                                                                        autoComplete="off"
                                                                        placeholder=""
                                                                        required
                                                                        value={"Brother"}
                                                                    //onChange={(txt) => { setFullName(txt.target.value); }}
                                                                    >{data.NextOfKinRelation}</p>
                                                                </div>
                                                            </div>
                                                        </Col>
                                                        <Col>
                                                            <div className="form__form-group" style={{ marginTop: 0 }}>
                                                                <span className="form__form-group-label" style={{ fontSize: 15, fontWeight: 400 }}>
                                                                    Next of Kin Contact number</span>
                                                                <div className="form__form-group-field">
                                                                    <p
                                                                        disabled
                                                                        style={{ backgroundColor: "white", paddingLeft: 5, borderColor: "var(--faysal)", borderBottomWidth: 2, borderStyle: "solid", borderTopWidth: 0, borderLeftWidth: 0, borderRightWidth: 0, height: "fit-content", fontSize: 14, width: "100%", outlineWidth: 0, marginTop: 5 }}
                                                                        name="name"
                                                                        type="text"
                                                                        autoComplete="off"
                                                                        placeholder=""
                                                                        required
                                                                        value={"+92455669874"}
                                                                    //onChange={(txt) => { setFullName(txt.target.value); }}
                                                                    >{data.NextOfKinContactNumber}</p>
                                                                </div>
                                                            </div>
                                                        </Col>

                                                    </Row>
                                                </> : <></>}
                                            <Row>
                                                <Col>
                                                    <div className="form__form-group" style={{ marginTop: 0 }}>
                                                        <span className="form__form-group-label" style={{ fontSize: 15, fontWeight: 400 }}>
                                                            Occupation</span>
                                                        <div className="form__form-group-field">
                                                            <p
                                                                disabled
                                                                style={{ backgroundColor: "white", paddingLeft: 5, borderColor: "var(--faysal)", borderBottomWidth: 2, borderStyle: "solid", borderTopWidth: 0, borderLeftWidth: 0, borderRightWidth: 0, height: "fit-content", fontSize: 14, width: "100%", outlineWidth: 0, marginTop: 5 }}
                                                                name="name"
                                                                type="text"
                                                                autoComplete="off"
                                                                placeholder=""
                                                                required
                                                                value={"Salaried"}
                                                            //onChange={(txt) => { setFullName(txt.target.value); }}
                                                            >{data.Occupation}</p>
                                                        </div>
                                                    </div>
                                                </Col>
                                                <Col>
                                                    <div className="form__form-group" style={{ marginTop: 0 }}>
                                                        <span className="form__form-group-label" style={{ fontSize: 15, fontWeight: 400 }}>
                                                            Source of Income</span>
                                                        <div className="form__form-group-field">
                                                            <p
                                                                disabled
                                                                style={{ backgroundColor: "white", paddingLeft: 5, borderColor: "var(--faysal)", borderBottomWidth: 2, borderStyle: "solid", borderTopWidth: 0, borderLeftWidth: 0, borderRightWidth: 0, height: "fit-content", fontSize: 14, width: "100%", outlineWidth: 0, marginTop: 5 }}
                                                                name="name"
                                                                type="text"
                                                                autoComplete="off"
                                                                placeholder=""
                                                                required
                                                                value={"Salary"}
                                                            //onChange={(txt) => { setFullName(txt.target.value); }}
                                                            >{data.SourceOfIncome}</p>
                                                        </div>
                                                    </div>
                                                </Col>
                                            </Row>
                                            {
                                                data?.ProductTypeId === 16 ?
                                                    <>
                                                        <Row>
                                                            <Col>
                                                                <div className="form__form-group" style={{ marginTop: 0 }}>
                                                                    <span className="form__form-group-label" style={{ fontSize: 15, fontWeight: 400 }}>
                                                                        Remitter Name</span>
                                                                    <div className="form__form-group-field">
                                                                        <p
                                                                            disabled
                                                                            style={{ backgroundColor: "white", paddingLeft: 5, borderColor: "var(--faysal)", borderBottomWidth: 2, borderStyle: "solid", borderTopWidth: 0, borderLeftWidth: 0, borderRightWidth: 0, height: "fit-content", fontSize: 14, width: "100%", outlineWidth: 0, marginTop: 5 }}
                                                                            name="name"
                                                                            type="text"
                                                                            autoComplete="off"
                                                                            placeholder=""
                                                                            required
                                                                            value={"Salaried"}
                                                                        //onChange={(txt) => { setFullName(txt.target.value); }}
                                                                        >{data.RemitterName}</p>
                                                                    </div>
                                                                </div>
                                                            </Col>
                                                            <Col>
                                                                <div className="form__form-group" style={{ marginTop: 0 }}>
                                                                    <span className="form__form-group-label" style={{ fontSize: 15, fontWeight: 400 }}>
                                                                        Remitter Relation</span>
                                                                    <div className="form__form-group-field">
                                                                        <p
                                                                            disabled
                                                                            style={{ backgroundColor: "white", paddingLeft: 5, borderColor: "var(--faysal)", borderBottomWidth: 2, borderStyle: "solid", borderTopWidth: 0, borderLeftWidth: 0, borderRightWidth: 0, height: "fit-content", fontSize: 14, width: "100%", outlineWidth: 0, marginTop: 5 }}
                                                                            name="name"
                                                                            type="text"
                                                                            autoComplete="off"
                                                                            placeholder=""
                                                                            required
                                                                            value={"Salary"}
                                                                        //onChange={(txt) => { setFullName(txt.target.value); }}
                                                                        >{data.Remitterrel}</p>
                                                                    </div>
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                    </>
                                                    : <></>
                                            }
                                            {
                                                data?.ProductTypeId !== 1 && data?.ProductTypeId !== 2 && data?.ProductTypeId !== 3 || (data.SupporterName !== "" && data.SupporterName !== null) ? <>
                                                    <Row>
                                                        <Col>
                                                            <div className="form__form-group" style={{ marginTop: 0 }}>
                                                                <span className="form__form-group-label" style={{ fontSize: 15, fontWeight: 400 }}>
                                                                    Supporter Name</span>
                                                                <div className="form__form-group-field">
                                                                    <p
                                                                        disabled
                                                                        style={{ backgroundColor: "white", paddingLeft: 5, borderColor: "var(--faysal)", borderBottomWidth: 2, borderStyle: "solid", borderTopWidth: 0, borderLeftWidth: 0, borderRightWidth: 0, height: "fit-content", fontSize: 14, width: "100%", outlineWidth: 0, marginTop: 5 }}
                                                                        name="name"
                                                                        type="text"
                                                                        autoComplete="off"
                                                                        placeholder=""
                                                                        required
                                                                        value={"Salaried"}
                                                                    //onChange={(txt) => { setFullName(txt.target.value); }}
                                                                    >{data.SupporterName}</p>
                                                                </div>
                                                            </div>
                                                        </Col>
                                                        <Col>
                                                            <div className="form__form-group" style={{ marginTop: 0 }}>
                                                                <span className="form__form-group-label" style={{ fontSize: 15, fontWeight: 400 }}>
                                                                    Supporter CNIC</span>
                                                                <div className="form__form-group-field">
                                                                    <p
                                                                        disabled
                                                                        style={{ backgroundColor: "white", paddingLeft: 5, borderColor: "var(--faysal)", borderBottomWidth: 2, borderStyle: "solid", borderTopWidth: 0, borderLeftWidth: 0, borderRightWidth: 0, height: "fit-content", fontSize: 14, width: "100%", outlineWidth: 0, marginTop: 5 }}
                                                                        name="name"
                                                                        type="text"
                                                                        autoComplete="off"
                                                                        placeholder=""
                                                                        required
                                                                        value={"Salary"}
                                                                    //onChange={(txt) => { setFullName(txt.target.value); }}
                                                                    >{data.SupporterCNIC}</p>
                                                                </div>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col>
                                                            <div className="form__form-group" style={{ marginTop: 0 }}>
                                                                <span className="form__form-group-label" style={{ fontSize: 15, fontWeight: 400 }}>
                                                                    Supporter Relationship</span>
                                                                <div className="form__form-group-field">
                                                                    <p
                                                                        disabled
                                                                        style={{ backgroundColor: "white", paddingLeft: 5, borderColor: "var(--faysal)", borderBottomWidth: 2, borderStyle: "solid", borderTopWidth: 0, borderLeftWidth: 0, borderRightWidth: 0, height: "fit-content", fontSize: 14, width: "100%", outlineWidth: 0, marginTop: 5 }}
                                                                        name="name"
                                                                        type="text"
                                                                        autoComplete="off"
                                                                        placeholder=""
                                                                        required
                                                                        value={"Salaried"}
                                                                    //onChange={(txt) => { setFullName(txt.target.value); }}
                                                                    >{data.SupporterRelationship}</p>
                                                                </div>
                                                            </div>
                                                        </Col>
                                                        <Col>
                                                            <div className="form__form-group" style={{ marginTop: 0 }}>
                                                                <span className="form__form-group-label" style={{ fontSize: 15, fontWeight: 400 }}>
                                                                    Supporter Job</span>
                                                                <div className="form__form-group-field">
                                                                    <p
                                                                        disabled
                                                                        style={{ backgroundColor: "white", paddingLeft: 5, borderColor: "var(--faysal)", borderBottomWidth: 2, borderStyle: "solid", borderTopWidth: 0, borderLeftWidth: 0, borderRightWidth: 0, height: "fit-content", fontSize: 14, width: "100%", outlineWidth: 0, marginTop: 5 }}
                                                                        name="name"
                                                                        type="text"
                                                                        autoComplete="off"
                                                                        placeholder=""
                                                                        required
                                                                        value={"Salary"}
                                                                    //onChange={(txt) => { setFullName(txt.target.value); }}
                                                                    >{data.SupporterJob}</p>
                                                                </div>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                </> : <></>
                                            }
                                        </>}
                                    {data.ApplicationStatus === "Application Draft" && screen[currentscreen] <= screen.znt ? <></> :
                                        <>
                                            <h3 style={{ marginBottom: 20, marginTop: 20 }}> ZAKAT,FATCA & CRS Status</h3>
                                            <Row>
                                                <Col>
                                                    <div className="form__form-group" style={{ marginTop: 0 }}>
                                                        <span className="form__form-group-label" style={{ fontSize: 15, fontWeight: 400 }}>
                                                            Zakat Exemption</span>
                                                        <div className="form__form-group-field">
                                                            <p
                                                                disabled
                                                                style={{ backgroundColor: "white", paddingLeft: 5, borderColor: "var(--faysal)", borderBottomWidth: 2, borderStyle: "solid", borderTopWidth: 0, borderLeftWidth: 0, borderRightWidth: 0, height: "fit-content", fontSize: 14, width: "100%", outlineWidth: 0, marginTop: 5 }}
                                                                name="name"
                                                                type="text"
                                                                autoComplete="off"
                                                                placeholder=""
                                                                required
                                                                value={"Yes"}
                                                            //onChange={(txt) => { setFullName(txt.target.value); }}
                                                            >{data.ZakatExemption === true ? "Yes" : "No"}</p>
                                                        </div>
                                                    </div>
                                                </Col>
                                                <Col>
                                                    <div className="form__form-group" style={{ marginTop: 0 }}>
                                                        <span className="form__form-group-label" style={{ fontSize: 15, fontWeight: 400 }}>
                                                            Reason For Exemption</span>
                                                        <div className="form__form-group-field">
                                                            <p
                                                                disabled
                                                                style={{ backgroundColor: "white", paddingLeft: 5, borderColor: "var(--faysal)", borderBottomWidth: 2, borderStyle: "solid", borderTopWidth: 0, borderLeftWidth: 0, borderRightWidth: 0, height: "fit-content", fontSize: 14, width: "100%", outlineWidth: 0, marginTop: 5 }}
                                                                name="name"
                                                                type="text"
                                                                autoComplete="off"
                                                                placeholder=""
                                                                required
                                                                value={"Non-Muslim"}
                                                            //onChange={(txt) => { setFullName(txt.target.value); }}
                                                            >{data.ZakatExemption === true ? data.ReasonForExemptionOfZakat : "None"}</p>
                                                        </div>
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col>
                                                    <div className="form__form-group" style={{ marginTop: 0 }}>
                                                        <span className="form__form-group-label" style={{ fontSize: 15, fontWeight: 400 }}>
                                                            FATCA Status</span>
                                                        <div className="form__form-group-field">
                                                            <p
                                                                disabled
                                                                style={{ backgroundColor: "white", paddingLeft: 5, borderColor: "var(--faysal)", borderBottomWidth: 2, borderStyle: "solid", borderTopWidth: 0, borderLeftWidth: 0, borderRightWidth: 0, height: "fit-content", fontSize: 14, width: "100%", outlineWidth: 0, marginTop: 5 }}
                                                                name="name"
                                                                type="text"
                                                                autoComplete="off"
                                                                placeholder=""
                                                                required
                                                                value={"US Person"}
                                                            //onChange={(txt) => { setFullName(txt.target.value); }}
                                                            >{data.FATCAStatus}</p>
                                                        </div>
                                                    </div>
                                                </Col>
                                                <Col>
                                                    {/* <div className="form__form-group" style={{ marginTop: 0 }}>
                                                        <span className="form__form-group-label" style={{ fontSize: 15, fontWeight: 400 }}>
                                                            FATCA Declarations</span>
                                                        <div className="form__form-group-field">
                                                            <p
                                                                disabled
                                                                style={{ backgroundColor: "white", paddingLeft: 5, borderColor: "var(--faysal)", borderBottomWidth: 2, borderStyle: "solid", borderTopWidth: 0, borderLeftWidth: 0, borderRightWidth: 0, height: "fit-content", fontSize: 14, width: "100%", outlineWidth: 0, marginTop: 5 }}
                                                                name="name"
                                                                type="text"
                                                                autoComplete="off"
                                                                placeholder=""
                                                                required
                                                                value={"W-9 US Person"}
                                                            //onChange={(txt) => { setFullName(txt.target.value); }}
                                                            >{data.FATCADeclarations}</p>
                                                        </div>
                                                    </div> */}
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col>
                                                    {/* <div className="form__form-group" style={{ marginTop: 0 }}>
                                                        <span className="form__form-group-label" style={{ fontSize: 15, fontWeight: 400 }}>
                                                            Country of Residence for Tax Purpose</span>
                                                        <div className="form__form-group-field">
                                                            <p
                                                                disabled
                                                                style={{ backgroundColor: "white", paddingLeft: 5, borderColor: "var(--faysal)", borderBottomWidth: 2, borderStyle: "solid", borderTopWidth: 0, borderLeftWidth: 0, borderRightWidth: 0, height: "fit-content", fontSize: 14, width: "100%", outlineWidth: 0, marginTop: 5 }}
                                                                name="name"
                                                                type="text"
                                                                autoComplete="off"
                                                                placeholder=""
                                                                required
                                                                value={"US"}
                                                            //onChange={(txt) => { setFullName(txt.target.value); }}
                                                            >{data.CountryTaxResidenceCountry}</p>
                                                        </div>
                                                    </div> */}
                                                </Col>
                                                <Col>
                                                    {/* <div className="form__form-group" style={{ marginTop: 0 }}>
                                                        <span className="form__form-group-label" style={{ fontSize: 15, fontWeight: 400 }}>
                                                            Tax Identification No (TIN)</span>
                                                        <div className="form__form-group-field">
                                                            <p
                                                                disabled
                                                                style={{ backgroundColor: "white", paddingLeft: 5, borderColor: "var(--faysal)", borderBottomWidth: 2, borderStyle: "solid", borderTopWidth: 0, borderLeftWidth: 0, borderRightWidth: 0, height: "fit-content", fontSize: 14, width: "100%", outlineWidth: 0, marginTop: 5 }}
                                                                name="name"
                                                                type="text"
                                                                autoComplete="off"
                                                                placeholder=""
                                                                required
                                                                value={"92669855"}
                                                            //onChange={(txt) => { setFullName(txt.target.value); }}
                                                            >{data.CountryTaxResidenceTIN}</p>
                                                        </div>
                                                    </div> */}
                                                </Col>
                                            </Row>
                                            {/* <Row>
                                                <Col>
                                                    <div className="form__form-group" style={{ marginTop: 0 }}>
                                                        <span className="form__form-group-label" style={{ fontSize: 15, fontWeight: 400 }}>
                                                            Reason if no TIN available</span>
                                                        <div className="form__form-group-field">
                                                            <p
                                                                disabled
                                                                style={{ backgroundColor: "white", paddingLeft: 5, borderColor: "var(--faysal)", borderBottomWidth: 2, borderStyle: "solid", borderTopWidth: 0, borderLeftWidth: 0, borderRightWidth: 0, height: "fit-content", fontSize: 14, width: "100%", outlineWidth: 0, marginTop: 5 }}
                                                                name="name"
                                                                type="text"
                                                                autoComplete="off"
                                                                placeholder=""
                                                                required
                                                                value={"The Account Holder is otherwise unable to obtain a TIN or equivalent please explain why you are unable to obtain a TIN in the below REMARKS field."}
                                                            //onChange={(txt) => { setFullName(txt.target.value); }}
                                                            >{data.CountryTaxResidenceTINReason}</p>
                                                        </div>
                                                    </div>
                                                </Col>
                                                <Col>
                                                    <div className="form__form-group" style={{ marginTop: 0 }}>
                                                        <span className="form__form-group-label" style={{ fontSize: 15, fontWeight: 400 }}>
                                                            Remarks</span>
                                                        <div className="form__form-group-field">
                                                            <p
                                                                disabled
                                                                style={{ backgroundColor: "white", paddingLeft: 5, borderColor: "var(--faysal)", borderBottomWidth: 2, borderStyle: "solid", borderTopWidth: 0, borderLeftWidth: 0, borderRightWidth: 0, height: "fit-content", fontSize: 14, width: "100%", outlineWidth: 0, marginTop: 5 }}
                                                                name="name"
                                                                type="text"
                                                                autoComplete="off"
                                                                placeholder=""
                                                                required
                                                                value={"The Account Holder is otherwise unable to obtain a TIN."}
                                                            //onChange={(txt) => { setFullName(txt.target.value); }}
                                                            >{data.CountryTaxResidenceTINRemarks}</p>
                                                        </div>
                                                    </div>
                                                </Col>
                                            </Row> */}
                                        </>}
                                    {data.ApplicationStatus === "Application Draft" && screen[currentscreen] <= screen.cnc ? <></> :
                                        <>
                                            <h3 style={{ marginBottom: 20, marginTop: 20 }}>Card & Chequebook Details</h3>
                                            <Row>
                                                <Col>
                                                    <div className="form__form-group" style={{ marginTop: 0 }}>
                                                        <span className="form__form-group-label" style={{ fontSize: 15, fontWeight: 400 }}>
                                                            Cheque Book</span>
                                                        <div className="form__form-group-field">
                                                            <p
                                                                disabled
                                                                style={{ backgroundColor: "white", paddingLeft: 5, borderColor: "var(--faysal)", borderBottomWidth: 2, borderStyle: "solid", borderTopWidth: 0, borderLeftWidth: 0, borderRightWidth: 0, height: "fit-content", fontSize: 14, width: "100%", outlineWidth: 0, marginTop: 5 }}
                                                                name="name"
                                                                type="text"
                                                                autoComplete="off"
                                                                placeholder=""
                                                                required
                                                                value={"Yes"}
                                                            //onChange={(txt) => { setFullName(txt.target.value); }}
                                                            >{data.ChequeBook === true ? "Yes" : "No"}</p>
                                                        </div>
                                                    </div>
                                                </Col>
                                                <Col>
                                                    <div className="form__form-group" style={{ marginTop: 0 }}>
                                                        <span className="form__form-group-label" style={{ fontSize: 15, fontWeight: 400 }}>
                                                            No. of Leave’s</span>
                                                        <div className="form__form-group-field">
                                                            <p
                                                                disabled
                                                                style={{ backgroundColor: "white", paddingLeft: 5, borderColor: "var(--faysal)", borderBottomWidth: 2, borderStyle: "solid", borderTopWidth: 0, borderLeftWidth: 0, borderRightWidth: 0, height: "fit-content", fontSize: 14, width: "100%", outlineWidth: 0, marginTop: 5 }}
                                                                name="name"
                                                                type="text"
                                                                autoComplete="off"
                                                                placeholder=""
                                                                required
                                                                value={"50"}
                                                            //onChange={(txt) => { setFullName(txt.target.value); }}
                                                            >{data.ChequeBookNoOfLeaves}</p>
                                                        </div>
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col>
                                                    <div className="form__form-group" style={{ marginTop: 0 }}>
                                                        <span className="form__form-group-label" style={{ fontSize: 15, fontWeight: 400 }}>
                                                            Electronic Fund Transfer (EFT)Consent</span>
                                                        <div className="form__form-group-field">
                                                            <p
                                                                disabled
                                                                style={{ backgroundColor: "white", paddingLeft: 5, borderColor: "var(--faysal)", borderBottomWidth: 2, borderStyle: "solid", borderTopWidth: 0, borderLeftWidth: 0, borderRightWidth: 0, height: "fit-content", fontSize: 14, width: "100%", outlineWidth: 0, marginTop: 5 }}
                                                                name="name"
                                                                type="text"
                                                                autoComplete="off"
                                                                placeholder=""
                                                                required
                                                                value={"Yes"}
                                                            //onChange={(txt) => { setFullName(txt.target.value); }}
                                                            >{data.EFT === true ? "Yes" : "No"}</p>
                                                        </div>
                                                    </div>
                                                </Col>
                                                <Col>
                                                    <div className="form__form-group" style={{ marginTop: 0 }}>
                                                        <span className="form__form-group-label" style={{ fontSize: 15, fontWeight: 400 }}>
                                                            E-Statement Subscription</span>
                                                        <div className="form__form-group-field">
                                                            <p
                                                                disabled
                                                                style={{ backgroundColor: "white", paddingLeft: 5, borderColor: "var(--faysal)", borderBottomWidth: 2, borderStyle: "solid", borderTopWidth: 0, borderLeftWidth: 0, borderRightWidth: 0, height: "fit-content", fontSize: 14, width: "100%", outlineWidth: 0, marginTop: 5 }}
                                                                name="name"
                                                                type="text"
                                                                autoComplete="off"
                                                                placeholder=""
                                                                required
                                                                value={"No"}
                                                            //onChange={(txt) => { setFullName(txt.target.value); }}
                                                            >{data.ESS === true ? "Yes" : "No"}</p>
                                                        </div>
                                                    </div>
                                                </Col>
                                            </Row>

                                            <Row>
                                                <Col>
                                                    <div className="form__form-group" style={{ marginTop: 0 }}>
                                                        <span className="form__form-group-label" style={{ fontSize: 15, fontWeight: 400 }}>
                                                            E-Statement Frequency</span>
                                                        <div className="form__form-group-field">
                                                            <p
                                                                disabled
                                                                style={{ backgroundColor: "white", paddingLeft: 5, borderColor: "var(--faysal)", borderBottomWidth: 2, borderStyle: "solid", borderTopWidth: 0, borderLeftWidth: 0, borderRightWidth: 0, height: "fit-content", fontSize: 14, width: "100%", outlineWidth: 0, marginTop: 5 }}
                                                                name="name"
                                                                type="text"
                                                                autoComplete="off"
                                                                placeholder=""
                                                                required
                                                                value={"Yearly"}
                                                            //onChange={(txt) => { setFullName(txt.target.value); }}
                                                            >{data.ESF}</p>
                                                        </div>
                                                    </div>
                                                </Col>
                                                <Col>
                                                    <div className="form__form-group" style={{ marginTop: 0 }}>
                                                        <span className="form__form-group-label" style={{ fontSize: 15, fontWeight: 400 }}>
                                                            Selected Branch</span>
                                                        <div className="form__form-group-field">
                                                            <p
                                                                disabled
                                                                style={{ backgroundColor: "white", paddingLeft: 5, borderColor: "var(--faysal)", borderBottomWidth: 2, borderStyle: "solid", borderTopWidth: 0, borderLeftWidth: 0, borderRightWidth: 0, height: "fit-content", fontSize: 14, width: "100%", outlineWidth: 0, marginTop: 5 }}
                                                                name="name"
                                                                type="text"
                                                                autoComplete="off"
                                                                placeholder=""
                                                                required
                                                                value={"Main Br. Karachi-0002"}
                                                            //onChange={(txt) => { setFullName(txt.target.value); }}
                                                            >{
                                                                    data.BranchDetail != null ? JSON.parse(data.BranchDetail)["Branch Code"] + "-" + JSON.parse(data.BranchDetail)["Branch Name"] + "," + JSON.parse(data.BranchDetail).City + "," + JSON.parse(data.BranchDetail).Province : ""
                                                                }</p>
                                                        </div>
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col>
                                                    <div className="form__form-group" style={{ marginTop: 0 }}>
                                                        <span className="form__form-group-label" style={{ fontSize: 15, fontWeight: 400 }}>
                                                            Branch Staff Referral</span>
                                                        <div className="form__form-group-field">
                                                            <p
                                                                disabled
                                                                style={{ backgroundColor: "white", paddingLeft: 5, borderColor: "var(--faysal)", borderBottomWidth: 2, borderStyle: "solid", borderTopWidth: 0, borderLeftWidth: 0, borderRightWidth: 0, height: "fit-content", fontSize: 14, width: "100%", outlineWidth: 0, marginTop: 5 }}
                                                                name="name"
                                                                type="text"
                                                                autoComplete="off"
                                                                placeholder=""
                                                                required
                                                                value={"Mr Karim"}
                                                            //onChange={(txt) => { setFullName(txt.target.value); }}
                                                            >{data.BranchRef}</p>
                                                        </div>
                                                    </div>
                                                </Col>
                                                <Col>
                                                    <div className="form__form-group" style={{ marginTop: 0 }}>
                                                        <span className="form__form-group-label" style={{ fontSize: 15, fontWeight: 400 }}>
                                                            ATM Debit Card</span>
                                                        <div className="form__form-group-field">
                                                            <p
                                                                disabled
                                                                style={{ backgroundColor: "white", paddingLeft: 5, borderColor: "var(--faysal)", borderBottomWidth: 2, borderStyle: "solid", borderTopWidth: 0, borderLeftWidth: 0, borderRightWidth: 0, height: "fit-content", fontSize: 14, width: "100%", outlineWidth: 0, marginTop: 5 }}
                                                                name="name"
                                                                type="text"
                                                                autoComplete="off"
                                                                placeholder=""
                                                                required
                                                                value={"Yes"}
                                                            //onChange={(txt) => { setFullName(txt.target.value); }}
                                                            >{data.ATMDebitCard === false ? "No" : "Yes"}</p>
                                                        </div>
                                                    </div>
                                                </Col>
                                            </Row>
                                            {true ?
                                                <Row>
                                                    <Col>
                                                        <div className="form__form-group" style={{ marginTop: 0 }}>
                                                            <span className="form__form-group-label" style={{ fontSize: 15, fontWeight: 400 }}>
                                                                Card Type</span>
                                                            <div className="form__form-group-field">
                                                                <p
                                                                    disabled
                                                                    style={{ backgroundColor: "white", paddingLeft: 5, borderColor: "var(--faysal)", borderBottomWidth: 2, borderStyle: "solid", borderTopWidth: 0, borderLeftWidth: 0, borderRightWidth: 0, height: "fit-content", fontSize: 14, width: "100%", outlineWidth: 0, marginTop: 5 }}
                                                                    name="name"
                                                                    type="text"
                                                                    autoComplete="off"
                                                                    placeholder=""
                                                                    required
                                                                    value={"Soneri Master Classic Debit"}
                                                                //onChange={(txt) => { setFullName(txt.target.value); }}
                                                                >{data.DebitCardType}</p>
                                                            </div>
                                                        </div>
                                                    </Col>
                                                    <Col>
                                                        {data.CardReason !== "" ?
                                                            <div className="form__form-group" style={{ marginTop: 0 }}>
                                                                <span className="form__form-group-label" style={{ fontSize: 15, fontWeight: 400 }}>
                                                                    Reason</span>
                                                                <div className="form__form-group-field">
                                                                    <p
                                                                        disabled
                                                                        style={{ backgroundColor: "white", paddingLeft: 5, borderColor: "var(--faysal)", borderBottomWidth: 2, borderStyle: "solid", borderTopWidth: 0, borderLeftWidth: 0, borderRightWidth: 0, height: "fit-content", fontSize: 14, width: "100%", outlineWidth: 0, marginTop: 5 }}
                                                                        name="name"
                                                                        type="text"
                                                                        autoComplete="off"
                                                                        placeholder=""
                                                                        required
                                                                        value={"Brand Conscious"}
                                                                    //onChange={(txt) => { setFullName(txt.target.value); }}
                                                                    >{data.CardReason}</p>
                                                                </div>
                                                            </div>
                                                            : <></>}
                                                    </Col>
                                                </Row>
                                                : <></>
                                            }
                                            {data?.ProductTypeId !== 1 && data?.ProductTypeId !== 2 && data?.ProductTypeId !== 3 ?
                                                <>
                                                    <Row>
                                                        <Col>
                                                            <div className="form__form-group" style={{ marginTop: 0 }}>
                                                                <span className="form__form-group-label" style={{ fontSize: 15, fontWeight: 400 }}>
                                                                    Nature of Transactions</span>
                                                                <div className="form__form-group-field">
                                                                    <p
                                                                        disabled
                                                                        style={{ backgroundColor: "white", paddingLeft: 5, borderColor: "var(--faysal)", borderBottomWidth: 2, borderStyle: "solid", borderTopWidth: 0, borderLeftWidth: 0, borderRightWidth: 0, height: "fit-content", fontSize: 14, width: "100%", outlineWidth: 0, marginTop: 5 }}
                                                                        name="name"
                                                                        type="text"
                                                                        autoComplete="off"
                                                                        placeholder=""
                                                                        required
                                                                        value={"Cash/Deposit Withdrawal"}
                                                                    //onChange={(txt) => { setFullName(txt.target.value); }}
                                                                    >{data.NatureOfTransactions}</p>
                                                                </div>
                                                            </div>
                                                        </Col>
                                                        <Col>
                                                            <div className="form__form-group" style={{ marginTop: 0 }}>
                                                                <span className="form__form-group-label" style={{ fontSize: 15, fontWeight: 400 }}>
                                                                    Estimated Annual Income</span>
                                                                <div className="form__form-group-field">
                                                                    <p
                                                                        disabled
                                                                        style={{ backgroundColor: "white", paddingLeft: 5, borderColor: "var(--faysal)", borderBottomWidth: 2, borderStyle: "solid", borderTopWidth: 0, borderLeftWidth: 0, borderRightWidth: 0, height: "fit-content", fontSize: 14, width: "100%", outlineWidth: 0, marginTop: 5 }}
                                                                        name="name"
                                                                        type="text"
                                                                        autoComplete="off"
                                                                        placeholder=""
                                                                        required
                                                                        value={"1 - 500,000"}
                                                                    //onChange={(txt) => { setFullName(txt.target.value); }}
                                                                    >{data.EstimatedAnnualIncome}</p>
                                                                </div>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col>
                                                            <div className="form__form-group" style={{ marginTop: 0 }}>
                                                                <span className="form__form-group-label" style={{ fontSize: 15, fontWeight: 400 }}>
                                                                    Expected Monthly Credit – Transactions</span>
                                                                <div className="form__form-group-field">
                                                                    <p
                                                                        disabled
                                                                        style={{ backgroundColor: "white", paddingLeft: 5, borderColor: "var(--faysal)", borderBottomWidth: 2, borderStyle: "solid", borderTopWidth: 0, borderLeftWidth: 0, borderRightWidth: 0, height: "fit-content", fontSize: 14, width: "100%", outlineWidth: 0, marginTop: 5 }}
                                                                        name="name"
                                                                        type="text"
                                                                        autoComplete="off"
                                                                        placeholder=""
                                                                        required
                                                                        value={"Less Than 15"}
                                                                    //onChange={(txt) => { setFullName(txt.target.value); }}
                                                                    >{data.ExpectedMonthlyCreditTransactions}</p>
                                                                </div>
                                                            </div>
                                                        </Col>
                                                        <Col>
                                                            <div className="form__form-group" style={{ marginTop: 0 }}>
                                                                <span className="form__form-group-label" style={{ fontSize: 15, fontWeight: 400 }}>
                                                                    Expected Monthly Debit – Transactions</span>
                                                                <div className="form__form-group-field">
                                                                    <p
                                                                        disabled
                                                                        style={{ backgroundColor: "white", paddingLeft: 5, borderColor: "var(--faysal)", borderBottomWidth: 2, borderStyle: "solid", borderTopWidth: 0, borderLeftWidth: 0, borderRightWidth: 0, height: "fit-content", fontSize: 14, width: "100%", outlineWidth: 0, marginTop: 5 }}
                                                                        name="name"
                                                                        type="text"
                                                                        autoComplete="off"
                                                                        placeholder=""
                                                                        required
                                                                        value={"Less Than 15"}
                                                                    //onChange={(txt) => { setFullName(txt.target.value); }}
                                                                    >{data.ExpectedMonthlyDebitTransactions}</p>
                                                                </div>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col>
                                                            <div className="form__form-group" style={{ marginTop: 0 }}>
                                                                <span className="form__form-group-label" style={{ fontSize: 15, fontWeight: 400 }}>
                                                                    Expected Monthly Credit</span>
                                                                <div className="form__form-group-field">
                                                                    <p
                                                                        disabled
                                                                        style={{ backgroundColor: "white", paddingLeft: 5, borderColor: "var(--faysal)", borderBottomWidth: 2, borderStyle: "solid", borderTopWidth: 0, borderLeftWidth: 0, borderRightWidth: 0, height: "fit-content", fontSize: 14, width: "100%", outlineWidth: 0, marginTop: 5 }}
                                                                        name="name"
                                                                        type="text"
                                                                        autoComplete="off"
                                                                        placeholder=""
                                                                        required
                                                                        value={"1 - 500,000"}
                                                                    //onChange={(txt) => { setFullName(txt.target.value); }}
                                                                    >{data.ExpectedMonthlyCredit}</p>
                                                                </div>
                                                            </div>
                                                        </Col>
                                                        <Col>
                                                            <div className="form__form-group" style={{ marginTop: 0 }}>
                                                                <span className="form__form-group-label" style={{ fontSize: 15, fontWeight: 400 }}>
                                                                    Expected Monthly Debit</span>
                                                                <div className="form__form-group-field">
                                                                    <p
                                                                        disabled
                                                                        style={{ backgroundColor: "white", paddingLeft: 5, borderColor: "var(--faysal)", borderBottomWidth: 2, borderStyle: "solid", borderTopWidth: 0, borderLeftWidth: 0, borderRightWidth: 0, height: "fit-content", fontSize: 14, width: "100%", outlineWidth: 0, marginTop: 5 }}
                                                                        name="name"
                                                                        type="text"
                                                                        autoComplete="off"
                                                                        placeholder=""
                                                                        required
                                                                        value={"< 500,000"}
                                                                    //onChange={(txt) => { setFullName(txt.target.value); }}
                                                                    >{data.ExpectedMonthlyDebit}</p>
                                                                </div>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                </> : <></>}
                                            <Row>
                                                {/* {data?.ProductTypeId !== 1 && data?.ProductTypeId !== 2 && data?.ProductTypeId !== 3 ? */}
                                                <Col>
                                                    <div className="form__form-group" style={{ marginTop: 0 }}>
                                                        <span className="form__form-group-label" style={{ fontSize: 15, fontWeight: 400 }}>
                                                            Monthly Income</span>
                                                        <div className="form__form-group-field">
                                                            <p
                                                                disabled
                                                                style={{ backgroundColor: "white", paddingLeft: 5, borderColor: "var(--faysal)", borderBottomWidth: 2, borderStyle: "solid", borderTopWidth: 0, borderLeftWidth: 0, borderRightWidth: 0, height: "fit-content", fontSize: 14, width: "100%", outlineWidth: 0, marginTop: 5 }}
                                                                name="name"
                                                                type="text"
                                                                autoComplete="off"
                                                                placeholder=""
                                                                required
                                                                value={"Less Than 15"}
                                                            //onChange={(txt) => { setFullName(txt.target.value); }}
                                                            >{data.MonthlyIncome}</p>
                                                        </div>
                                                    </div>
                                                </Col>
                                                {/* : <></>} */}
                                                <Col>
                                                    <div className="form__form-group" style={{ marginTop: 0 }}>
                                                        <span className="form__form-group-label" style={{ fontSize: 15, fontWeight: 400 }}>
                                                            SMS Subscription</span>
                                                        <div className="form__form-group-field">
                                                            <p
                                                                disabled
                                                                style={{ backgroundColor: "white", paddingLeft: 5, borderColor: "var(--faysal)", borderBottomWidth: 2, borderStyle: "solid", borderTopWidth: 0, borderLeftWidth: 0, borderRightWidth: 0, height: "fit-content", fontSize: 14, width: "100%", outlineWidth: 0, marginTop: 5 }}
                                                                name="name"
                                                                type="text"
                                                                autoComplete="off"
                                                                placeholder=""
                                                                required
                                                                value={"1 - 500,000"}
                                                            //onChange={(txt) => { setFullName(txt.target.value); }}
                                                            >{data.SMSSubscription ? "Yes" : "No"}</p>
                                                        </div>
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row>

                                                {data?.CNICBackPath !== "" && data?.CNICBackPath !== null ?
                                                    <Col>
                                                        <label className="" style={{ marginBottom: 10, marginTop: 20 }}>
                                                            <CheckBox style={{ fill: "#579209" }} />
                                                            All correspondence with the customer will be carried out on above-mentioned email address and mobile no. unless changed in the future by the customer.
                                                        </label>
                                                    </Col>
                                                    : <></>}
                                            </Row>
                                            <Row>

                                                {data?.LivenessImagePath !== "" && data?.LivenessImagePath !== null ?
                                                    <Col>
                                                        <label className="" style={{ marginBottom: 10, marginTop: 20 }}>
                                                            <CheckBox style={{ fill: "#579209" }} />
                                                            I acknowledge the Product Features, Key Fact Statement and Accept the Terms & Conditions.
                                                        </label>
                                                    </Col>
                                                    : <></>}
                                            </Row>
                                            {data.CNICBackReceipt !== null && data.CNICBackReceipt !== "" ? <><Col>
                                                <label className="" style={{ marginBottom: 10, marginTop: 20 }}>
                                                    <CheckBox style={{ fill: "#579209" }} />
                                                    I have requested BANKISLAMI PAKISTAN LIMITED to open my account on the basis of provided CNIC / SNIC number, which has been expired.I hereby confirm that I have applied for renewal of my CNIC / SNIC against which NADRA has issued receipt.I undertake to provide copy of renewed CNIC / SNIC to Bank within the period of 90 days from the date of NADRA Receipt, failing which, Bank is authorized to mark debit block on my account.
                                                </label>
                                            </Col></> : ""}
                                            <Row>

                                                {data?.ChequeBook === true ?
                                                    <Col>
                                                        <label className="" style={{ marginBottom: 10, marginTop: 20 }}>
                                                            <CheckBox style={{ fill: "#579209" }} />
                                                            I/We do hereby authorise BankIslami Ltd. to share my/our account information, including account number and title with the Bank’s approved printer for Cheque Book printing
                                                        </label>
                                                    </Col>
                                                    : <></>}
                                            </Row>
                                            <Row>
                                                {data?.EFTCheckbox !== "None" && data?.EFTCheckbox !== null ?
                                                    <Col>
                                                        <label className="" style={{ marginBottom: 10, marginTop: 20 }}>
                                                            <CheckBox style={{ fill: "#579209" }} />
                                                            {data.EFTCheckbox}
                                                        </label>
                                                    </Col>
                                                    : <></>}
                                            </Row>
                                            <Row>
                                                {data?.ESFCheckbox !== "None" && data?.ESFCheckbox !== null ?
                                                    <Col>
                                                        <label className="" style={{ marginBottom: 10, marginTop: 20 }}>
                                                            <CheckBox style={{ fill: "#579209" }} />
                                                            {data.ESFCheckbox}
                                                        </label>
                                                    </Col>
                                                    : <></>}
                                            </Row>
                                            <Row>

                                                {data?.OccupationCheckBox !== "None" && data?.OccupationCheckBox !== null ?
                                                    <Col>
                                                        <label className="" style={{ marginBottom: 10, marginTop: 20 }}>
                                                            <CheckBox style={{ fill: "#579209" }} />
                                                            {data.OccupationCheckBox}
                                                        </label>
                                                    </Col>
                                                    : <></>}
                                            </Row>
                                            <Row>

                                                {data?.OccupationCheckBox2 !== "None" && data?.OccupationCheckBox2 !== null || [1, 2, 3, 16, 17].indexOf(data?.ProductTypeId) !== -1 ?
                                                    <Col>
                                                        <label className="" style={{ marginBottom: 10, marginTop: 20 }}>
                                                            <CheckBox style={{ fill: "#579209" }} />
                                                            {JSON.parse(authconst).Checkboxes.filter(x => x.id == 12)[0].Discription}
                                                        </label>
                                                    </Col>
                                                    : <></>}
                                            </Row>
                                            <Row>

                                                {data?.OccupationCheckBox3 !== "None" && data?.OccupationCheckBox3 !== null || [1, 2, 3, 16, 17].indexOf(data?.ProductTypeId) !== -1 ?
                                                    <Col>
                                                        <label className="" style={{ marginBottom: 10, marginTop: 20 }}>
                                                            <CheckBox style={{ fill: "#579209" }} />
                                                            {JSON.parse(authconst).Checkboxes.filter(x => x.id == 13)[0].Discription}
                                                        </label>
                                                    </Col>
                                                    : <></>}
                                            </Row>
                                            <Row>

                                                {data?.OccupationCheckBox4 !== "None" && data?.OccupationCheckBox4 !== null || [1, 2, 3, 16, 17].indexOf(data?.ProductTypeId) !== -1 ?
                                                    <Col>
                                                        <label className="" style={{ marginBottom: 10, marginTop: 20 }}>
                                                            <CheckBox style={{ fill: "#579209" }} />
                                                            {JSON.parse(authconst).Checkboxes.filter(x => x.id == 14)[0].Discription}
                                                        </label>
                                                    </Col>
                                                    : <></>}
                                            </Row>
                                            {(parseInt(new Date().getFullYear()) - parseInt(new Date(data.DateOfBirth).getFullYear())) < 18 ?
                                                <>
                                                    <Row>
                                                        <Col>

                                                        </Col>
                                                        <Col>

                                                        </Col>
                                                    </Row>
                                                    <Row>

                                                        {data?.MinorAccount !== "None" && data?.MinorAccount !== null ?
                                                            <Col>
                                                                <label className="" style={{ marginBottom: 10, marginTop: 20 }}>
                                                                    <CheckBox style={{ fill: "#579209" }} />
                                                                    {data.MinorAccount}
                                                                </label>
                                                            </Col>
                                                            : <></>}
                                                    </Row>
                                                </>
                                                : <></>
                                            }
                                            {/* <Row>

                                                {data?.SignatureCheck1 !== "None" && data?.SignatureCheck1 !== null ?
                                                    <Col>
                                                        <label className="" style={{ marginBottom: 10, marginTop: 20 }}>
                                                            <CheckBox style={{ fill: "#579209" }} />
                                                            {data.SignatureCheck1}
                                                        </label>
                                                    </Col>
                                                    : <></>}
                                            </Row>
                                            <Row>
                                                {data?.SignatureCheck2 !== "None" && data?.SignatureCheck2 !== null ?
                                                    <Col>
                                                        <label className="" style={{ marginBottom: 10, marginTop: 20 }}>
                                                            <CheckBox style={{ fill: "#579209" }} />
                                                            {data.SignatureCheck2}
                                                        </label>
                                                    </Col>
                                                    : <></>}
                                            </Row>
                                            <Row>
                                                {data?.SignatureCheck3 !== "None" && data?.SignatureCheck3 !== null ?
                                                    <Col>
                                                        <label className="" style={{ marginBottom: 10, marginTop: 20 }}>
                                                            <CheckBox style={{ fill: "#579209" }} />
                                                            {data.SignatureCheck3}
                                                        </label>
                                                    </Col>
                                                    : <></>}
                                            </Row>
                                            <Row>
                                                {data?.SignatureCheck4 !== "None" && data?.SignatureCheck4 !== null ?
                                                    <Col>
                                                        <label className="" style={{ marginBottom: 10, marginTop: 20 }}>
                                                            <CheckBox style={{ fill: "#579209" }} />
                                                            {data.SignatureCheck4}
                                                        </label>
                                                    </Col>
                                                    : <></>}
                                            </Row> */}
                                        </>
                                    }

                                    <Row>
                                        <h3 style={{ marginBottom: 20, marginTop: 20 }}>Documents</h3>
                                        <Col>
                                            {data.IncomeReceiptPath !== null ? <>
                                                {
                                                    JSON.parse(data.IncomeReceiptPath).map((p, i) => (
                                                        <a href={Config.LOCALADDRESS + "/" + p} target={"_blank"}>Click to see IncomeReceiptPath attach file {i + 1}<br /></a>
                                                    ))
                                                }
                                            </> : ""}
                                            {data.professionReceiptPath !== null ? <>
                                                {
                                                    JSON.parse(data.professionReceiptPath).map((p, i) => (
                                                        <a href={Config.LOCALADDRESS + "/" + p} target={"_blank"}>Click to see proof of profession attach file {i + 1}<br /></a>
                                                    ))
                                                }
                                            </> : ""}
                                            {(parseInt(new Date().getFullYear()) - parseInt(new Date(data.DateOfBirth).getFullYear())) < 18 ?
                                                <>
                                                    {data.CRCPath !== null ? <> <a href={Config.LOCALADDRESS + "/" + data.CRCPath} target={"_blank"}>Click to see Juvenile Card / Form-B/ Child Registration Certificate (CRC)/ Family Registration Certificate (FRC)</a> <br /></> : ""}
                                                    {data.COAPath !== null ? <> <a href={Config.LOCALADDRESS + "/" + data.COAPath} target={"_blank"}>Click to see Court Order Attachment</a> <br /></> : ""}
                                                </> : <></>}
                                            {data.FPfilepath !== null ? <> <a href={Config.LOCALADDRESS + "/" + data.FPfilepath} target={"_blank"}>Click to see file document file</a> <br /></> : ""}
                                            {data.ECRPDoc !== null ? <> <a href={Config.LOCALADDRESS + "/" + data.ECRPDoc} target={"_blank"}>Click to see eCRP document file</a> <br /></> : ""}
                                            {data.EKYCDoc !== null ? <> <a href={Config.LOCALADDRESS + "/" + data.EKYCDoc} target={"_blank"}>Click to see eKYC document file</a> <br /></> : ""}
                                            {data.SignaturePath !== null ? <> <a href={Config.LOCALADDRESS + "/" + data.SignaturePath} target={"_blank"}>Click to see Signature attach file</a> <br /></> : ""}
                                            {data.CZ50Path !== null ? <> <a href={Config.LOCALADDRESS + "/" + data.CZ50Path} target={"_blank"}>Click to see CZ50Path attach file</a> <br /></> : ""}

                                            {data.FATCADeclarationsPath !== null ? <> <a href={Config.LOCALADDRESS + "/" + data.FATCADeclarationsPath} target={"_blank"}>Click to see FATCADeclarationsPath attach file</a> <br /></> : ""}

                                            {data.BeneficialIdDocPath !== null ? <> <a href={Config.LOCALADDRESS + "/" + data.BeneficialIdDocPath} target={"_blank"}>Click to see BeneficialIdDocPath attach file</a> <br /></> : ""}

                                            {data.BeneficialIncomeDocPath !== null ? <> <a href={Config.LOCALADDRESS + "/" + data.BeneficialIncomeDocPath} target={"_blank"}>Click to see BeneficialIncomeDocPath attach file</a> <br /></> : ""}

                                            {data.CNICFrontPath !== null ? <> <a href={Config.LOCALADDRESS + "/" + data.CNICFrontPath} target={"_blank"}>Click to see CNICFrontPath attach file</a> <br /></> : ""}

                                            {data.CNICBackPath !== null ? <> <a href={Config.LOCALADDRESS + "/" + data.CNICBackPath} target={"_blank"}>Click to see CNICBackPath attach file</a> <br /></> : ""}
                                            {data.CNICBackReceipt !== null && data.CNICBackReceipt !== "" ? <> <a href={Config.LOCALADDRESS + "/" + data.CNICBackReceipt} target={"_blank"}>Click to see CNIC Receipt attach file</a> <br /></> : ""}
                                        </Col>
                                    </Row>
                                </CardBody>
                                <CardFooter>
                                    <button onClick={() => {
                                        history.goBack();
                                    }} className="btn" style={{ backgroundColor: "var(--islamic)", color: "white" }}>Back</button>

                                    {JSON.parse(authconst).role !== "5" ? (
                                        <>
                                            {
                                                data.ApplicationStatusList ?
                                                    <>
                                                        <select className="btn" style={{ backgroundColor: "var(--faysal)", color: "white" }} onChange={(txt) => {
                                                            RejectHandler(txt.target.value);
                                                            //setstatuid(txt.target.value);
                                                        }}>
                                                            <option value={""}>Select</option>
                                                            {data.ApplicationStatusList?.map(o => (
                                                                <option value={o.id}>{o.Name.replace("_", " ")}</option>
                                                            ))}
                                                        </select>
                                                    </> : <></>
                                            }
                                            {data.ApplicationStatus !== ApplicationStatuses.applicationDraft ?
                                                <>
                                                    <button onClick={() => {
                                                        setMeetingmodel(true);
                                                    }} className="btn" style={{ marginLeft: 30, backgroundColor: "var(--islamic)", color: "white" }}>Schedule Meeting</button>
                                                    <button onClick={() => {
                                                        setDocmodel(true);
                                                    }} className="btn" style={{ marginLeft: 30, backgroundColor: "var(--islamic)", color: "white" }}>Update/Add Document</button>
                                                    <button onClick={() => {
                                                        setMeetingvar(true);
                                                    }} className="btn" style={{ marginLeft: 30, backgroundColor: "var(--islamic)", color: "white" }}>upload Meeting</button>

                                                    <button onClick={() => {
                                                        setLoading(true);
                                                        //var header = document.getElementById('fileheader').innerHTML;
                                                        fetch("api/dashboard/DigitalAcountDashboard/ValidateUserInfo",
                                                            {
                                                                method: 'POST',
                                                                headers: {
                                                                    'Content-Type': 'application/json',
                                                                },
                                                                body: JSON.stringify({
                                                                    "CNIC": data.CNIC,
                                                                    "Id": parmsdata.Id,
                                                                    "Index": parmsdata.Index
                                                                }),
                                                            }).then(response => response.json())
                                                            .then(json => {
                                                                debugger;
                                                                if (json.StatusCode === 0) {
                                                                    window.location.reload();
                                                                } else {
                                                                    alert(json.StatusMessage);
                                                                }
                                                                setLoading(false);
                                                            })
                                                            .catch(error => {
                                                                alert(error);
                                                                setLoading(false);
                                                            })
                                                    }} className="btn" style={{ marginLeft: 30, backgroundColor: "var(--islamic)", color: "white" }}>Nadra Verification</button>

<button onClick={() => {
                                                        setLoading(true);
                                                        //var header = document.getElementById('fileheader').innerHTML;
                                                        fetch("api/dashboard/DigitalAcountDashboard/ValidatePMD",
                                                            {
                                                                method: 'POST',
                                                                headers: {
                                                                    'Content-Type': 'application/json',
                                                                },
                                                                body: JSON.stringify({
                                                                    "CNIC": data.CNIC,
                                                                    "Id": parmsdata.Id,
                                                                    "Index": parmsdata.Index
                                                                }),
                                                            }).then(response => response.json())
                                                            .then(json => {
                                                                debugger;
                                                                if (json.StatusCode === 0) {
                                                                    alert("Success");
                                                                    window.location.reload();
                                                                } else {
                                                                    alert(json.StatusMessage);
                                                                    window.location.reload();
                                                                }
                                                                setLoading(false);
                                                            })
                                                            .catch(error => {
                                                                alert(error);
                                                                setLoading(false);
                                                            })
                                                    }} className="btn" style={{ marginLeft: 30, backgroundColor: "var(--islamic)", color: "white" }}>PMD Verification</button>

                                                </>
                                                : <></>}
                                            {data.ApplicationStatus === ApplicationStatuses.applicationDiscrepant ?
                                                <button onClick={() => {
                                                    var dt = JSON.parse(data.ApplicationStatusTimeStamps).filter(p => p.TransactionStatus === ApplicationStatusesEnum.Application_Discrepant)[0];
                                                    setnotes(dt?.Notes);
                                                    setRemarks(dt?.Remarks);
                                                    setnotesmodeldtl(true)
                                                }} className="btn" style={{ marginLeft: 30, backgroundColor: "var(--faysal)", color: "white" }}>Discrepant Details</button>
                                                : <></>}

                                        </>) : <></>}
                                </CardFooter>
                            </Card>
                        </Col>
                    </Row>
                    <Row style={{ marginBottom: 10 }}>
                        <Col md={12}>
                            <h3 className="page-title">Application Logs</h3>
                        </Col>
                    </Row>
                    <Row style={{ marginBottom: 10 }}>
                        <Col>
                            <Card>
                                <CardBody>
                                    <table className='table table-striped'>
                                        <thead>
                                            <tr>
                                                <th>DateTime</th>
                                                <th>Status</th>
                                                <th>User</th>
                                                <th>Internal Notes</th>
                                                <th>Other Details</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {data.ApplicationStatusTimeStamps !== "" ? JSON.parse(data.ApplicationStatusTimeStamps).map(p => (
                                                <tr>
                                                    <td>{(new Date(p?.DateTime).toDateString()).replace("Invalid Date", "") + " " + (new Date(p?.DateTime).toLocaleTimeString()).replace("Invalid Date", "")}</td>
                                                    <td>{ApplicationStatusList2.filter(o => o.id === p.TransactionStatus)[0]?.name}</td>
                                                    <td>{p?.UserEmail}</td>
                                                    <td>{p.Notes}</td>
                                                    <td>{p.TransactionStatus === ApplicationStatusesEnum.Application_Discrepant ?
                                                        p.Remarks
                                                        : p.TransactionStatus === ApplicationStatusesEnum.Application_Successful ?
                                                            <>
                                                                <p>IBAN Number : {p.successfulApplication.IBANNumber}</p>
                                                                <p>Account Title : {p.successfulApplication.AccountTitle}</p>
                                                                <p>Account Opening Date : {(new Date(p.successfulApplication.AccountOpeningDate).toDateString())}</p>
                                                                <p>Branch Name : {p.successfulApplication.BranchName}</p>
                                                                <p>SWIFT Code : {p.successfulApplication.SWIFTCode}</p>UserEmail
                                                            </> :
                                                            ""
                                                    }</td>
                                                </tr>

                                            )) : <></>}
                                        </tbody>
                                    </table>


                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                    <h2>Meetings</h2>
                    <Row style={{ marginBottom: 10 }}>
                        <Col>
                            <Card>
                                <CardBody>
                                    <table className='table table-striped'>
                                        <thead>
                                            <tr>
                                                <th>DateTime</th>
                                                <th>Upload by</th>
                                                <th>File</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {data?.MeetingList !== "" && data?.MeetingList !== null ? JSON.parse(data.MeetingList).map(p => (
                                                <tr>
                                                    <td>{(new Date(p?.Date).toDateString()).replace("Invalid Date", "") + " " + (new Date(p?.Date).toLocaleTimeString()).replace("Invalid Date", "")}</td>
                                                    <td>{p.Uploadby}</td>
                                                    <td>{<a href={Config.LOCALADDRESS + "/api/dashboard/DigitalAcountDashboard/Download?filePath=" + p?.MeetingPath} target={"_blank"} >Click here to see</a>}</td>
                                                </tr>

                                            )) : <></>}
                                        </tbody>
                                    </table>


                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </div>
                : <>
                    <div style={{ textAlign: "center" }}>
                        {/* <h3 style={{alignSelf:"center",margin:"auto"}}>Please Wait..</h3> */}
                        <div className={`load${true ? '' : ' loaded'}`}>

                            <div className="load__icon-wrap">
                                <svg className="load__icon">
                                    <path fill="#2c2d6e" d="M12,4V2A10,10 0 0,0 2,12H4A8,8 0 0,1 12,4Z" />
                                </svg>
                            </div>
                        </div>
                    </div>
                </>}
            {
                meetingmodel == true ?
                    <SeheduleMeeting model={true} setModal={setMeetingmodel} id={parmsdata.Id} index={parmsdata.Index} />
                    : null
            }

            {
                Docmodel == true ?
                    <AddDoc model={true} setModal={setDocmodel} id={parmsdata.Id} index={parmsdata.Index} />
                    : null
            }
            {
                Meetingvar == true ?
                    <Meeting model={true} setModal={setMeetingvar} id={parmsdata.Id} index={parmsdata.Index} />
                    : null
            }
            {
                notesmodel == true ?
                    <AddNotes model={true} setModal={setNotesmodel} id={parmsdata.Id} index={parmsdata.Index} statusid={parseInt(statuid)} />
                    : null
            }
            {
                notesmodeldtl == true ?
                    <Details model={true} setModal={setnotesmodeldtl} remarks={remarks} notes={notes} />
                    : null
            }
        </div >
    )
}

export default UserList;