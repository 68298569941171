import axios from 'axios';
import React, { useEffect, useState, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { Alert, Card, CardBody, CardFooter, CardHeader, Col, Container, Pagination, PaginationItem, PaginationLink, Row, Table } from 'reactstrap';
import SeheduleMeeting from './ScheduleMeeting';
import { FunctionsContext } from '../../Context/functions.context'
import { postRequest } from '../service';
import { CheckBox, Fullscreen, RadioButtonChecked, RadioButtonUnchecked } from '@material-ui/icons';
import AddUser from './Adduser';
import { Auth } from '../../Context/local.context';
import { ButtonList } from '../Enums';

const UserList = () => {
    const history = useHistory()
    const [usrList, setusrList] = useState([]);
    const [filterlist, setfilterlist] = useState([]);
    const [meetingmodel, setMeetingmodel] = useState(false);
    const [AddUsermodel, setAddUsermodel] = useState(false)
    const [selid, setSelid] = useState("");

    const [isLoaded, setisLoaded] = useState(false);
    const [Roles, setRoles] = useState([]);
    const { AuthData } = useContext(Auth)
    //const [Buttons, setButtons] = useState([]);
    useEffect(() => {
        //setButtons([...AuthData.Buttons]);
        list();
    }, [])
    async function list() {
        setisLoaded(false);
        var data = await postRequest("api/dashboard/Auth/UserList", null);
        console.log(data);
        if (data.StatusCode === 0) {
            var t = [];
            var s = [];
            data.ResponseObject.RoleList.forEach(element => {
                s.push({
                    ...element,
                })
            });
            data.ResponseObject.UserList.forEach(element => {
                t.push({
                    ...element,
                })
            });
            setRoles(s);
            setusrList(t);
            setfilterlist(t);
        }
        setisLoaded(true);
    }
    const searchfilter = (txt) => {
        if (txt !== "") {
            var t = usrList.filter(p => p.First.includes(txt) || p.last.includes(txt) || p.Email.includes(txt)
            );
            setfilterlist(t);
        } else {
            var t = usrList;
            setfilterlist(t);
        }
    }




    const EnableUser = async (Email) => {
        setisLoaded(false)
        var senddata = { "Email": Email };
        var data = await postRequest("api/dashboard/Auth/ActivateUser", senddata);
        if (data.StatusCode === 0) {
            alert("User Enabled.")
            list();
        } else {
            alert(data.StatusMessage);
        }
        setisLoaded(true)
    }
    const DisabledUser = async (Email) => {
        setisLoaded(false)
        var senddata = { "Email": Email };
        var data = await postRequest("api/dashboard/Auth/DeActivateUser", senddata);
        if (data.StatusCode === 0) {
            alert("User Disabled.")
            list();
        } else {
            alert(data.StatusMessage);
        }
        setisLoaded(true)
    }


    return (
        !isLoaded ? (
            <div style={{ textAlign: "center" }}>
                {/* <h3 style={{alignSelf:"center",margin:"auto"}}>Please Wait..</h3> */}
                <div className={`load${true ? '' : ' loaded'}`}>

                    <div className="load__icon-wrap">
                        <svg className="load__icon">
                            <path fill="#2c2d6e" d="M12,4V2A10,10 0 0,0 2,12H4A8,8 0 0,1 12,4Z" />
                        </svg>
                    </div>
                </div>
            </div>
        ) :
            <div style={{}}>
                <div>
                    <Row>
                        <Col>
                            <Card style={{ marginRight: 15 }}>
                                <CardBody>
                                    <Row>
                                        <Col md={10} style={{ marginTop: 20 }}>
                                            <h3 className="page-title">User List</h3>
                                        </Col>
                                        <Col md={2} style={{ marginTop: 20 }}>
                                            {AuthData.Buttons.findIndex(p => p === ButtonList.AddUser) !== -1 ?
                                                <button className='btn ' style={{ marginLeft: 30, backgroundColor: "var(--faysal)", color: "white" }} onClick={() => { setAddUsermodel(true) }}>Add User</button>
                                                : <></>}
                                        </Col>
                                    </Row>
                                    <Row>
                                        {/* <Col>
                                            <a className="btn" style={{ marginLeft: 10, padding: "10px 20px", marginBottom: 0, overflow: "none !important", backgroundColor: "var(--islamic)", color: "white" }}
                                                href='api/dashboard/DigitalAcountDashboard/DownloadBranchReferral'
                                            >Dwonload Excel</a>
                                        </Col> */}
                                    </Row>
                                    {/* <Row>
                                        <Col md={6}>
                                            <div className="form__form-group" style={{ marginTop: 0 }}>
                                                <span className="form__form-group-label" style={{ fontSize: 15, fontWeight: 400 }}>Search</span>
                                                <div className="form__form-group-field">
                                                    <input
                                                        disabled={!loaded}
                                                        style={{ height: 35, fontSize: 14, width: "100%", outlineWidth: 0, marginTop: 5 }}
                                                        name="name"
                                                        type="text"
                                                        autoComplete="off"
                                                        placeholder=""
                                                        required
                                                        value={search}
                                                        onChange={(txt) => { setsearch(txt.target.value); searchfilter(txt.target.value); }}
                                                    />
                                                </div>
                                            </div>
                                        </Col>

                                    </Row> */}

                                    <Table responsive hover bordered>
                                        <thead>
                                            <tr>
                                                <th>#</th>
                                                <th>E-mail</th>
                                                <th>Full Name</th>
                                                <th>Roles</th>
                                                <th>Is Active</th>
                                                <th>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {filterlist.map((p, i) => (
                                                <tr key={p.Id}>
                                                    <th scope="row"><p>{(i + 1)}</p></th>
                                                    <td><p>{p.Email}</p></td>
                                                    <td><p>{p.FullName}</p></td>
                                                    <th><p>{p.Roles.map(i => (
                                                        <p>{Roles.filter(r => r.Id === i)[0].Name}</p>
                                                    ))}</p></th>
                                                    <td><p>
                                                        {p.ActiveStatus ?
                                                            <RadioButtonChecked />
                                                            :
                                                            <RadioButtonUnchecked />
                                                            // <input type="checkbox" disabled checked={p.ActiveStatus} />
                                                        }
                                                    </p></td>

                                                    <td>
                                                        {AuthData.Buttons.findIndex(p => p === ButtonList.AddUserRole || p === ButtonList.RemoveUserRole) !== -1 ?
                                                            <button onClick={() => {
                                                                history.push({
                                                                    pathname: "/admin/dashboard/roles",
                                                                    state: { ...p, RoleList: Roles }
                                                                })
                                                            }} className="btn" style={{ marginLeft: 30, backgroundColor: "var(--faysal)", color: "white" }}>View Role</button>
                                                            : <></>}
                                                        {AuthData.Buttons.findIndex(p => p === ButtonList.UpdateUserStatus) !== -1 ?
                                                            <>
                                                                {p.ActiveStatus ?
                                                                    <a onClick={() => DisabledUser(p.Email)} className="btn" style={{ marginLeft: 30, backgroundColor: "var(--islamic)", color: "white" }}>Disable</a>
                                                                    :
                                                                    <a onClick={() => EnableUser(p.Email)} className="btn" style={{ marginLeft: 30, backgroundColor: "var(--faysal)", color: "white" }}>Enable</a>
                                                                }
                                                            </>
                                                            : <></>}
                                                    </td>
                                                </tr>

                                            ))}
                                        </tbody>
                                    </Table>
                                    {/* <Pagination aria-label="Page navigation example">
                                        <PaginationItem>
                                            <PaginationLink href="#">
                                                1
                                            </PaginationLink>
                                        </PaginationItem>
                                    </Pagination> */}
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>

                </div>
                {
                    meetingmodel == true ?
                        <SeheduleMeeting model={true} setModal={setMeetingmodel} userid={selid} />
                        : null
                }

                {
                    AddUsermodel == true ?
                        <AddUser model={true} setModal={setAddUsermodel} userid={selid} roles={Roles} refreshlist={list} />
                        : null
                }
            </div >
    )
}

export default UserList;