import React, { Component } from 'react';
import { Redirect, Route } from 'react-router';
import { Layout } from './Layout';
import { Home } from './Home';
import FirstScreen from './Questions/FirstScreen';

import AccountType from './Questions/Accounttype';
import AccountOptions from './Questions/AccountOptions';
import AccountOtherdetails from './Questions/AccountOtherdetails';
import Basicdetails from './Questions/Basicdetails';
import OtpVerification from './Questions/OtpEmailVerification';
import IndividualInformation from './Questions/IndividualInformation';
import FinalizeInfo from './Questions/FinalizeInfo';
import FinalizeInfop2 from './Questions/FinalizeInfop2';
import Thankyou from './Questions/Thankyou';
import BeneficialInfo from './Questions/BeneficialInfo';
import ZakatNTrans from './Questions/ZakatNTrans';
import CheckNCard from './Questions/CheckNCard';
import RemitterInfo from './Questions/RemitterInfo';
import Liveness from './Questions/Liveness';
import CNICVerification from './Questions/CNICVerification';
import Download from './Questions/Download';
import TNC from './Questions/TNC';
import Continueotp from './Questions/OtpVerification';

import NotFound from './Questions/404';


// import './custom.css'
import { DataContextProvider } from './Context/Data.Context';
import { StepsContextProvider } from './Context/Steps.Context';
import { ApiDataContextProvider } from './Context/Api.context';
import { AlertDataContextProvider } from './Context/Alert.context';
import { FunctionsContextProvider } from './Context/Functions.context';
import { Config } from './congig';
export default class ClientSide extends Component {

  componentDidMount() {
    window.onclose = () => {
      window.localStorage.clear();
    }

  }
  componentWillUnmount() {
    //fetch("api/DigitalAccount/CheckAuth?sc=" + window.location.pathname);
  }

  componentWillMount() {
    if (Config.ADMIN === false) {
      require('./custom.css');
    }
  }

  render() {
    return (
      <>
        <ApiDataContextProvider>
          <DataContextProvider>
            <StepsContextProvider>
              <AlertDataContextProvider>
                <FunctionsContextProvider>
                  <Layout>
                    {/* <Route exact path='*' component={NotFound} /> */}
                    <Route exact path='/' component={FirstScreen} />
                    <Route exact path='/accounttype' component={AccountType} />
                    <Route exact path='/accountoption' component={AccountOptions} />
                    <Route exact path='/otherdetails' component={AccountOtherdetails} />
                    <Route exact path='/basicdetails' component={Basicdetails} />
                    <Route exact path='/otp' component={OtpVerification} />
                    <Route exact path='/individual' component={IndividualInformation} />
                    <Route exact path='/otherinfo' component={FinalizeInfo} />
                    <Route exact path='/finalize' component={FinalizeInfop2} />
                    <Route exact path='/beneficial' component={BeneficialInfo} />
                    <Route exact path='/znt' component={ZakatNTrans} />
                    <Route exact path='/cnc' component={CheckNCard} />
                    <Route exact path='/remitter' component={RemitterInfo} />
                    <Route exact path='/liveness' component={Liveness} />
                    <Route exact path='/cnicverification' component={CNICVerification} />
                    <Route exact path='/downloads' component={Download} />
                    <Route exact path='/tnc' component={TNC} />
                    <Route exact path='/continueotp' component={Continueotp} />

                    <Route exact path='/thankyou' component={Thankyou} />

                  </Layout>
                </FunctionsContextProvider>
              </AlertDataContextProvider>
            </StepsContextProvider>
          </DataContextProvider>
        </ApiDataContextProvider>
      </>
    );
  }
}

