import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Alert, Button, Card, CardBody, CardHeader, Col, Container, Row, Table } from 'reactstrap';
import SeheduleMeeting from './ScheduleMeeting';
import { postRequest } from '../service'
import Pagination from '@material-ui/lab/Pagination';
import { ApplicationStatuses, ApplicationStatusesEnum, ApplicationStatusList } from '../Enums';
import Details from './Descrepantdtl';
const NadraReportlist = () => {
    const history = useHistory()
    const [loaded, setLoaded] = useState(true);
    const [ViewDtl, setViewDtl] = useState(false);

    const [search, setsearch] = useState("")
    const [TotalCount, setTotalCount] = useState(0);
    const [startdata, setstartdata] = useState("")
    const [enddata, setenddata] = useState("")
    const [Data, setData] = useState("")
    async function filterRecords(txt) {
        setLoaded(false);
        setData("");
        var data = await postRequest("api/dashboard/DigitalAcountDashboard/NadraReport", {
            "StartDate": startdata !== "" ? startdata : null,
            "EndDate": enddata !== "" ? enddata : null,
        });
        setData(data);
        console.log(data)
        setLoaded(true);
    }


    return (
        <div style={{}}>
            <div>
                <Row>
                    <Col md={12}>
                        <h3 className="page-title">Nadra Reports</h3>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Card style={{ marginRight: 15 }}>
                            <CardBody>
                                <Row>
                                    <Col>
                                        <div className="form__form-group" style={{ marginTop: 0 }}>
                                            <span className="form__form-group-label" style={{ fontSize: 15, fontWeight: 400 }}>Start Date</span>
                                            <div className="form__form-group-field">
                                                <input type={"date"} onChange={(txt) => { setstartdata(txt.target.value) }} className="form-control" />
                                            </div>
                                        </div>
                                    </Col>
                                    <Col>
                                        <div className="form__form-group" style={{ marginTop: 0 }}>
                                            <span className="form__form-group-label" style={{ fontSize: 15, fontWeight: 400 }}>End Date</span>
                                            <div className="form__form-group-field">
                                                <input type={"date"} onChange={(txt) => { setenddata(txt.target.value) }} className="form-control" />
                                            </div>
                                        </div>
                                    </Col>
                                    <Col>
                                        <button className="btn" style={{ marginLeft: 10, padding: "10px 20px", marginBottom: 0, overflow: "none !important", backgroundColor: "var(--faysal)", color: "white" }}
                                            onClick={() => filterRecords(search)}
                                        >Get Report</button>
                                    </Col>
                                </Row>
                                {loaded && Data !== "" ?
                                    <>
                                        <Table responsive hover bordered>
                                            <thead>
                                                <tr>
                                                    <th>Successful Hits</th>
                                                    <th>Failed Hits</th>
                                                    <th>View Details</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <th>{Data.TotalCountSuccess}</th>
                                                    <th>{Data.TotalCountFailed}</th>
                                                    <th><Button className="btn" onClick={() => { setViewDtl(true) }}>View Details</Button></th>
                                                </tr>
                                            </tbody>
                                        </Table>
                                        {ViewDtl ?
                                            <>
                                                <h3 style={{ marginTop: 20 }}>Details</h3>
                                                <Table responsive hover bordered>
                                                    <thead>
                                                        <tr>
                                                            <th>CNIC</th>
                                                            <th>Reference Id</th>
                                                            <th>Requested On</th>                                                            
                                                            <th>Session ID</th>
                                                            <th>Status</th>
                                                            <th>Status Message</th>

                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {Data.NadraReportDtoList !== undefined ?
                                                            Data.NadraReportDtoList.map(p => (
                                                                <tr>
                                                                    <th>{p.CNIC}</th>
                                                                    <th>{p.ReferenceId}</th>
                                                                    <th>{new Date(p.Hiton).toLocaleDateString()}</th>
                                                                    <th>{p.VerifyCitizenNumberResultDtl.VERIFICATION.RESPONSE_DATA.SESSION_ID}</th>
                                                                    <th>{p.VerifyCitizenNumberResultDtl.VERIFICATION.RESPONSE_DATA.RESPONSE_STATUS.CODE}</th>
                                                                    <th>{p.VerifyCitizenNumberResultDtl.VERIFICATION.RESPONSE_DATA.RESPONSE_STATUS.MESSAGE}</th>

                                                                </tr>))
                                                            : <></>}
                                                    </tbody>
                                                </Table></>
                                            : <></>}
                                    </>
                                    :
                                    loaded ? <></> :
                                        <div style={{ textAlign: "center" }}>
                                            {/* <h3 style={{alignSelf:"center",margin:"auto"}}>Please Wait..</h3> */}
                                            <div className={`load${true ? '' : ' loaded'}`}>

                                                <div className="load__icon-wrap">
                                                    <svg className="load__icon">
                                                        <path fill="#2c2d6e" d="M12,4V2A10,10 0 0,0 2,12H4A8,8 0 0,1 12,4Z" />
                                                    </svg>
                                                </div>
                                            </div>
                                        </div>
                                }
                            </CardBody>
                        </Card>
                    </Col>
                </Row>

            </div>
        </div>
    )
}

export default NadraReportlist;