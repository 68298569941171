import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Alert, Card, CardBody, CardHeader, Col, Container, Row, Table } from 'reactstrap';
import SeheduleMeeting from './ScheduleMeeting';
import { postRequest } from '../service'
import Pagination from '@material-ui/lab/Pagination';
import { ApplicationStatuses, ApplicationStatusesEnum, ApplicationStatusList } from '../Enums';
import Details from './Descrepantdtl';
const UserList = () => {
    const history = useHistory()
    const [usrList, setusrList] = useState([]);
    const [filterlist, setfilterlist] = useState([]);
    const [loaded, setLoaded] = useState(false);
    const [search, setsearch] = useState("")
    const [accountstatus, setAccountstatus] = useState("All");
    const [meetingmodel, setMeetingmodel] = useState(false);
    const [selid, setSelid] = useState("");
    const [numberofrows, setnumberofrows] = useState(25);
    const status = ["Approve", "Pending", "Rejected"];
    const [TotalCount, setTotalCount] = useState(0);
    const [Paginationarray, setPaginationarray] = useState([])
    const [currentPage, setCurrentPage] = useState(1)
    const [startdata, setstartdata] = useState("")
    const [enddata, setenddata] = useState("")
    const [appstatus, setappstatus] = useState(0);
    const [filter, setFilter] = useState(false)


    // useEffect(() => {
    //     list("");
    // }, [])

    useEffect(() => {
        list("");
    }, [numberofrows, currentPage])

    async function list(txt) {
        setLoaded(false);
        var data = await postRequest("api/dashboard/DigitalAcountDashboard/CustomerList", {
            "PageNo": currentPage === 0 ? 1 : currentPage,
            "PageSize": numberofrows === 0 ? 25 : numberofrows,
            "SearchKeyword": txt ? txt : ""
        });
        debugger
        var t = [];
        data.AccountVerificationBasicDetails.forEach(element => {
            t.push({
                ...element,
            })
        });
        if (txt !== "") {
            setCurrentPage(1);
        }
        setTotalCount(Math.ceil(data.TotalCount / numberofrows));
        setusrList(t);
        setfilterlist(t);
        setLoaded(true);
    }
    async function filterRecords(txt) {
        setLoaded(false);
        
        var data = await postRequest("api/dashboard/DigitalAcountDashboard/CustomerList", {
            "PageNo": currentPage === 0 ? 1 : currentPage,
            "PageSize": numberofrows === 0 ? 25 : numberofrows,
            "SearchKeyword": txt ? txt : "",
            "StartDate": startdata !== "" ? startdata : null,
            "EndDate": enddata !== "" ? enddata : null,
            "ApplicationStatus": appstatus !== "" && appstatus !== "All" ? appstatus : null
        });
        debugger
        var t = [];
        data.AccountVerificationBasicDetails.forEach(element => {
            t.push({
                ...element,
            })
        });
        if (txt !== "") {
            setCurrentPage(1);
        }
        setTotalCount(Math.round(data.TotalCount / numberofrows));
        setusrList(t);
        setfilterlist(t);
        setLoaded(true);
    }


    useEffect(() => {
        paginationitems();
    }, [TotalCount])

    const searchfilter = (txt) => {
        if (txt !== "") {
            var t = usrList.filter(p => p.CNIC.includes(txt) || p.CustomerName.includes(txt)
                && (accountstatus == "All" ? true : p.accountStatus == accountstatus)
            );
            setfilterlist(t);
        } else {
            var t = usrList.filter(p => (accountstatus == "All" ? true : p.accountStatus == accountstatus));
            setfilterlist(t);
        }
    }
    const accountStatusfilter = (txt) => {
        if (txt !== "All") {
            var t = usrList.filter(p => (search == "" ? true : p.CNIC.includes(search) || p.PhoneNumber.includes(search) || p.Email.includes(search))
                && (p.accountStatus == txt)
            );
            setfilterlist(t);
        } else {
            var t = usrList.filter(p => search == "" ? true : (p.CNIC.includes(search) || p.PhoneNumber.includes(search) || p.Email.includes(search)));
            setfilterlist(t);
        }
    }
    const downloadExcel = (txt) => {
        var parms = "";
        if (txt !== "") {
            parms += `SearchKeyword=${(txt ? txt : "")}`;
        }
        if (startdata !== "") {
            parms += `&StartDate=${startdata}`;
        }
        if (enddata !== "") {
            parms += `&EndDate=${enddata}`;
        }
        if (appstatus !== "" && appstatus !== "All" && appstatus !== 0) {
            parms += `&ApplicationStatus=${appstatus}`;
        }
        var t = `api/dashboard/DigitalAcountDashboard/DownloadExcel?${parms}`;
        window.location.href = t;
    }
    const paginationitems = () => {
        // var s = [];
        // for (let index = 0; index < TotalCount; index++) {
        //     s.push(
        //         <PaginationItem >
        //             <PaginationLink onClick={() => {
        //                 if (currentPage !== (index + 1)) {
        //                     setCurrentPage(previousindex => index + 1);
        //                 }
        //                 //(currentPage === (index + 1)) ? null :

        //             }}>
        //                 {index + 1}
        //             </PaginationLink>
        //         </PaginationItem>
        //     )

        // }
        // setPaginationarray([...s]);
    }


    return (
        <div style={{}}>
            <div>
                <Row>
                    <Col md={12}>
                        <h3 className="page-title">Customer Accounts</h3>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Card style={{ marginRight: 15 }}>
                            <CardBody>
                                <Row>
                                    <Col md={6}>
                                        <div className="form__form-group" style={{ marginTop: 0 }}>
                                            <span className="form__form-group-label" style={{ fontSize: 15, fontWeight: 400 }}>Search</span>
                                            <div className="form__form-group-field">
                                                <input
                                                    disabled={!loaded}
                                                    style={{ height: 35, fontSize: 14, width: "100%", outlineWidth: 0, marginTop: 5 }}
                                                    name="name"
                                                    type="text"
                                                    autoComplete="off"
                                                    placeholder=""
                                                    required
                                                    value={search}
                                                    onChange={(txt) => { setsearch(txt.target.value); }}
                                                />
                                                {/* <button className="btn" style={{ marginLeft: 10, padding: "10px 20px", width: 110, marginBottom: 0, overflow: "none !important", backgroundColor: "var(--islamic)", color: "white" }}
                                                    onClick={() => {
                                                        setCurrentPage(1);
                                                        list(search);
                                                    }}
                                                >Search</button> */}
                                            </div>
                                        </div>
                                    </Col>
                                    <Col>
                                        <div className="form__form-group" style={{ marginTop: 0 }}>
                                            <span className="form__form-group-label" style={{ fontSize: 15, fontWeight: 400 }}>Account Status</span>
                                            <div className="form__form-group-field">
                                                <select className="form-control" onChange={(txt) => { setappstatus(txt.target.value) }}>
                                                    <option value={"All"}>All</option>
                                                    {ApplicationStatusList.map(p => (
                                                        <option value={p.id}>{p.name}</option>
                                                    ))}
                                                </select>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <div className="form__form-group" style={{ marginTop: 0 }}>
                                            <span className="form__form-group-label" style={{ fontSize: 15, fontWeight: 400 }}>Start Date</span>
                                            <div className="form__form-group-field">
                                                <input type={"date"} onChange={(txt) => { setstartdata(txt.target.value) }} className="form-control" />
                                            </div>
                                        </div>
                                    </Col>
                                    <Col>
                                        <div className="form__form-group" style={{ marginTop: 0 }}>
                                            <span className="form__form-group-label" style={{ fontSize: 15, fontWeight: 400 }}>End Date</span>
                                            <div className="form__form-group-field">
                                                <input type={"date"} onChange={(txt) => { setenddata(txt.target.value) }} className="form-control" />
                                            </div>
                                        </div>
                                    </Col>
                                    <Col>
                                        <div className="form__form-group" style={{ marginTop: 0 }}>
                                            <span className="form__form-group-label" style={{ fontSize: 15, fontWeight: 400 }}># of Rows</span>
                                            <div className="form__form-group-field">
                                                <select className="form-control" onChange={(txt) => { setnumberofrows(txt.target.value) }}>
                                                    <option selected={25 === numberofrows} value={25}>25</option>
                                                    <option selected={50 === numberofrows} value={50}>50</option>
                                                    <option selected={100 === numberofrows} value={100}>100</option>
                                                    <option selected={200 === numberofrows} value={200}>200</option>
                                                </select>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                                <Row style={{ marginBottom: 20 }}>
                                    <Col>
                                        <button className="btn" style={{ marginLeft: 10, padding: "10px 20px", marginBottom: 0, overflow: "none !important", backgroundColor: "var(--faysal)", color: "white" }}
                                            onClick={() => filterRecords(search)}
                                        >Filter Records</button>
                                        <button className="btn" style={{ marginLeft: 10, padding: "10px 20px", marginBottom: 0, overflow: "none !important", backgroundColor: "var(--islamic)", color: "white" }}
                                            onClick={() => downloadExcel(search)}
                                        >Download Excel</button>
                                    </Col>
                                </Row>
                                {loaded ?
                                    <Table responsive hover bordered>
                                        <thead>
                                            <tr>
                                                <th>#</th>
                                                <th>Customer Name</th>
                                                <th>CNIC</th>
                                                <th>E-mail</th>
                                                <th>Mobile number</th>
                                                <th>Application Status</th>
                                                <th>Submited Date</th>
                                                <th>View Details</th>
                                                {/* <th>Schedule Meeting</th> */}
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {filterlist.map((p, i) => (
                                                <tr key={p.Id}>
                                                    <td scope="row"><p>{(i + 1)}</p></td>
                                                    <td style={{ padding: 10, fontSize: 15 }}><p>{p.CustomerName}</p></td>
                                                    <td style={{ padding: 10, fontSize: 15 }}><p>{p.CNIC}</p></td>
                                                    <td style={{ padding: 10, fontSize: 15 }}><p>{p.Email}</p></td>
                                                    <td style={{ padding: 10, fontSize: 15 }}><p>{p.PhoneNumber}</p></td>
                                                    <th><p>{p.ApplicationStatus}</p></th>
                                                    <td><p>{p.ApplicationStatusTimeStamps !== "" ? (new Date(JSON.parse(p.ApplicationStatusTimeStamps).filter(p => p.TransactionStatus === 2)[0]?.DateTime).toLocaleDateString()).replace("Invalid Date", "") + " " + (new Date(JSON.parse(p.ApplicationStatusTimeStamps).filter(p => p.TransactionStatus === 2)[0]?.DateTime).toLocaleTimeString()).replace("Invalid Date", "") : ""}</p></td>
                                                    <td style={{ padding: 10, fontSize: 15 }}>
                                                        {/* {
                                                            p.ApplicationStatusTimeStamps !== "" &&
                                                                (new Date(new Date(JSON.parse(p.ApplicationStatusTimeStamps).filter(p => p.TransactionStatus === 2)[0]?.DateTime)).setHours(new Date().getDate() + 1) < new Date())
                                                                ? */}
                                                        <button onClick={() => {
                                                            history.push({
                                                                pathname: "/admin/dashboard/usersdetails",
                                                                state: p
                                                            })
                                                        }} className="btn" style={{ marginLeft: 30, backgroundColor: "var(--faysal)", color: "white" }}>View</button>
                                                        {/* : <>
                                                                    <button className="btn" style={{ marginLeft: 30, backgroundColor: "var(--faysal)", color: "white" }}>Pending Scan</button>
                                                                </>
                                                        } */}
                                                    </td>
                                                    {/* <td><button onClick={() => {
                                                        setMeetingmodel(true);
                                                        setSelid(p.Id);
                                                    }} className="btn" style={{ marginLeft: 30, backgroundColor: "var(--islamic)", color: "white" }}>Schedule</button></td> */}
                                                </tr>

                                            ))}
                                        </tbody>
                                    </Table>
                                    :
                                    <div style={{ textAlign: "center" }}>
                                        {/* <h3 style={{alignSelf:"center",margin:"auto"}}>Please Wait..</h3> */}
                                        <div className={`load${true ? '' : ' loaded'}`}>

                                            <div className="load__icon-wrap">
                                                <svg className="load__icon">
                                                    <path fill="#2c2d6e" d="M12,4V2A10,10 0 0,0 2,12H4A8,8 0 0,1 12,4Z" />
                                                </svg>
                                            </div>
                                        </div>
                                    </div>
                                }
                                <div style={{ marginTop: 20, alignContent: "center", width: "100%", justifyContent: "center" }}>

                                    {/* {Paginationarray.length > 0 ?
                                        <Pagination aria-label="Page navigation example" style={{ margin: "auto" }} >
                                            {
                                                Paginationarray.map(o =>
                                                    o
                                                )
                                            }
                                        </Pagination>
                                        : <></>} */}
                                    <Pagination count={TotalCount} hideNextButton hidePrevButton color="primary" onClick={(p) => setCurrentPage(previousindex => parseInt(p.target.innerText))} />



                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>

            </div>
            {meetingmodel == true ?
                <SeheduleMeeting model={true} setModal={setMeetingmodel} userid={selid} />
                : null}

        </div>
    )
}

export default UserList;