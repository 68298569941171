import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import Layout from './Layout/index';
import MainWrapper from './MainWrapper';
import Dashboard from './Dashboard/index';
import Login from './AdminLogin/index';
import Registration from './AdminLogin/registration';
import Logout from './AdminLogin/logout';

import Accounts from './UserList/index';
import UserDetails from './UserList/details';
import Employees from './UserList/Employeelist';
import ListBranchRef from './UserList/ListBranchRef';
import NadraReport from './UserList/NadraReport';

import Roles from './UserList/Roles';

const Pages = () => (
  <Switch>
    <Route exact path="/admin/dashboard/index" component={Dashboard} />
    <Route exact path="/admin/dashboard/users" component={Accounts} />
    <Route exact path="/admin/dashboard/usersdetails" component={UserDetails} />
    <Route exact path="/admin/dashboard/employees" component={Employees} />
    <Route exact path="/admin/dashboard/branchref" component={ListBranchRef} />
    <Route exact path="/admin/dashboard/nadrareport" component={NadraReport} />
    <Route exact path="/admin/dashboard/roles" component={Roles} />
    <Route exact path="/admin/dashboard/logout" component={Logout} />


  </Switch>
);

const wrappedRoutes = () => (
  <div>
    <Layout />
    <div className="container__wrap">
      <div>
      <Route path="/admin/dashboard" component={Pages} />
      </div>
    </div>
  </div>
);

const Router = () => (
  <MainWrapper>
    <main>
      <Switch>      
        <Route exact path="/admin/login" component={Login} />
        <Route exact path="/admin/registration" component={Registration} />

        <Route path="/admin/dashboard" component={wrappedRoutes} />
      </Switch>
    </main>
  </MainWrapper>
);


export default Router;
