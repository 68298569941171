import React, { useContext, useEffect, useState } from "react";
import { Grid } from "@material-ui/core";
import { ArrowRight, ArrowLeft, CheckBox } from "@material-ui/icons";
import Button from "reactstrap/lib/Button";
import Row from "../Row";
import { DataContext } from "../Context/Data.Context";
import Blue from '../../Assets/blue.png'
import { useHistory } from "react-router";
import { StepsContext } from "../Context/Steps.Context";
import Animatedpage from "../animatepage";
import { ApiDataContext } from "../Context/Api.context";
import { screen } from "./screen";

const AccountOtherdetails = () => {
    const { data, setData } = useContext(DataContext);
    const { setActive, setComplete } = useContext(StepsContext);
    const { ApiData, setApiData } = useContext(ApiDataContext)
    const [Layout, setLayout] = useState(false)
    const enable = 0 < screen[sessionStorage.getItem("CurrentScreen")] ? true : false;

    const history = useHistory();
    useEffect(() => {
        setActive("Account Details");
    }, [])
   
    return (
        <Animatedpage submit={() => { history.push("basicdetails") }} pop={() => setLayout(true)} useauth={true}>
            {Layout === true ?
                <Grid item xs={12} id="s1q2">
                    <Row>
                        <fieldset disabled={enable}>
                            <div>
                                <h1 className="Q" style={{ marginTop: 20 }}>Select your account currency</h1>
                                {/* <p className="Qhelper">Please enter your full name as it appears on your CNIC, SNIC or NICOP.</p> */}
                                <select className="inputtxt"
                                    onChange={(txt) => {
                                        setData({ ...data, currency: txt.target.value })
                                    }}
                                    required
                                >
                                    <option value="">Select</option>
                                    {ApiData.currencyList.map(p => (
                                        <>
                                            {data?.option?.Currencies.includes(p.Name) ?
                                                <option value={p.id} selected={parseInt(data.currency) === p.id ? true : false}>{p.Name}</option>
                                                : <></>
                                            }
                                        </>
                                    ))}
                                </select>
                            </div>
                            <div>
                                {/* <h1 className="Q" style={{ marginTop: 20 }}>Select your account type</h1> */}
                                {/* <p className="Qhelper">Please enter your full name as it appears on your CNIC, SNIC or NICOP.</p> */}
                                {/* <select className="inputtxt" disabled={data.selectedAT === "Islamic" && data.option === "Freelance Digital Account" && data.currency !== "PKR"}
                        onChange={(txt) => {
                            setData({ ...data, type: txt.target.value })

                        }}
                    >
                        <option value="Current" selected={data.type==="Current"?true:false}>Current</option>
                        <option value="Saving" selected={data.type==="Saving"?true:false}>Saving</option>
                    </select> */}
                                {/* <div>
                                    {ApiData.accountTypeList.map(p => (
                                        <div style={{ display: "inline-block" }}>
                                            <input type="radio" name="type" 
                                                onClick={(txt) => {
                                                    debugger
                                                    setData({ ...data, type: txt.target.value })

                                                }} value={p.id} id={p.Name} checked={parseInt(data.type) === p.id ? true : false} /><label for={p.Name} className="Q" style={{ marginLeft: 10, marginRight: 10 }}>{p.Name}</label>
                                        </div>
                                    ))}


                                </div> */}

                            </div>
                            <div>
                                <h1 className="Q" style={{ marginTop: 20 }}>Select your purpose of account</h1>
                                {/* <p className="Qhelper">Please enter your full name as it appears on your CNIC, SNIC or NICOP.</p> */}
                                <select className="inputtxt"
                                    onChange={(txt) => setData({ ...data, purpose: txt.target.value })}
                                    required
                                >
                                    <option value="">Select</option>
                                    {ApiData.accountPurposeList.map(p => (
                                        <option value={p.id} selected={parseInt(data.purpose) === p.id ? true : false}>{p.Name}</option>
                                    ))}
                                </select>
                            </div>
                        </fieldset>
                        <Button variant="contained" style={{ margin: 10, background: "var(--secondary)", border: "none", borderRadius: 10, marginTop: 20 }} onClick={() => history.push("accountoption")}>
                            <ArrowLeft style={{ marginLeft: -10 }} />
                            Back
                        </Button>
                        <Button type="submit" variant="contained" style={{ margin: 10, background: "var(--primary)", border: "none", borderRadius: 10, marginTop: 20 }} >Next
                            <ArrowRight style={{ marginRight: -10 }} />
                        </Button>
                    </Row>
                </Grid>
                : <></>}
        </Animatedpage>
    )

}
export default AccountOtherdetails;