import React, { useEffect,useState } from 'react';
import { BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import "../index.css"


const Example = ({ sdata = [] }) => {
  const [data, setData] = useState([])
  useEffect(() => {
    var arr = [];
    sdata.map(p => {
      var s = arr.findIndex(o => o.name === p.ProductType);
      if (s === -1) {
        var d = { name: p.ProductType, Male: 0, Female: 0, Other: 0 };
        d[p.Gender] = p.GenderCount;
        arr.push(d);
      } else {
        var d = arr[s];
        d[p.Gender] = p.GenderCount;
        arr.splice(s, 1, d);
      }
    })
    setData([...arr]);
  }, [sdata])
  return (
    <ResponsiveContainer width="100%" height={350}>
      <BarChart
        width={"100%"}
        height={330}
        data={data}
        margin={{
          top: 5,
          right: 30,
          left: 20,
          bottom: 5,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="name" />
        <YAxis />
        <Tooltip />
        <Legend style={{ marginTop: 5 }} />
        <Bar dataKey="Male" fill="#8884d8" />
        <Bar dataKey="Female" fill="#82ca9d" />
        <Bar dataKey="Others" fill="#0dcaf0" />
      </BarChart>
    </ResponsiveContainer>
  );
}
export default Example;
