import React, { useContext, useEffect, useState } from "react";
import { Grid } from "@material-ui/core";
import { ArrowRight, ArrowLeft, CheckBox } from "@material-ui/icons";
import Button from "reactstrap/lib/Button";
import Row from "../Row";
import { DataContext } from "../Context/Data.Context";
import Blue from '../../Assets/blue.png'
import Thumbsup from '../../Assets/thumbsup.png'

import { useHistory, useLocation } from "react-router";
import { StepsContext } from "../Context/Steps.Context";
import Recaptcha from 'react-recaptcha'
import Animatedpage from "../animatepage";
import Confetti from "../customComponents/Confetti";

const Thankyou = ({ route }) => {
    const history = useHistory();
    const { setActive, setComplete } = useContext(StepsContext);
    const { data } = useContext(DataContext);
    const d = new Date();
    const location = useLocation();
    const [show, setShow] = useState(true)
    useEffect(() => {
        setActive("Form Submit");
        localStorage.removeItem("dataCon")
        localStorage.removeItem("ExpiryTime")
        setTimeout(() => {
            setShow(false)
        }, 1000 * 20);
    }, [])
    return (
        <Animatedpage>
            <Grid item xs={12} id="s1q2" style={{ marginTop: "5vh", backgroundColor: "white" }}>
                <Row>
                    <div style={{alignContent:"center",textAlign:"center"}}>
                        <img src={Thumbsup} className="thumpups" style={{ height: 100, width: 100,marginBottom:15 }} />
                        <h1 className="Q">Dear Customer, your Account opening request has been
                            successfully received by BankIslami. Your Account will be
                            activated within two working days after complete verification of
                            your provided information Insha’Allah.</h1>
                        {/* <p className="Qhelper">Your account reference number is {location.state.Ref}.</p> */}
                    </div>

                    {show ?
                        <Confetti />
                        : <></>}
                </Row>
            </Grid>
        </Animatedpage>
    )

}
export default Thankyou;