import React, { useContext, useEffect } from "react";
import { Grid } from "@material-ui/core";
import { ArrowRight, ArrowLeft, CheckBox } from "@material-ui/icons";
import Button from "reactstrap/lib/Button";
import Row from "../Row";
import { DataContext } from "../Context/Data.Context";
import Blue from '../../Assets/blue.png'
import { useHistory } from "react-router";
import { StepsContext } from "../Context/Steps.Context";
import Animatedpage from "../animatepage";
import { AlertDataContext } from "../Context/Alert.context";

import { ApiDataContext } from "../Context/Api.context";
import { FunctionsContext } from "../Context/Functions.context";
import { screen } from "./screen";
import { CheckAlpha, CheckAplhaChara, CheckNumeric } from "../service";

const FinalizeInfo = () => {
    const { data, setData, setvalue } = useContext(DataContext);
    const { setActive, setComplete } = useContext(StepsContext);
    const { showInfo, showError, setLoading, setmOTP } = useContext(AlertDataContext);
    const { ApiData, setApiData } = useContext(ApiDataContext)
    const { IndividualDetail_II } = useContext(FunctionsContext)
    const history = useHistory();
    const enable = false;
    const datasubmit = async (e) => {
        // e.preventDefault();
        setLoading(true);

        var resp = await IndividualDetail_II(data);
        setLoading(false);
        
        if (resp.StatusCode === 0) {
            setApiData({ ...ApiData, sourceOfIncomeList: [...resp.ResponseObject.sourceOfIncomeList], OccupationList: [...resp.ResponseObject.OccupationList], OccupationChechList: [...resp.ResponseObject.OccupationChechList] });
            history.push("finalize");
        }
    }
    useEffect(() => {
        
       // setData({ ...data, nationality: 168, resident: 168 })
        setActive("Individual Information");
    }, [])
    return (
        <>
            {ApiData.Country ?
                <Animatedpage submit={datasubmit}>
                    <Grid item xs={12} id="s1q2">
                        <Row>
                            <div>
                                {/* <p style={{ marginTop: 40 }} className="Qhelper">Question <b>1</b> /8</p> */}
                                <h1 className="Q">Nationality/Residencies</h1>
                                <input className="inputtxt" name="nationality" value={data.nationality} onChange={(txt) => CheckAlpha(txt, () => { setvalue(txt) }, () => { })} required />
                                <p className="Qhelper"></p>
                            </div>

                            {/* <div>
                        <p style={{ marginTop: 40 }} className="Qhelper">Question <b>2</b> / 8</p>
                        <h1 className="Q">Resident Country</h1>
                        <input className="inputtxt" value="PAKISTAN" disabled name="Resident" onChange={(txt) => setvalue(txt)} required />
                        <p className="Qhelper"></p>
                    </div> */}

                            <div>
                                {/* <p style={{ marginTop: 40 }} className="Qhelper">Question <b>3</b> / 8</p> */}
                                <h1 className="Q">Other Nationalities/Residencies</h1>
                                <input className="inputtxt" value={data.OtherResident} name="OtherResident" onChange={(txt) => CheckAlpha(txt, () => { setvalue(txt) }, () => { })} />
                                <p className="Qhelper"></p>
                            </div>




                            {/* <div>                                
                                <h1 className="Q">Education</h1>
                                <select className="inputtxt" type="text" name="education" onChange={(txt) => setvalue(txt)} required>
                                    <option value="">Select</option>
                                    {
                                        ApiData.Education.map(p => (
                                            <option value={p.id} selected={parseInt(data.education) === p.id ? true : false}>{p.Name}</option>
                                        ))
                                    }
                                </select>
                                <p className="Qhelper"></p>
                            </div> */}
                            <div>
                                {/* <p style={{ marginTop: 40 }} className="Qhelper">Question <b>1</b> /3</p> */}
                                <h1 className="Q">Permanent Residential Address</h1>
                                <input className="inputtxt" name="PermanentResidential" value={data.PermanentResidential} onChange={(txt) => CheckAplhaChara(txt, () => { setvalue(txt) }, () => { })} required />
                                <p className="Qhelper2">House no., Street no, Area, Sector</p>
                            </div>
                            <div>
                                <h1 className="Q">Residential /landline contact number</h1>
                                {/* <input className="inputtxt" type="tel" maxLength={13} minLength={13} name="mnumber" value={data.mnumber} onChange={(txt) => setvalue(txt)} required /> */}
                                <div>
                                    <div class="input-group mb-3">
                                        <div class="input-group-prepend" style={{ marginTop: 5 }}>
                                            <span class="input-group-text">+92</span>
                                        </div>
                                        <input class="inputtxt form-control" type="tel" maxLength={10} minLength={10} placeholder="" style={{borderLeft: "none !important" }} value={data.landline} name="landline" onChange={(txt) => CheckNumeric(txt, () => { setvalue(txt) }, () => { })} required />
                                    </div>


                                </div>
                                <p className="Qhelper2"></p>
                            </div>
                            <div className="btndiv">
                                <button style={{ margin: 10, background: "var(--secondary)", border: "none", borderRadius: 10, marginTop: 20 }} onClick={() => history.push("basicdetails")}>
                                    <ArrowLeft style={{ marginLeft: -10 }} />
                                    Back
                                </button>
                                <button type="submit" style={{ margin: 10, background: "var(--primary)", border: "none", borderRadius: 10, marginTop: 20 }}>Next
                                    <ArrowRight style={{ marginRight: -10 }} />
                                </button>
                            </div>
                        </Row>
                    </Grid>
                </Animatedpage> :
                <></>}
        </>
    )

}
export default FinalizeInfo;