import React, { useContext, useEffect } from "react";
import { Grid } from "@material-ui/core";
import { ArrowRight, ArrowLeft, CheckBox } from "@material-ui/icons";
import Button from "reactstrap/lib/Button";
import Row from "../Row";
import { DataContext } from "../Context/Data.Context";
import Blue from '../../Assets/blue.png'
import { useHistory } from "react-router";
import { StepsContext } from "../Context/Steps.Context";
import Animatedpage from "../animatepage";
import { AlertDataContext } from "../Context/Alert.context";

import { ApiDataContext } from "../Context/Api.context";
import { FunctionsContext } from "../Context/Functions.context";
import { screen } from "./screen";
import { CheckNumeric, CheckAlpha, CheckAplhaChara } from "../service";

import Select from "react-select";
import { Branches } from "../../Assets/branches.js"
const FinalizeInfo2 = () => {
    const { data, setData, setvalue } = useContext(DataContext);
    const { setActive, AddSteps, RemoveSteps } = useContext(StepsContext);
    const { ApiData, setApiData } = useContext(ApiDataContext)
    const { IndividualDetail_III } = useContext(FunctionsContext)
    const { setLoading } = useContext(AlertDataContext);

    const history = useHistory();

    useEffect(() => {
        setActive("Individual Information");
    }, [])
    const populatemultiopt = (arr) => {
        var s = [];
        arr.map(p => {
            s.push({ value: p.id, label: p.Name });
        })
        return s;
    }
    const setmultiple = (txt, name) => {
        if (txt.findIndex(p => p.value === 2048) !== -1) {
            AddSteps("Fund Provider", "Individual Information")
        } else {
            RemoveSteps("Fund Provider");
        }
        var t = data;
        t[name] = txt;
        setData({ ...t });
        
    }
    const datasubmit = async (e) => {
        // e.preventDefault();
        setLoading(true);
        var resp = await IndividualDetail_III(data);
        setLoading(false);
        
        if (resp.StatusCode === 0) {
            setApiData({ ...ApiData, Zakatreason: resp.ResponseObject.ExemptionList, TINReasonList: resp.ResponseObject.TINReasonList })
            history.push("individual");
        }
    }
    return (
        <>
            {ApiData.sourceOfIncomeList ?
                <Animatedpage submit={datasubmit}>
                    <Grid item xs={12} id="s1q2">
                        <Row>


                            <div>
                                {/* <p style={{ marginTop: 40 }} className="Qhelper">Question <b>2</b> / 3</p> */}
                                <h1 className="Q">Occupation</h1>
                                <select className="inputtxt" type="text" name="Occupation" onChange={(txt) => setvalue(txt)} required>
                                    <option value="">Select</option>
                                    {ApiData.OccupationList.map(p => (
                                        <option value={p.id} selected={parseInt(data.Occupation) === p.id ? true : false}>{p.Name}</option>
                                    ))}
                                </select>
                                <p className="Qhelper2">Select the appropriate/relevant as per available documentary evidence.</p>
                            </div>
                            {data.Occupation === "4" || data.Occupation === "5" ?
                                <>
                                    <div>
                                        {/* <p style={{ marginTop: 40 }} className="Qhelper">Question <b>4</b> / 8</p> */}
                                        <h1 className="Q">Supporter Name</h1>
                                        <input className="inputtxt" type="text" value={data.supportername} name="supportername" onChange={(txt) => CheckAlpha(txt, () => { setvalue(txt) }, () => { })} required />
                                        <p className="Qhelper"></p>
                                    </div>
                                    <div>
                                        {/* <p style={{ marginTop: 40 }} className="Qhelper">Question <b>4</b> / 8</p> */}
                                        <h1 className="Q">Supporter CNIC</h1>
                                        <input className="inputtxt" minLength={13} maxLength={13} onChange={(txt) => CheckNumeric(txt, () => { setvalue(txt) }, () => { })} type="text" value={data.supporterCNIC} name="supporterCNIC" required />
                                        <p className="Qhelper"></p>
                                    </div>
                                    <div>
                                        {/* <p style={{ marginTop: 40 }} className="Qhelper">Question <b>4</b> / 8</p> */}
                                        <h1 className="Q">Supporter Relationship</h1>
                                        <input className="inputtxt" type="text" value={data.supporterrelation} name="supporterrelation" onChange={(txt) => CheckAlpha(txt, () => { setvalue(txt) }, () => { })} required />
                                        <p className="Qhelper"></p>
                                    </div>
                                    <div>
                                        {/* <p style={{ marginTop: 40 }} className="Qhelper">Question <b>4</b> / 8</p> */}
                                        <h1 className="Q">Supporter Job Title</h1>
                                        <input className="inputtxt" type="text" value={data.supporterjob} name="supporterjob" onChange={(txt) => CheckAplhaChara(txt, () => { setvalue(txt) }, () => { })} required />
                                        <p className="Qhelper"></p>
                                    </div>
                                </>
                                : <></>
                            }



                            <br />
                            <div>
                                {/* <p style={{ marginTop: 40 }} className="Qhelper">Question <b>3</b> / 3</p> */}
                                <h1 className="Q">Source of Income</h1>
                                <Select
                                    defaultValue={data.Income}
                                    isMulti
                                    name="Income"
                                    options={populatemultiopt(ApiData.sourceOfIncomeList)}
                                    className="basic-multi-select"
                                    classNamePrefix="select"
                                    onChange={(txt) => setmultiple(txt, "Income")}
                                />
                                <p className="Qhelper">Select the appropriate/relevant as per available documentary evidence</p>
                                {(data.Occupation === "Salaried" || data.Occupation === "Self Employed" || data.Occupation === "Business" || data.Occupation === "Freelancer") ?
                                    <>
                                        <label for="occchk" className="">
                                            <input type="checkbox" id="occchk" style={{ marginRight: 5, width: 18, height: 18 }} />
                                            I do hereby confirm and undertake that I holds the controlling rights being the owner of funds in this account, I will be
                                            treated as sole beneficial owner of information uploaded/provided digitally for this account in BankIslami.</label >
                                    </> :
                                    <></>
                                }
                            </div>

                            <div>
                                <h1 className="Q">Attach Source of Income Doc</h1>
                                <input className="inputtxt" type="file" name="incomereceipt" multiple={false} onChange={(txt) => setvalue(txt)} required />
                                <p className="Qhelper" style={{ marginTop: 5, fontSize: 12 }}>Please attach documentary evidence(s) as per above selected Occupation/Source of Income (PNG, PDF, BMP, GIF or JPEG).</p>
                            </div>
                            <div>
                                <h1 className="Q">Attach Proof of Profession</h1>
                                <input className="inputtxt" type="file" name="professionreceipt" multiple={false} onChange={(txt) => setvalue(txt)} required />
                                <p className="Qhelper" style={{ marginTop: 5, fontSize: 12 }}>Please attach documentary evidence(s) of Proof of Profession (PNG, PDF, BMP, GIF or JPEG).</p>
                            </div>

                            <div>
                                {/* <p style={{ marginTop: 40 }} className="Qhelper">Question <b>4</b> / 8</p> */}
                                <h1 className="Q">Next of Kin Name</h1>
                                <input className="inputtxt" type="text" value={data.kinname} name="kinname" onChange={(txt) => CheckAlpha(txt, () => { setvalue(txt) }, () => { })} required />
                                <p className="Qhelper"></p>
                            </div>
                            <div>
                                {/* <p style={{ marginTop: 40 }} className="Qhelper">Question <b>5</b> / 8</p> */}
                                <h1 className="Q">Next of Kin Address</h1>
                                <input className="inputtxt" type="text" value={data.kinaddress} name="kinaddress" onChange={(txt) => CheckAplhaChara(txt, () => { setvalue(txt) }, () => { })} required />
                                <p className="Qhelper"></p>
                            </div>
                            <div>
                                {/* <p style={{ marginTop: 40 }} className="Qhelper">Question <b>6</b> / 8</p> */}
                                <h1 className="Q">Relation with Next of Kin</h1>
                                <input className="inputtxt" type="text" value={data.kinrelation} name="kinrelation" onChange={(txt) => CheckAlpha(txt, () => { setvalue(txt) }, () => { })} required />
                                <p className="Qhelper"></p>
                            </div>
                            <div>
                                {/* <p style={{ marginTop: 40 }} className="Qhelper">Question <b>7</b> / 8</p> */}
                                <h1 className="Q">Next of Kin Contact number</h1>
                                <input class="inputtxt" type="text" maxLength={13} minLength={9} placeholder="" style={{ borderLeft: "none !important" }} value={data.kinnumber} name="kinnumber" onChange={(txt) => CheckNumeric(txt, () => { setvalue(txt) }, () => { })} required />
                                <p className="Qhelper"></p>
                            </div>
                            <div className="btndiv">
                                <Button variant="contained" style={{ margin: 10, background: "var(--secondary)", border: "none", borderRadius: 10, marginTop: 20 }} onClick={() =>
                                    history.push("otherinfo")
                                }>
                                    <ArrowLeft style={{ marginLeft: -10 }} />
                                    Back
                                </Button>
                                <Button type="submit" variant="contained" style={{ margin: 10, background: "var(--primary)", border: "none", borderRadius: 10, marginTop: 20 }}>Next
                                    <ArrowRight style={{ marginRight: -10 }} />
                                </Button>
                            </div>
                        </Row>
                    </Grid>
                </Animatedpage> : <></>}
        </>
    )

}
export default FinalizeInfo2;