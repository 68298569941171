import React from "react";

const Row=({children,style,className})=>{    
        return (
        <div  style={{width:"90%",marginLeft:"5%",marginRight:"5%",alignSelf:"center",...style}} className={className+" rowmob"}>
                {children}

        </div>
        );    
}
 
export default Row;