function postRequest(endpoint, data) {
    return new Promise((resolve, reject) => {
        var fetchObj = {};
        if (data instanceof FormData) {
            fetchObj = {
                method: 'POST',
                body: data
            }
        }
        else {
            fetchObj = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(data)
            };

        }
        fetch(endpoint, fetchObj
        ).then((res) => res.json().then((resp) => {
            if (resp.StatusCode === 2 || resp.StatusCode === 12) {
                window.location.href = "/admin/login";
                resolve();
            } else {
                resolve(resp);
            }
        })).catch(err => {
        })
    })
}

function KeyValue(key, value) {
    this.key = key;
    this.value = value;
}
function CheckAlpha(txt, success, failed) {
    debugger
    var value = txt.target.value;
    var letters = /^[A-Za-z ][^<>;]*$/;
    if (value.match(letters)) {
        success();
    }
    else {
        txt.target.value = txt.target.value.slice(0, -1);
        failed()
    }
}

function getFileData(data, ...attachment) {
    debugger;
    var form_data = new FormData();
    if (!attachment) {
        let _attachment = [...arguments];
        buildFormData(form_data, data);

        _attachment.forEach((element) => {
            if (element !== null) {
                form_data.append(element.key, element.value);
            }
        });

        return form_data;
    }

    buildFormData(form_data, data);

    attachment.forEach((element) => {
        if (element !== null) {
            form_data.append(element.key, element.value);
        }
    });

    return form_data;
}

function buildFormData(formData, data, parentKey) {
    if (data && typeof data === 'object' && !(data instanceof Date) && !(data instanceof File)) {
        Object.keys(data).forEach(key => {
            buildFormData(formData, data[key], parentKey ? `${parentKey}[${key}]` : key);
        });
    } else {
        const value = data == null ? '' : data;

        formData.append(parentKey, value);
    }
}


function CheckNumeric(txt, success, failed) {
    var value = txt.target.value;
    var letters = /^[0-9]*$/;
    if (value.match(letters)) {
        success();
    }
    else {
        txt.target.value = txt.target.value.slice(0, -1);
        failed()
    }
}


function CheckAplhaChara(txt, success, failed) {
    var value = txt.target.value;
    var letters = /^[A-Za-z 0-9^@.,#/\r\n]*$/;
    if (value.match(letters)) {
        success();
    }
    else {
        txt.target.value = txt.target.value.slice(0, -1);
        failed()
    }
}

export {
    postRequest,
    CheckAlpha,
    CheckNumeric,
    CheckAplhaChara,
    getFileData,
    KeyValue,
}