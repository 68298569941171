import React, { useContext, useEffect, useRef, useState } from "react";
import { Grid } from "@material-ui/core";
import { ArrowRight, ArrowLeft, CheckBox } from "@material-ui/icons";
import Button from "reactstrap/lib/Button";
import Row from "../Row";
import { DataContext } from "../Context/Data.Context";
import Blue from '../../Assets/blue.png'
import { useHistory } from "react-router";
import { StepsContext } from "../Context/Steps.Context";
import Animatedpage from "../animatepage";
import CardSample from "../../Assets/cardsample.png";
import Paypak from '../../Assets/paypak.png'
import Mastergold from '../../Assets/mastergold.png'
import Master from '../../Assets/master.png'
import { Chip, MenuItem } from "@material-ui/core";

// import Select from '@material-ui/core/Select';
import Select from 'react-select';
import Input from "reactstrap/lib/Input";
import { makeStyles } from "@material-ui/styles";

import { ApiDataContext } from "../Context/Api.context";
import { AlertDataContext } from "../Context/Alert.context";
import { FunctionsContext } from "../Context/Functions.context";
import { Config } from "../congig";
import { screen } from "./screen";
import CustomCheck from '../customComponents/CustomCheck';
const CNC = () => {
    const { data, setData, setvalue, setmultiple } = useContext(DataContext);
    const { setActive } = useContext(StepsContext);
    const { ApiData } = useContext(ApiDataContext)
    const { DynamicModelShow, showError, setLoading } = useContext(AlertDataContext);
    const { CNCInfo } = useContext(FunctionsContext)


    const [plist, setPlist] = useState([]);
    const [brancheslist, setbrancheslist] = useState([])
    const cityref = useRef();
    const [show, setshow] = useState(false);

    const history = useHistory();

    const enable = screen[history.location.pathname.replace("/", "")] < screen[sessionStorage.getItem("CurrentScreen")] ? true : false;

    const showFDAModel = (data) => {
        var t = <>
            <ul>
                {data.LongDescription.map(o => (
                    <li>{o}</li>
                ))}
            </ul>
            {/* {data.Note !== "" ?
                <p className="Q">Note:{data.Note}</p>
                : <></>} */}
        </>;
        DynamicModelShow(data.Name, t, true);
    }
    const populatecities = (list) => {
        var cities = {};
        var t = '<option value="">Select</option>';
        brancheslist.map(p => {

            if (p.Province === data.Branchprovince && !(p.City in cities)) {
                t += `<option value=${p.City}>${p.City}</option>`;
                cities[p.City] = 1;
            }
        })
        cityref.current.innerHTML = t;
    }
    useEffect(() => {
        debugger
        setActive("Value Added Services");
    }, [])
    useEffect(() => {
        if (plist.length === 0) {
            popbanklist();
        }
    }, [ApiData])
    const popbanklist = () => {
        debugger
        setLoading(true);
        if (ApiData.BankList?.length > 0) {
            var t = ApiData.BankList;
            
            if (t?.length > 0) {
                var arr = [];
                var alreadyp = [];
                var branches = [];
                for (let index = 0; index < t.length; index++) {
                    
                    const element = JSON.parse(t[index]);
                    if (alreadyp[element.Province] === undefined) {
                        arr.push(element.Province);
                        alreadyp[element.Province] = 1;
                    }
                    branches.push(element);
                }
                setPlist([...arr]);
                setbrancheslist([...branches]);
            }
        }
        setLoading(true);

    }

    const datasubmit = async (e) => {

        setLoading(true);
        var resp = await CNCInfo(data);
        setLoading(false);        
        if (resp.StatusCode === 0) {
            //setApiData({ ...ApiData, ...resp.ResponseObject });
            history.push("tnc")
        } 
    }

    const populatemultiopt = (arr) => {
        var s = [];
        arr.map(p => {
            s.push({ value: p.id, label: p.Name });
        })
        return s;
    }

    return (
        <>


            <Animatedpage submit={() => { !enable ? datasubmit() : history.push("liveness") }} pop={() => { setshow(true); }}>
                {show ?
                    <Grid item xs={12} id="s1q2">
                        <Row>
                            <fieldset disabled={enable}>

                                <div>
                                    {/* <p style={{ marginTop: 40 }} className="Qhelper">Question <b>1</b> /2</p> */}
                                    <h1 className="Q">Cheque Book</h1>

                                    <select className="inputtxt" name="Checkbook" onChange={(txt) => setvalue(txt)} required>
                                        <option value="">Select</option>

                                        <option value="0">No</option>
                                        <option value="1">Yes</option>
                                    </select>
                                    <p className="Qhelper2"></p>
                                </div>
                                {data.Checkbook === "1" ?
                                    <div>
                                        {/* <p style={{ marginTop: 40 }} className="Qhelper"></p> */}
                                        <h1 className="Q">Select No. of Leaves</h1>
                                        <select className="inputtxt" name="CheckbookLeave" onChange={(txt) => setvalue(txt)} required>
                                            <option value="">Select</option>

                                            {ApiData.LeavesList.map(p => (
                                                <>
                                                    {p.Name === "10" && 1 === parseInt(data.type) ?
                                                        <></> :
                                                        <option value={p.id} >{p.Name}</option>
                                                    }</>
                                            ))}
                                        </select>
                                        <p className="Qhelper2"></p>
                                        <label for="occchk" className="Qhelper">
                                            <input type="checkbox" id="occchk" name="ChecqueBookCheck" onChange={(txt) => setvalue(txt)} style={{ marginRight: 5, width: 18, height: 18 }} required />
                                            I/We do hereby authorise BankIslami Ltd. to share my/our account information, including account number and title with the Bank’s approved printer for Cheque Book printing.</label >
                                    </div>
                                    : <></>}
                                <div>
                                    {/* <p style={{ marginTop: 40 }} className="Qhelper">Question <b>1</b> /2</p> */}
                                    <h1 className="Q">Upload Signature image</h1>
                                    <input className="inputtxt" multiple={false} name="signature" id="signature" type="file" onChange={(txt) => setvalue(txt)} required />
                                    <p className="Qhelper" style={{ marginTop: 5, fontSize: 12 }}>Ensure to Attach (PNG, PDF, BMP, GIF or JPEG)</p>
                                </div>
                                <div>
                                    <ol>
                                        <li><CustomCheck list={ApiData.CheckBoxList} Name={"CNC_Signature1_1"} setvalue={setvalue} required={true} /></li>
                                        <li><CustomCheck list={ApiData.CheckBoxList} Name={"CNC_Signature2_1"} setvalue={setvalue} required={true} /></li>
                                        <li><CustomCheck list={ApiData.CheckBoxList} Name={"CNC_Signature3_1"} setvalue={setvalue} required={true} /></li>
                                        <li><CustomCheck list={ApiData.CheckBoxList} Name={"CNC_Signature4_1"} setvalue={setvalue} required={true} /></li>
                                    </ol>
                                    {/* <label for="trm" className="Qhelper">
                                        <input type="checkbox" id="trm" style={{ marginRight: 5, width: 18, height: 18 }} required />
                                        I/We do hereby agree with the terms mentioned above.
                                    </label > */}
                                </div>
                                <div>
                                    {/* <p style={{ marginTop: 40 }} className="Qhelper">Question <b>1</b> /2</p> */}
                                    <h1 className="Q">Electronic Fund Transfer (EFT)Consent</h1>

                                    <select className="inputtxt" name="EFT" onChange={(txt) => setvalue(txt)} required>
                                        <option value="">Select</option>

                                        <option value="0">No</option>
                                        <option value="1">Yes</option>
                                    </select>
                                    <p className="Qhelper2"></p>
                                    {data.EFT === "1" ?
                                        <CustomCheck list={ApiData.CheckBoxList} Name={"CNC_EFT_1"} setvalue={setvalue} required={true} />
                                        : <></>}
                                </div>
                                <div>
                                    {/* <p style={{ marginTop: 40 }} className="Qhelper">Question <b>1</b> /7</p> */}
                                    <h1 className="Q">SMS Subscription</h1>
                                    <select className="inputtxt" name="smssubscription" onChange={(txt) => setvalue(txt)} required
                                    //disabled={data.currency==="PKR"&&data.type==="current"?true:false}
                                    >
                                        <option value="">Select</option>
                                        <option value="0">No</option>
                                        <option value="1">Yes</option>
                                    </select>
                                    <p className="Qhelper2">Charges as per SOC</p>

                                </div>
                                <div>
                                    {/* <p style={{ marginTop: 40 }} className="Qhelper">Question <b>1</b> /2</p> */}
                                    <h1 className="Q">E-Statement Subscription</h1>

                                    <select className="inputtxt" name="ESS" onChange={(txt) => setvalue(txt)} required>
                                        <option value="">Select</option>

                                        <option value="0">No</option>
                                        <option value="1">Yes</option>
                                    </select>
                                    <p className="Qhelper2"></p>
                                </div>
                                {data.ESS === "1" ?
                                    <div>
                                        {/* <p style={{ marginTop: 40 }} className="Qhelper">Question <b>1</b> /2</p> */}
                                        <h1 className="Q">E-Statement Frequency</h1>
                                        <h1 className="Q">E-mail {data.email}</h1>
                                        <select className="inputtxt" name="ESF" onChange={(txt) => setvalue(txt)} required>
                                            <option value="">Select</option>

                                            {ApiData.StatementFrequencyList.map(p => (
                                                <option value={p.id}>{p.Name}</option>
                                            ))}
                                        </select>
                                        <p className="Qhelper2"></p>

                                        <CustomCheck list={ApiData.CheckBoxList} Name={"CNC_ESF_1"} setvalue={setvalue} required={true} />

                                    </div>
                                    : <></>
                                }




                                <div>
                                    {/* <p style={{ marginTop: 40 }} className="Qhelper">Question <b>2</b> /2</p> */}
                                    <h1 className="Q">ATM Debit Card</h1>
                                    <select className="inputtxt" name="ATM" onChange={(txt) => setvalue(txt)} required disabled>
                                        <option value="">Select</option>

                                        <option value="0">No</option>
                                        <option value="1" selected>Yes</option>

                                    </select>
                                    <p className="Qhelper2"></p>

                                </div>
                                {data.ATM === "1" || true ?
                                    <>
                                        <div>
                                            <h1 className="Q">Card Type</h1>
                                            {ApiData.CardTypeDetailList.ResponseObject.map(p => (
                                                <div key={p.Id} className={"Carddiv" + ((data.ATMCard?.Id === p.Id) ? " Active" : "")} onClick={() => {
                                                    
                                                    setData({ ...data, ATMCard: p })
                                                    showFDAModel(p)
                                                }} style={{ width: 300, height: 220 }}>
                                                    {data.ATMCard === p ? <CheckBox className="CardCheck" style={{ color: "var(--light)" }} /> : null}
                                                    <p className="Q" style={{ display: "inline-block", marginTop: 0, color: "black" }}>{p.Name}</p>
                                                    <img src={(Config.LOCALIMG ? Config.LOCALADDRESS : window.location.origin) + p.CardPath} />
                                                </div>
                                            ))}
                                            <p className="Qhelper2"></p>

                                        </div>
                                        {/* {
                                            data.ATMCard?.Name !== "Soneri Pay Pak Debit Card" ?

                                                <div>
                                                    
                                                    <h1 className="Q" style={{ marginTop: 20 }}>Reason for selection of {data.ATMCard.Name}</h1>
                                                    <Select
                                                        defaultValue={data.ATMReason}
                                                        isMulti
                                                        name="colors"
                                                        onChange={() => { }}
                                                        options={populatemultiopt(ApiData.CardReason)}
                                                        className="basic-multi-select"
                                                        classNamePrefix="select"
                                                        onChange={(txt) => setmultiple(txt, "ATMReason")}
                                                    />
                                                    <p className="Qhelper2"></p>

                                                </div>
                                                :
                                                <></>
                                        } */}
                                    </>
                                    : <></>}


                            </fieldset>
                            <div className="btndiv">
                                <button variant="contained" style={{ margin: 10, background: "var(--secondary)", border: "none", borderRadius: 10, marginTop: 20 }} onClick={() =>
                                    history.push(data.option.AccountType === "Asaan Digital Remittance" ? "remitter" : "znt")
                                }>
                                    <ArrowLeft style={{ marginLeft: -10 }} />
                                    Back
                                </button>
                                <button type="submit" style={{ margin: 10, background: "var(--primary)", border: "none", borderRadius: 10, marginTop: 20 }}>Next
                                    <ArrowRight style={{ marginRight: -10 }} />
                                </button>
                            </div>
                        </Row>
                    </Grid>
                    :
                    <></>
                }
            </Animatedpage>
        </>
    );
}

export default CNC;