import React, { useContext, useEffect, useState } from "react";
import { Grid } from "@material-ui/core";
import { ArrowRight, ArrowLeft, CheckBox } from "@material-ui/icons";
import Button from "reactstrap/lib/Button";
import Row from "../Row";
import { DataContext } from "../Context/Data.Context";
import Blue from '../../Assets/blue.png'
import { useHistory } from "react-router";
import { StepsContext } from "../Context/Steps.Context";
import Recaptcha from 'react-recaptcha'
import Animatedpage from "../animatepage";
import { ResentOtp } from "../service";
import { AlertDataContext } from "../Context/Alert.context";
import { FunctionsContext } from "../Context/Functions.context";
import { ApiDataContext } from "../Context/Api.context";


const BasicDetails = () => {
    const { data, setData, setvalue } = useContext(DataContext);
    const { setActive, setComplete } = useContext(StepsContext);
    const { showInfo, showError, setLoading } = useContext(AlertDataContext);
    const { ApiData, setApiData } = useContext(ApiDataContext)

    const { VerifyOtp } = useContext(FunctionsContext)
    const [Captcha, setCaptcha] = useState("")
    const [otp, setotp] = useState("")
    const [otpEmail, setotpEmail] = useState("")

    const [countdown, setcountdown] = useState(60);
    const history = useHistory();
    var callback = function () {
        
    };
    var verifyCallback = function (response) {
        setCaptcha(true);
    };
    var expiredCallback = function (response) {
        setCaptcha(false)
    }
    useEffect(() => {
        setActive("Basic Details & OTP Verification");

    }, [])
    useEffect(() => {
        setcountdown(60);
        var t = setInterval(() => {
            if (countdown > -1) {
                var s = countdown - 1;
                setcountdown(prevTime => prevTime - 1);
            }
        }, 1000);
        var s = setTimeout(() => {
            clearInterval(t);
            clearTimeout(s)
        }, 1000 * 60);
        return () => {
            clearInterval(t);
            clearTimeout(s)
        }
    }, []);

    const datasubmit = async (e) => {
        //e.preventDefault();
        if (Captcha) {
            setLoading(true);
            var resp = await VerifyOtp(data, otp, otpEmail);
            setLoading(false);
            
            if (resp.StatusCode === 0) {
                setApiData({ ...ApiData, ...resp.ResponseObject });
                //history.push("/accountoption");
                window.location.href = "/accountoption";
            } else {
                showError(resp.StatusMessage);
            }
        } else {
            alert("Validate Captcha")
        }
    }
    const resend = async () => {

        setLoading(true);
        var resp = await ResentOtp(data);
        setLoading(false);
        
        setcountdown(60);
        var t = setInterval(() => {
            if (countdown > -1) {
                var s = countdown - 1;
                setcountdown(prevTime => prevTime - 1);
            }
        }, 1000);
        var s = setTimeout(() => {
            clearInterval(t);
            clearTimeout(s)
        }, 1000 * 60);
    }


    return (
        <Animatedpage submit={datasubmit}>
            <Grid item xs={12} id="s1q2">
                <Row>
                    <div>
                        <p className="Qhelper">Phone Number : <b>+92{data.mnumber}</b></p>
                        <p className="Qhelper">Email : <b>{data.email}</b></p>
                    </div>
                    <div>
                        {/* <p style={{ marginTop: 40 }} className="Qhelper">Question <b>1</b> / 1</p> */}
                        <p className="Qhelper">Enter the OTP code sent to your provided mobile number.</p>
                        <input className="inputtxt" type={"password"} minLength={4} maxLength={4} name="otp" onChange={(txt) => setotp(txt.target.value)} required />
                        <p className="Qhelper">Enter the OTP code sent to your provided Email address.</p>
                        <input className="inputtxt" type={"password"} minLength={4} maxLength={4} name="otp" onChange={(txt) => setotpEmail(txt.target.value)} required />
                        {countdown < 1 ? <p onClick={() => resend()} className="Qhelper" style={{ cursor: "pointer" }}>Resent OTP</p> : <p className="Qhelper">OTP expire in 00:{countdown < 10 ? "0" + countdown : countdown}</p>}
                        <Recaptcha
                            sitekey="6Ld_88YcAAAAAB179MAic7n2N5tgDn6jHERoYKew"
                            render="explicit"
                            verifyCallback={verifyCallback}
                            onloadCallback={callback}
                            expiredCallback={expiredCallback}
                        />
                    </div>
                    <label for="otpchk" className="" style={{ marginBottom: 20 }}>
                        <input type="checkbox" id="otpchk" name="otpchk" onChange={(txt) => setvalue(txt)} style={{ marginRight: 5, width: 18, height: 18 }} required />
                        All correspondence with the customer will be carried out on above-mentioned email address and mobile no. unless changed in the future by the customer.
                    </label >
                    <div className="btndiv">
                        <button style={{ margin: 10, background: "var(--secondary)", border: "none", borderRadius: 10, marginTop: 20 }} onClick={() => history.push("/")}>
                            <ArrowLeft style={{ marginLeft: -10 }} />
                            Back
                        </button>
                        <button type="submit" style={{ margin: 10, background: "var(--primary)", border: "none", borderRadius: 10, marginTop: 20 }}>Next
                            <ArrowRight style={{ marginRight: -10 }} />
                        </button>
                    </div>
                </Row>
            </Grid>
        </Animatedpage>
    )

}
export default BasicDetails;