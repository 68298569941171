const camera = function () {
    let width = 0;
    let height = 0;

    const createObjects = function () {
        var video = document.getElementById('video');
        var canvas = document.getElementById('canvas');
        if (video === null) {
            video = document.createElement('video');
            video.id = 'video';
            video.width = width;
            video.height = height;
            video.autoplay = true;
            document.getElementById('mbody').appendChild(video);
        }
        if (canvas === null) {
            canvas = document.createElement('canvas');
            canvas.id = 'canvas';
            canvas.width = width;
            canvas.height = height;
            canvas.style.display = "none";
            document.getElementById('mbody').appendChild(canvas);
        }
    }


    return {
        video: null,
        context: null,
        canvas: null,

        startCamera: function (w = 280, h = 180) {
            if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
                width = w;
                height = h;

                createObjects();

                this.video = document.getElementById('video');
                this.canvas = document.getElementById('canvas');
                this.context = this.canvas.getContext('2d');


                (function (video) {
                    navigator.mediaDevices.getUserMedia({ video: true }).then(function (stream) {
                        video.srcObject = stream;
                        video.play();
                    });
                })(this.video)

            }
        },
        stopCamera: function () {
            const vid = document.getElementById('video');
            var mediaStream = vid;
            if (mediaStream !== null&&vid!==null) {
                mediaStream = vid.srcObject;
                if(mediaStream!==null){

                // Through the MediaStream, you can get the MediaStreamTracks with getTracks():
                const tracks = mediaStream.getTracks();
                // Tracks are returned as an array, so if you know you only have one, you can stop it with: 
                tracks[0].stop();

                // Or stop all like so:
                tracks.forEach(track => track.stop())
                }
            }
        },
        tryagain: function () {
            var v = document.getElementById('video');
            var canvs = document.getElementById('canvas');
            v.style.display = "block";
            canvs.style.display = "none";
        },
        takeSnapshot: function () {
            var v = document.getElementById('video');
            var canvs = document.getElementById('canvas');
            var ctx = canvs.getContext('2d');
            ctx.drawImage(v, 0, 0, 250, 180);
            canvs.style.display = "block";
            v.style.display = "none";
        }

    }
}();

export default camera;