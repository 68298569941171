import React, { useContext, useEffect, useState } from "react";
import { Grid } from "@material-ui/core";
import { ArrowRight, ArrowLeft, AttachFile, Person, AssignmentInd, CalendarToday, PhoneAndroid, Email, LocationCity } from "@material-ui/icons";
import Button from "reactstrap/lib/Button";
import Row from "../Row";
import { DataContext } from "../Context/Data.Context";
import Blue from '../../Assets/blue.png'
import { useHistory } from "react-router";
import { StepsContext } from "../Context/Steps.Context";
import Animatedpage from "../animatepage";
import Cinput from "../customComponents/CustomInput";
import { CheckAlpha, CheckAplhaChara, CheckNumeric, CheckPhone, ContineuResentOtp } from "../service";
import { AlertDataContext } from "../Context/Alert.context";
import { FunctionsContext } from "../Context/Functions.context";
import Recaptcha from 'react-recaptcha'
import { Config } from "../congig";
import { screen } from "./screen";
import { ApiDataContext } from "../Context/Api.context";
import CustomCheck from '../customComponents/CustomCheck'

const BasicDetails = () => {
    const { data, setData, setvalue } = useContext(DataContext);
    const { setActive, setComplete } = useContext(StepsContext);
    const { ApiData, setApiData } = useContext(ApiDataContext);

    const { showInfo, showError, setLoading, DynamicModelShow, DynamicModelHide } = useContext(AlertDataContext);
    const { VerifyDetail } = useContext(FunctionsContext)
    const history = useHistory();
    const [checkexpirybool, setcheckexpirybool] = useState(false)
    const [Captcha, setCaptcha] = useState(false)
    const [isCNICCheck, setIsCNICCheck] = useState(false)
    const [Layout, setLayout] = useState(false)
    const enable = screen[history.location.pathname.replace("/", "")] <= screen[sessionStorage.getItem("CurrentScreen")] ? true : false;
    const [goforwardDpb, setgoforwardDpb] = useState(true)
    const datasubmit = async (e) => {
       setLoading(true);
        const ipv4 = "";// await publicIp.v4()
        setData({ ...data, UserIp: ipv4 });
        if (data.latitude === null) {
            alert("Please enable the location to proceed further for digital account opening.");
            getLocation();
            setLoading(false);

            return
        }
        var resp = await VerifyDetail(data);
        setLoading(false);
        if (resp.StatusCode === 0) {
            history.push("otherinfo")
        }
    }
    useEffect(async () => {
        getLocation();
        setActive("Basic Details & OTP Verification");
    }, [])
    const checkexpiry = (txt) => {
        var value = txt.target.value;
        var t = new Date(value);
        if (t <= new Date()) {
            // alert("Please ensure to submit NADRA receipt/token along with your CNIC.");
            setcheckexpirybool(true);
        } else {
            setcheckexpirybool(false);

        }
        setvalue(txt);
    }
    function getLocation() {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition((position) => {
                setData({
                    ...data,
                    latitude: position.coords.latitude,
                    longitude: position.coords.longitude
                })
            }, () => {
                setData({
                    ...data,
                    latitude: null,
                    longitude: null
                })
            });
        }
    }

    const checkdob = (txt) => {
        var t = new Date(txt.target.value);
        var age = parseInt(new Date().getFullYear()) - parseInt(t.getFullYear());
        if (age < 18) {
            alert("Dear Customer, currently we are not offering Digital Account Opening facility for Minors. Inconvenience is regretted, please approach nearest BankIslami Branch.");
            setgoforwardDpb(false);
        } else {
            setgoforwardDpb(true);
        }
        setvalue(txt);
    }
    return (
        <Animatedpage submit={() => { !enable ? datasubmit() : history.push("individual") }} pop={() => setLayout(true)}>
            {Layout ?
                <Grid item xs={12} id="s1q2">
                    <Row>
                        <fieldset disabled={enable}>
                            <div>
                                <div className="inputrow">
                                    <div className="inputcolumn1">
                                        <CalendarToday style={{ marginTop: 30, fill: "var(--secondary)", fontSize: 20 }} />
                                    </div>
                                    <div className="inputcolumn2">
                                        <h1 className="Q">ID Issuance Date</h1>
                                        <input className="inputtxt" type="date" value={data.issuancedate} name="issuancedate" onChange={(txt) => {
                                            if (new Date(txt.target.value) > new Date()) {
                                                setData({ ...data, dateofbirth: new Date() });
                                                // checkdob(txt)
                                            } else {
                                                setvalue(txt)
                                            }
                                        }} required />
                                        <p className="Qhelper2">Enter CNIC issuance date.</p>
                                    </div>
                                </div>

                            </div>
                            <div>
                                <div className="inputrow">
                                    <div className="inputcolumn1">
                                        <CalendarToday style={{ marginTop: 30, fill: "var(--secondary)", fontSize: 20 }} />
                                    </div>
                                    <div className="inputcolumn2">
                                        <h1 className="Q">ID Expiry Date</h1>
                                        <input className="inputtxt" type="date" name="expirydate"
                                            min={new Date().getFullYear(data.issuancedate) + "-" + (parseInt(new Date(data.issuancedate).getMonth()) + 1) + "-" + parseInt(new Date(data.issuancedate).getDate() + 1)}
                                            onChange={(txt) => checkexpiry(txt)} required value={data.expirydate} disabled={data.BD_Lifetime_1 === "" || data.BD_Lifetime_1 === undefined || data.BD_Lifetime_1 === "None" ? false : true} />
                                        <p className="Qhelper2">Enter CNIC expiry date.</p>
                                        <CustomCheck list={ApiData.CheckBoxList} Name={"BD_Lifetime_1"} setvalue={setvalue} required={false} />
                                    </div>
                                </div>
                            </div>

                            {
                                checkexpirybool && (data.BD_Lifetime_1 === undefined || data.BD_Lifetime_1 === "") ?
                                    <div>
                                        <h1 className="Q" style={{ color: "red" }}>Please ensure to submit NADRA receipt/token along with your CNIC.</h1>
                                        <div className="inputrow">
                                            <div className="inputcolumn1">
                                                <AttachFile style={{ marginTop: 30, fill: "var(--secondary)", fontSize: 20 }} />
                                            </div>
                                            <div className="inputcolumn2">
                                                <h1 className="Q">Attach NADRA receipt/token</h1>
                                                <input className="inputtxt" type="file" accept="application/pdf, image/png,image/jpg,image/jpeg" name="cnicreceipt" multiple={false} onChange={(txt) => setvalue(txt)} required />
                                                <p className="Qhelper2">Ensure to Attach/Scan Original (colored) Identity Document (PNG or JPEG)</p>
                                            </div>
                                        </div>

                                    </div>
                                    : <></>
                            }
                            <div>
                                <div className="inputrow">
                                    <div className="inputcolumn1">
                                        <AttachFile style={{ marginTop: 30, fill: "var(--secondary)", fontSize: 20 }} />
                                    </div>
                                    <div className="inputcolumn2">
                                        <h1 className="Q">Attach CNIC (Front)</h1>
                                        <input className="inputtxt" type="file" accept="image/png,image/jpg,image/jpeg" name="cnicdocfront" multiple={false} onChange={(txt) => setvalue(txt)} required />
                                        <p className="Qhelper" style={{ marginTop: 5, fontSize: 12 }}>Ensure to Attach/Scan Original (colored) Identity Document (PNG, PDF, BMP, GIF or JPEG)</p>
                                    </div>
                                </div>

                            </div>
                            <div>
                                <div className="inputrow">
                                    <div className="inputcolumn1">
                                        <AttachFile style={{ marginTop: 30, fill: "var(--secondary)", fontSize: 20 }} />
                                    </div>
                                    <div className="inputcolumn2">
                                        <h1 className="Q">Attach CNIC (Back)</h1>
                                        <input className="inputtxt" type="file" accept="image/png,image/jpg,image/jpeg" name="cnicdocback" multiple={false} onChange={(txt) => setvalue(txt)} required />
                                        <p className="Qhelper" style={{ marginTop: 5, fontSize: 12 }}>Ensure to Attach/Scan Original (colored) Identity Document (PNG, PDF, BMP, GIF or JPEG)</p>
                                    </div>
                                </div>

                            </div>
                            <div>
                                <div className="inputrow">
                                    <div className="inputcolumn1">
                                        <Person className="Iconcls" style={{ marginTop: 30, fill: "var(--secondary)", fontSize: 20 }} />
                                    </div>
                                    <div className="inputcolumn2">
                                        <h1 className="Q">Full Name (as per CNIC)</h1>
                                        <input className="inputtxt" name="customerName" value={data.customerName} onChange={(txt) => CheckAlpha(txt, () => { setvalue(txt) }, () => { })} required />
                                        <p className="Qhelper2">As per CNIC ID document.</p>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div className="inputrow">
                                    <div className="inputcolumn1">
                                        <Person className="Iconcls" style={{ marginTop: 30, fill: "var(--secondary)", fontSize: 20 }} />
                                    </div>
                                    <div className="inputcolumn2">
                                        {/* <p style={{ marginTop: 40 }} className="Qhelper">Question <b>2</b> / 6</p> */}
                                        <h1 className="Q">Father/Husband Name</h1>
                                        <input className="inputtxt" name="father" value={data.father} onChange={(txt) => CheckAlpha(txt, () => { setvalue(txt) }, () => { })} required />
                                        <p className="Qhelper2">As per CNIC record</p>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div className="inputrow">
                                    <div className="inputcolumn1">
                                        <Person className="Iconcls" style={{ marginTop: 30, fill: "var(--secondary)", fontSize: 20 }} />
                                    </div>
                                    <div className="inputcolumn2">
                                        {/* <p style={{ marginTop: 40 }} className="Qhelper">Question <b>2</b> / 6</p> */}
                                        <div>
                                            {/* <p style={{ marginTop: 40 }} className="Qhelper">Question <b>1</b> /6</p> */}
                                            <h1 className="Q">Mother Maiden Name</h1>
                                            <input className="inputtxt" name="mother" value={data.mother} onChange={(txt) => CheckAlpha(txt, () => { setvalue(txt) }, () => { })} required />
                                            <p className="Qhelper2">As per CNIC record</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="inputrow">
                                <div className="inputcolumn1">
                                    <LocationCity className="Iconcls" style={{ marginTop: 30, fill: "var(--secondary)", fontSize: 20 }} />
                                </div>
                                <div className="inputcolumn2">
                                    {/* <p style={{ marginTop: 40 }} className="Qhelper">Question <b>3</b> / 6</p> */}
                                    <h1 className="Q">Place of Birth Province</h1>
                                    <input className="inputtxt" name="Province" value={data.Province} onChange={(txt) => CheckAlpha(txt, () => { setvalue(txt) }, () => { })} required />
                                    <p className="Qhelper2"></p>
                                </div>
                            </div>
                            <div className="inputrow">
                                <div className="inputcolumn1">
                                    <LocationCity className="Iconcls" style={{ marginTop: 30, fill: "var(--secondary)", fontSize: 20 }} />
                                </div>
                                <div className="inputcolumn2">
                                    {/* <p style={{ marginTop: 40 }} className="Qhelper">Question <b>3</b> / 6</p> */}
                                    <h1 className="Q">Place of Birth</h1>
                                    <input className="inputtxt" name="City" value={data.City} onChange={(txt) => CheckAlpha(txt, () => { setvalue(txt) }, () => { })} required />
                                    <p className="Qhelper2"></p>
                                </div>
                            </div>
                            <div className="inputrow">
                                <div className="inputcolumn1">
                                    <LocationCity className="Iconcls" style={{ marginTop: 30, fill: "var(--secondary)", fontSize: 20 }} />
                                </div>
                                <div className="inputcolumn2">
                                    {/* <p style={{ marginTop: 40 }} className="Qhelper">Question <b>3</b> / 6</p> */}
                                    <h1 className="Q">Present Residential Address</h1>
                                    <input className="inputtxt" name="Residential" value={data.Residential} onChange={(txt) => CheckAplhaChara(txt, () => { setvalue(txt) }, () => { })} required />
                                    <p className="Qhelper2">House no., Street no, Area, Sector</p>
                                </div>
                            </div>
                            <div className="inputrow">
                                <div className="inputcolumn1">
                                    <Person className="Iconcls" style={{ marginTop: 30, fill: "var(--secondary)", fontSize: 20 }} />
                                </div>
                                <div className="inputcolumn2">
                                    <h1 className="Q">Gender</h1>
                                    <select className="inputtxt" type="text" name="gender" onChange={(txt) => CheckAplhaChara(txt, () => { setvalue(txt) }, () => { })} required>
                                        <option value="">Select</option>

                                        {ApiData.GenderList.map(p => (
                                            <option value={p.id} selected={parseInt(data.gender) === p.id ? true : false}>{p.Name}</option>
                                        ))}
                                    </select>
                                    <p className="Qhelper2"></p>
                                </div>
                            </div>
                            <div className="inputrow">
                                <div className="inputcolumn1">
                                    <Person className="Iconcls" style={{ marginTop: 30, fill: "var(--secondary)", fontSize: 20 }} />
                                </div>
                                <div className="inputcolumn2">
                                    <h1 className="Q">Marital Status</h1>
                                    <select className="inputtxt" type="text" name="Marital" onChange={(txt) => CheckAplhaChara(txt, () => { setvalue(txt) }, () => { })} required>
                                        <option value="">Select</option>
                                        {ApiData.MaritalStatusList.map(p => (
                                            <option value={p.id} selected={parseInt(data.Marital) === p.id ? true : false}
                                            >{p.Name}</option>
                                        ))}
                                    </select>
                                    <p className="Qhelper2"></p>
                                </div>
                            </div>

                            <div>
                                <div className="inputrow">
                                    <div className="inputcolumn1">
                                        <CalendarToday style={{ marginTop: 30, fill: "var(--secondary)", fontSize: 20 }} />
                                    </div>
                                    <div className="inputcolumn2">
                                        <h1 className="Q">Date of Birth</h1>
                                        <input className="inputtxt" type="date" max={new Date().getFullYear() + "-" + (parseInt(new Date().getMonth()) + 1) + "-" + new Date().getDate()} name="dateofbirth" value={data.dateofbirth} onChange={(txt) => {
                                            if (new Date(txt.target.value) > new Date()) {
                                                setData({ ...data, dateofbirth: new Date() });
                                                // checkdob(txt)
                                            } else {
                                                checkdob(txt)
                                            }
                                        }} required />
                                        <p className="Qhelper2">As per NADRA record.</p>
                                    </div>
                                </div>

                            </div>

                        </fieldset>
                        <div className="btndiv">
                            <button style={{ margin: 10, background: "var(--secondary)", border: "none", borderRadius: 10, marginTop: 20 }} onClick={() => history.goBack()}>
                                <ArrowLeft style={{ marginLeft: -10 }} />
                                Back
                            </button>
                            <button type="submit" disabled={!goforwardDpb} style={{ margin: 10, background: "var(--primary)", border: "none", borderRadius: 10, marginTop: 20 }}>Next
                                <ArrowRight style={{ marginRight: -10 }} />
                            </button>
                        </div>
                    </Row>
                </Grid>
                : <></>}
        </Animatedpage>
    )

}
export default BasicDetails;